import { addressFormTranslations, getDynamicField } from './utils';
import { CMPlaceholder, CmsSite } from '@vf/api-contract';
import { ComposableContext } from '../types';
import { getTranslation } from './utils/extractProperty';

export const checkoutBillingInfo = (
  data: CMPlaceholder,
  context: ComposableContext,
  siteConfiguration: CmsSite
) => {
  const {
    billing: billingTranslations,
    ...addressTranslations
  } = addressFormTranslations(data, context, siteConfiguration);
  return {
    component: 'checkout/CheckoutBillingInfo',
    props: {
      addressFormTranslations: {
        ...addressTranslations,
        ...billingTranslations,
      },
      translations: {
        sameAsShipping: getTranslation(
          data,
          'sameAsShipping',
          '$Same as shipping$'
        ),
        editThisAddress: getTranslation(
          data,
          'editThisAddress',
          '$Edit this address$'
        ),
        billingAddress: getTranslation(
          data,
          'billingAddress',
          '$Billing Address$'
        ),
        selectSavedAddress: getTranslation(
          data,
          'selectSavedAddress',
          '$Select a saved billing address, or$'
        ),
        enterNewAddress: getTranslation(
          data,
          'enterNewAddress',
          '$Enter a new address.$'
        ),
        addressSelect: getTranslation(
          data,
          'addressSelect',
          '$Select billing address$'
        ),
        addressSelectionAdditionalOption: getTranslation(
          data,
          'addressSelectionAdditionalOption',
          {
            openAddressModal: '$Add new address$',
            setSameAsShipping: '$Same as shipping address$',
          }
        ),
        validationMessages: getTranslation(data, 'validationMessages', {
          required: '$This field is required.$',
          email: '$Please enter a valid email address.$',
          phone:
            '$Please match the requested format: Enter phone number with no spaces or special characters.$',
        }),
        marketingOptIn: getTranslation(
          data,
          'marketingOptIn',
          '$Please send me emails with offers and updates from Vans, a division of VF Outdoor, LLC, 1588 South Coast Drive, Costa Mesa, CA 92626, www.vans.ca. I understand that I can unsubscribe at any time.$'
        ),
      },
      showEditAddressButton: getDynamicField(
        data,
        'show-edit-address-button',
        false
      ),
      showZipCodeWithState: getDynamicField(
        data,
        'show-zip-code-with-state',
        false
      ),
      showCountry: getDynamicField(data, 'show-country', true),
      showSubscriptionCheckbox: getDynamicField(
        data,
        'show-subscription-checkbox',
        false
      ),
      clearFieldsOnSameAsShippingDisabling: getDynamicField(
        data,
        'clear-fields-on-same-as-shipping-disabling',
        false
      ),
    },
  };
};
