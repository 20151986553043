// TODO: GLOBAL15-56318 remove ff
import { useFeatureFlagsStore } from '../store/featureFlags';

const ROUTES = {
  HOME: (): string => '/',
  CART: (): string => '/cart',
  CHECKOUT: (): string =>
    useFeatureFlagsStore().isCheckoutRedesignEnabled
      ? '/checkout'
      : '/checkout/shipping',
  CHECKOUT_SHIPPING: (): string =>
    useFeatureFlagsStore().isCheckoutRedesignEnabled
      ? '/checkout'
      : `/checkout/shipping`,
  CHECKOUT_PAYMENT: (): string =>
    useFeatureFlagsStore().isCheckoutRedesignEnabled
      ? '/checkout'
      : `/checkout/payment`,
  CHECKOUT_ORDER_STATUS: (): string =>
    useFeatureFlagsStore().isCheckoutSuccessRedesignEnabled
      ? '/checkout/success'
      : `/order/success`,
  SIGN_IN: (): string => '/account/sign-in',
  SIGN_OUT: (): string => '/account/sign-out',
  ACCOUNT: (id = ''): string => `/account${id && '/' + id}`,
  ACCOUNT_PROFILE: (): string => '/account/profile',
  ACCOUNT_FAMILY: (): string => '/account/loyalty',
  ARTICLE_BASE: (): string => '/articles/',
  PROFILE: (): string => `/account`,
  CREDIT_CARDS: (): string => `/account/credit-cards`,
  CUSTOMS: (): string => '/customs',
  NEW_CREDIT_CARD: (): string => `/account/credit-cards/new`,
  ADDRESS_BOOK: (): string => '/account/address-book',
  SHIPPING_ADDRESS: (id: string): string =>
    `/account/address-book/shipping-address?id=${id}`,
  NEW_SHIPPING_ADDRESS: (): string => `/account/address-book/shipping-address`,
  BILLING_ADDRESS: (id: string): string =>
    `/account/address-book/billing-address?id=${id}`,
  NEW_BILLING_ADDRESS: (): string => `/account/address-book/billing-address`,
  CATEGORY: (id: string): string => `/category/${id}`,
  PRODUCT: (id: string): string => `/product/${id}`,
  PRODUCT_REVIEW: (): string => '/product/review',
  RESET_PASSWORD: (): string => '/reset-password',
  FORGOT_PASSWORD: (): string => '/account/forgot-password',
  SEARCH: (q?: string): string =>
    q ? `/search/product?q=${q}` : '/search/product',
  SEARCH_ARTICLE: (q: string): string => `/search/article?q=${q}`,
  STORE_LOCATOR_BASE: (): string => '/stores',
  FAVORITES: (): string => '/favorites',
  FAVORITES_PRIVATE: (): string => '/favorites-private',
  FAVORITES_PUBLIC: (): string => '/favorites-public',
  ORDER_STATUS: (): string => `/order-status`,
  ORDER_DETAILS_GUEST: (): string => `/order-details`,
  ORDER_DETAILS: (id = ''): string => `/account/order-details/${id}`,
  ORDER_HISTORY: (): string => `/account/order-history`,
  RETURNS: (): string => `/account/returns`,
  RETURN_HISTORY: (): string => `/account/returns/return-history`,
  RETURN_ORDER: (id = ''): string => `/account/returns/return-order/${id}`,
  RETURN_CONFIRMATION: (): string => `/account/returns/confirmation`,
  RETURN_DETAILS: (id = ''): string => `/account/returns/details/${id}`,
  NOT_FOUND: (): string => `/not-found`,
  CUSTOMIZER: (): string => `/customizer`,
  INTERESTS: (): string => `/account/interests`,
  CUSTOMSTORE: (id = ''): string => `/customstore/${id}`,
  TAG: (id: string): string => `/tag/${id}`,
  EXPLORE: (id: string): string => `/explore/${id}`,
};

export { ROUTES };
