import {
  extractProperty,
  addressFormTranslations,
  generateLinkFromTarget,
  extractLocalSettingsExpandedLinks,
  getTranslation,
} from './utils';
import { CMPlaceholder, CmsSite } from '@vf/api-contract';
import { ComposableContext } from '../types';

export const addCreditCard = (
  data: CMPlaceholder,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  const securityCodeTooltipImage = `${cmsBaseUri}${extractProperty(
    extractLocalSettingsExpandedLinks(
      data,
      'add-credit-card-securityCodeTooltip'
    ),
    '[0].data.uri',
    ''
  )}`;

  const accountCreditCardsListLink = generateLinkFromTarget(
    extractLocalSettingsExpandedLinks(data, 'accountCreditCardsListLink')[0],
    context,
    siteConfiguration,
    cmsBaseUri
  );

  return {
    component: 'account/CreditCardForm',
    props: {
      addressFormTranslations: {
        ...addressFormTranslations(data, context, siteConfiguration),
        ...{
          // overriding address form translations
          addHeading: getTranslation(data, 'addHeading', '$Billing Address$'),
          saveButton: getTranslation(
            data,
            'saveThisCreditCard',
            '$Save this credit card$'
          ),
        },
      },
      creditCardFormTranslations: {
        cardNumber: getTranslation(data, 'cardNumber', '$Card Number$'),
        month: getTranslation(data, 'month', '$Month$'),
        year: getTranslation(data, 'year', '$Year$'),
        altText: getTranslation(
          data,
          'altText',
          '$This 3 or 4-digit number can be found on the back of your card, near your signature or on the front if you have an American Express card.$'
        ),
        securityCode: getTranslation(data, 'securityCode', '$Security Code$'),
        securityCodeTooltipButtonLabel: getTranslation(
          data,
          'securityCodeTooltipButtonLabel',
          '$Security Code Info$'
        ),
        validationMessages: getTranslation(data, 'validationMessages', {
          required: '$This field is required.$',
          securityCode: '$Please match the requested format: (XXX) or (XXXX)$',
          date: '$Wrong date.$',
          cardNumber: '$This field is not correct.$',
        }),
        allFieldsRequired: getTranslation(data, 'subtitle', ''),
      },
      translations: {
        heading: getTranslation(data, 'heading', '$Credit Card Info$'),
        subtitle: getTranslation(data, 'subtitle', '$*All fields required$'),
        selectSavedAddress: getTranslation(
          data,
          'selectSavedAddress',
          '$Select a saved billing address or enter a new address.$'
        ),
        addressSelect: getTranslation(
          data,
          'addressSelect',
          '$Select billing address$'
        ),
        backToTop: getTranslation(data, 'backToTop', '$Back to Top$'),
        addNewAddress: getTranslation(
          data,
          'addNewAddress',
          '$Add new address$'
        ),
        backToCreditCards: getTranslation(
          data,
          'backToCreditCards',
          '$Back to credit cards$'
        ),
        addCreditCard: getTranslation(
          data,
          'addCreditCard',
          '$Add a credit card$'
        ),
        validationMessages: getTranslation(data, 'validationMessages', {
          required: '$This field is required.$',
          email: '$Please enter a valid email address.$',
          phone:
            '$Please match the requested format: Enter phone number with no spaces or special characters.$',
        }),
      },
      accountCreditCardsListLink,
      securityCodeTooltipImage,
    },
  };
};
