var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"options-swatch"},[_c('div',{ref:"optionsSwatch",staticClass:"options-swatch__wrapper",class:{
      'options-swatch__wrapper--show-all': _vm.viewAllOptions,
      'options-swatch__wrapper--fix-height': !_vm.isDefinedOptionsOnViewport,
      'options-swatch__wrapper--rounded-items': _vm.roundItems,
      'options-swatch__wrapper--redesign-catalog': _vm.isVansPdpRedesignEnabled,
    },attrs:{"role":"listbox"}},[(_vm.isLoading)?_vm._l((24),function(i){return _c('div',{key:i,staticClass:"options-swatch__option placeholder",class:{ round: _vm.roundItems }})}):_vm._l((_vm.sizes),function(option,key){return _c('button',{key:key,staticClass:"options-swatch__option",class:{
          'options-swatch__option--redesign-catalog': _vm.isVansPdpRedesignEnabled,
          selected: _vm.value === option.value,
          round: _vm.roundItems,
          disabled: _vm.getOptionDisabledState(option),
        },attrs:{"aria-disabled":_vm.getOptionDisabledState(option),"role":"option","aria-selected":_vm.value === option.value},on:{"click":function($event){return _vm.$emit('input', option)},"mouseover":function($event){return _vm.onMouseOver(option)},"mouseleave":_vm.onMouseLeave}},[_c('span',{class:{
            'options-swatch__option-text': !_vm.isVansPdpRedesignEnabled,
            'options-swatch__option-text--crossed': _vm.canBeCrossed,
            'options-swatch__option-text--redesign-catalog': _vm.isVansPdpRedesignEnabled,
          }},[_vm._v("\n          "+_vm._s(_vm.getOptionText(option))+"\n        ")])])})],2),_vm._v(" "),(!_vm.viewAllOptions)?_c('VfButton',{directives:[{name:"show",rawName:"v-show",value:(_vm.isViewAllVisible),expression:"isViewAllVisible"}],staticClass:"vf-button--pure vf-button--text options-swatch__button",on:{"click":function($event){_vm.viewAllOptions = !_vm.viewAllOptions}}},[_vm._v("\n    "+_vm._s(_vm.showMoreText)+"\n    "),_c('VfIcon',{staticClass:"options-swatch__chevron",attrs:{"icon":_vm.viewAllOptions ? 'chevron_up' : 'chevron_down'}})],1):_vm._e(),_vm._v(" "),(_vm.selectedSizeData && _vm.selectedSizeData.modelMeasurements)?_c('p',{staticClass:"options-swatch__description"},[_vm._v("\n    "+_vm._s(_vm.selectedSizeData.modelMeasurements)+"\n  ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }