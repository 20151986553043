import { CMPlaceholder, CmsSite } from '@vf/api-contract';
import { ComposableContext } from '../types';

import {
  extractProperty,
  getTranslation,
  getDynamicField,
  extractCommonConfigLink,
} from './utils';

export const addToCartButton = (
  data: CMPlaceholder,
  context: ComposableContext,
  siteConfiguration: CmsSite
) => {
  const signInToBuy = extractProperty(
    extractCommonConfigLink(siteConfiguration, 'sign-in'),
    '[0]'
  )?.id;

  const loyaltyEnrollment = extractProperty(
    extractCommonConfigLink(siteConfiguration, 'loyalty-enrollment-modal'),
    '[0]'
  )?.id;
  return {
    component: 'shared/ProductAddToCart',
    props: {
      translations: {
        buttonCTANotify: getTranslation(
          data,
          'buttonCTANotify',
          '$Notify me when available$'
        ),
        buttonCTADiscontinued: getTranslation(
          data,
          'buttonCTADiscontinued',
          '$Out of stock$'
        ),
        buttonCTAActive: getTranslation(data, 'buttonCTAActive', data.title),
        buttonCTASuccess: getTranslation(data, 'buttonCTASuccess', '$Added$'),
        buttonCTACustomize: getTranslation(
          data,
          'buttonCTACustomize',
          '$Customize$'
        ),
        notificationDiscontinued: getTranslation(
          data,
          'notificationDiscontinued',
          '$Out of stock$'
        ),
        notifyBoxTitle: getTranslation(
          data,
          'notifyBoxTitle',
          '$Notify me when available$'
        ),
        notifyBoxTextBox: getTranslation(
          data,
          'notifyBoxTextBox',
          '$Please enter your email so we can alert you when the {{name}} in color {{color}}, size {{size}} is back in stock. We promise not to spam you. You will only be notified once.$'
        ),
        notifyEmailTitle: getTranslation(
          data,
          'notifyEmailTitle',
          '$Enter Email Address$'
        ),
        notifyEmailPlaceholder: getTranslation(
          data,
          'notifyEmailPlaceholder',
          '$Enter Email Address$'
        ),
        favoritesAriaLabel: getTranslation(
          data,
          'favoritesAriaLabel',
          '$favorites$'
        ),
        validationMessages: getTranslation(data, 'validationMessages', {
          requiredError: '$This field is required.$',
          emailError: '$Please enter a valid email address.$',
        }),
        notificationAlreadySubscribedMessage: getTranslation(
          data,
          'notificationAlreadySubscribedMessage',
          '$The email provided has already been submitted for this product. A notification will be sent when the item is back in stock$'
        ),
        notificationMessage: getTranslation(
          data,
          'notificationMessage',
          '$You will be notified when this item is available$'
        ),
        notificationErrorMessage: getTranslation(
          data,
          'notificationErrorMessage',
          '$Subscription failed$'
        ),
        sizeLabel: getTranslation(data, 'sizeLabel', '$Size$'),
        notifyButtonText: getTranslation(
          data,
          'notifyButtonText',
          '$Notify me$'
        ),
        addedToFavoritesNotification: getTranslation(
          data,
          'addedToFavoritesNotification',
          '$You have successfully added {{product}} to your Favorites.$'
        ),
        signInToBuy: getTranslation(data, 'signInToBuy', '$Sign in to buy$'),
        soldOutCta: getTranslation(data, 'soldOutCta', '$SOLD OUT$'),
      },
      showAddToFavourites: getDynamicField(data, 'showAddToFavourites', false),
      modals: {
        signInToBuy,
        loyaltyEnrollment,
      },
    },
  };
};
