import { computed, Ref } from '@nuxtjs/composition-api';
import type { Color, Product } from '@vf/api-client';
import { WidthAttributeValue } from '@vf/api-contract';
import type { ProductConfiguration } from '../../types';

const checkWideColor = (color: Color, shouldBeWide = false) => {
  const lowerCasedBadges = color.badges.map((badge) => badge.toLowerCase());

  return shouldBeWide
    ? lowerCasedBadges.includes('wide')
    : !lowerCasedBadges.includes('wide');
};

// in Vans, the label contains both slug and actual label,
// this will just return the label in TNF
const getColorName = (color: Color) =>
  color.label.replace(`${color.slug} - `, '');

export const useProductColors = ({
  product,
  configure,
}: {
  product: Ref<Product>;
  configure: (configuration: ProductConfiguration) => void;
}) => {
  const colorsOptions = computed(() => {
    const colors = (product.value.attributes || [])?.find(
      (attribute) => attribute.code === 'color'
    );
    return colors?.options || [];
  });

  const setInitialColor = (colorParam?: string) => {
    if (!product.value) return;
    const isColorParam =
      colorParam &&
      product.value?.colors.find((color: Color) => color.value === colorParam);

    if (isColorParam) {
      configure({ color: colorParam.toUpperCase() });
      return;
    }

    const productSlug = product.value.id;
    const initialColor = product.value?.colors?.find(
      (color: Color) => color.slug === productSlug
    );
    if (initialColor) {
      configure({ color: initialColor?.value });
      return;
    }
    configure({ color: product.value?.colors?.[0]?.value });
  };

  const colorsGroupedByPriceDiscount = computed(() => {
    const discountsGroupedByPrices: { colors: Color[] } = (
      colorsOptions.value || []
    )
      .filter((color) => color.price)
      .reduce((acc, color) => {
        const priceKey = `${color.price.current || 'no'}:${
          color.price.original
        }`;
        if (acc[priceKey]) {
          acc[priceKey].push(color);
        } else {
          acc[priceKey] = [color];
        }
        return acc;
      }, {});

    return Object.entries(discountsGroupedByPrices).map(([price, colors]) => {
      const pricesArray = price.split(':');
      return {
        currentPrice:
          pricesArray[0] === 'no'
            ? Number(pricesArray[1])
            : Number(pricesArray[0]),
        originalPrice: Number(pricesArray[1]),
        priceKey: price,
        colors,
      };
    });
  });

  const hasColorPriceWithDiscount = computed(() => {
    const colors = colorsOptions.value || [];
    const colorWithDiscount = colors.find((color) => {
      return color.price && color.price.current !== color.price.original;
    });
    return !!colorWithDiscount;
  });

  const getColorByCode = (colorCode: string) => {
    return product.value?.colors?.find(
      (attrOpt) => attrOpt.value === colorCode
    );
  };

  const getColorByWidth = (width: WidthAttributeValue) => {
    const currentColorName = getColorName(product.value.color);
    const colorSet = product.value.colors.filter((color) =>
      checkWideColor(color, width === WidthAttributeValue.WIDE)
    );
    const colorByWidth = colorSet.find(
      (color) => getColorName(color) === currentColorName
    );
    return colorByWidth || colorSet[0];
  };

  return {
    colorsGroupedByPriceDiscount,
    colorsOptions,
    hasColorPriceWithDiscount,
    setInitialColor,
    getColorByCode,
    getColorByWidth,
  };
};
