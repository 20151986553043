import { EventProps } from '../../eventPropsHandlers';
import templates from './templates.json';

export type EventType =
  | 'cart:add'
  | 'cart:remove'
  | 'cart:update'
  | 'live-chat:open'
  | 'enhanced-sale:impression'
  | 'enhanced-sale:click'
  | 'enhanced-sale:view'
  | 'create-account:onload'
  | 'create-account:onsubmit'
  | 'create-account:confirmed'
  | 'create-account:close'
  | 'autologin:onsubmit'
  | 'autologin:confirmed'
  | 'login:onload'
  | 'login:onsubmit'
  | 'login:confirmed'
  | 'login:close'
  | 'logout:confirmed'
  | 'search-filter:applied'
  | 'search-filter:removed'
  | 'filter:clear'
  | 'favorite:add'
  | 'favorite:remove'
  | 'mega-menu:expand'
  | 'notify-me:click'
  | 'notify-me:request';

export const getEventFromTemplate = <T>(
  type: EventType,
  attributes: Record<string, any> = {}
): EventProps<T> => {
  let eventTemplate = JSON.stringify(templates[type]);
  /**
   * replace event placeholders from attributes if given
   */
  Object.entries(attributes).forEach(([key, value]) => {
    eventTemplate = eventTemplate.replace(`{{${key}}}`, value);
  });
  if (['{{', '}}'].every((tag) => eventTemplate.includes(tag))) {
    console.warn(
      `GTM Event "${type}" includes unchanged placeholders`,
      JSON.parse(eventTemplate)
    );
  }
  return JSON.parse(eventTemplate) as EventProps<T>;
};
