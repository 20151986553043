import type {
  GetCustomerCreditCardsResponse,
  PaymentInstrument,
  TokenizedCreditCard,
} from '@vf/api-contract';
import type { Address } from '@vf/api-client/src/types';
import type { ComposablesStorage } from '../../../types';

import { computed, ref, Ref } from '@vue/composition-api';
import { apiClientFactory, PaymentMethodCode } from '@vf/api-client';

import initStorage from '../../../utils/storage';
import { UseAccountStorage } from '../../index';
import { errorMessages } from '../../../utils/errorMessages';
import { useRequestTracker } from '../../../useRequestTracker';
import { usePaymentProvider } from '../../../usePaymentProvider';
import { useFeatureFlagsStore } from '../../../store/featureFlags';
import { useCartStore } from '../../../store/cartStore';

export const usePaymentInstrument = (instance) => {
  const { displayErrorMessages } = errorMessages(instance);
  const {
    addPaymentInstrument: addPaymentInstrumentAPI,
    deletePaymentInstrument: deletePaymentInstrumentAPI,
    getPaymentInstruments: getPaymentInstrumentsAPI,
    setDefaultPaymentInstrument: setDefaultPaymentInstrumentAPI,
  } = apiClientFactory(instance);
  const { trackRequest, clearRequest } = useRequestTracker(instance);

  const { isCheckoutRedesignEnabled } = useFeatureFlagsStore();

  const storage: ComposablesStorage<UseAccountStorage> = initStorage<UseAccountStorage>(
    instance,
    'useAccount'
  );

  const newPaymentInstrumentAdded: Ref<boolean> =
    storage.get('newPaymentInstrumentAdded') ??
    storage.save('newPaymentInstrumentAdded', ref(false));

  const paymentInstruments: Ref<{ cards: PaymentInstrument[] }> =
    storage.get('paymentInstruments') ??
    storage.save('paymentInstruments', ref({ cards: [] }));

  const areCreditCardsFetched: Ref<boolean> =
    storage.get('areCreditCardsFetched') ??
    storage.save('areCreditCardsFetched', ref(false));

  const setNewPaymentInstrumentAdded = (value: boolean) => {
    newPaymentInstrumentAdded.value = value;
  };

  const getPaymentInstruments = async <ResponseType>(
    payment_method_id:
      | PaymentMethodCode.CREDIT_CARD
      | PaymentMethodCode.REWARD_CARD
      | PaymentMethodCode.REWARD_CODE
  ) => {
    const { tag } = trackRequest('usePaymentInstruments-getPaymentInstruments');
    const response = await getPaymentInstrumentsAPI<ResponseType>({
      payment_method_id,
    });
    clearRequest(tag);
    return response;
  };

  // TODO: Remove this when Backend will select oldest payment instrument by default
  const selectFirstPaymentInstrument = async () => {
    const hasPaymentInstruments = paymentInstruments.value.cards.length > 0;

    if (hasPaymentInstruments) {
      const firstPaymentInstrument =
        paymentInstruments.value.cards[0].card.paymentInstrumentId;
      await setDefaultPaymentInstrument(firstPaymentInstrument);
    }
  };

  const deletePaymentInstrument = async (instrumentId: string) => {
    try {
      const deleteResult = await deletePaymentInstrumentAPI(instrumentId);
      if (deleteResult?.status === 200) {
        const wasDefaultPaymentInstrument =
          instrumentId ===
          defaultPaymentInstrument.value.card.paymentInstrumentId;

        paymentInstruments.value.cards.splice(
          paymentInstruments.value.cards.findIndex(
            (item) => item.card.paymentInstrumentId === instrumentId
          ),
          1
        );

        if (wasDefaultPaymentInstrument) {
          await selectFirstPaymentInstrument();
        }
      } else {
        throw new Error('Non-successful response code');
      }
      return deleteResult;
    } catch (e) {
      displayErrorMessages(e);
    }
  };

  const setDefaultPaymentInstrument = async (instrumentId: string) => {
    try {
      const setDefaultResult = await setDefaultPaymentInstrumentAPI(
        instrumentId,
        { default: true }
      );
      if (setDefaultResult.status === 200) {
        paymentInstruments.value.cards = setDefaultResult.data as PaymentInstrument[];
      } else {
        displayErrorMessages((setDefaultResult.data as any).errorDetails);
      }
      return setDefaultResult;
    } catch (e) {
      displayErrorMessages(e);
    }
  };

  const defaultPaymentInstrument = computed(() => {
    const creditCards = paymentInstruments.value?.cards ?? [];
    return creditCards.find((item) => item.card.main) ?? creditCards[0];
  });

  const addPaymentInstrument = async (
    payload: TokenizedCreditCard,
    extraPayload: { address: Address; recaptcha_response: string }
  ) => {
    try {
      const addResult = await addPaymentInstrumentAPI({
        paymentMethodId: 'CREDIT_CARD',
        card: payload,
        addressId: extraPayload?.address?.id,
        recaptcha_response: extraPayload.recaptcha_response,
        default: (paymentInstruments.value?.cards ?? []).length < 1,
      });
      if (addResult?.status === 201) {
        setNewPaymentInstrumentAdded(true);
      } else {
        displayErrorMessages((addResult.data as any).errorDetails);
      }
    } catch (e) {
      displayErrorMessages(e);
    }
  };

  const savePaymentInstrument = async (address: Address, recaptcha: string) => {
    try {
      const tokenizedCreditCard = await usePaymentProvider(
        instance
      ).getCardData();
      await addPaymentInstrument(tokenizedCreditCard, {
        address,
        recaptcha_response: recaptcha,
      });
    } catch (e) {
      displayErrorMessages(e);
    }
  };

  const getConsumerCreditCards = async (): Promise<void> => {
    const paymentInstrumentsResponse = await getPaymentInstruments<GetCustomerCreditCardsResponse>(
      PaymentMethodCode.CREDIT_CARD
    );
    areCreditCardsFetched.value = true;
    paymentInstruments.value.cards = paymentInstrumentsResponse.data as PaymentInstrument[];
    if (isCheckoutRedesignEnabled) {
      useCartStore().$patch({
        availableCreditCards: paymentInstrumentsResponse.data as PaymentInstrument[],
      });
    }
  };

  return {
    getConsumerCreditCards,
    paymentInstruments,
    getPaymentInstruments,
    defaultPaymentInstrument,
    addPaymentInstrument,
    savePaymentInstrument,
    deletePaymentInstrument,
    setDefaultPaymentInstrument,
    newPaymentInstrumentAdded,
    setNewPaymentInstrumentAdded,
    selectFirstPaymentInstrument,
    areCreditCardsFetched,
  };
};
