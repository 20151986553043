//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { focus } from '@vf/shared/src/utils/directives';
import { defineComponent } from '@vue/composition-api';
export default defineComponent({
  name: 'VfThumbnail',
  directives: { focus },
  props: {
    thumbnail: {
      type: Object,
      default: () => {
        return { src: '', value: '' };
      },
    },
    selected: {
      type: Boolean,
      default: false,
    },
    imageHeight: {
      type: [Number, String],
      default: 50,
    },
    imageWidth: {
      type: [Number, String],
      default: 50,
    },
    lazy: {
      type: Boolean,
      default: true,
    },
    imageFetchPriority: {
      type: String,
      default: null,
    },
    // TODO: GLOBAL15-63801 clean up
    isRedesign: Boolean,
  },
  computed: {
    aspectRatio() {
      return {
        'aspect-ratio': `${this.imageWidth} / ${this.imageHeight}`,
      };
    },
  },
});
