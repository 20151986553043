








































































import {
  computed,
  defineComponent,
  inject,
  onBeforeUnmount,
  onMounted,
  PropType,
  ref,
  watch,
} from '@vue/composition-api';
import { getCacheKeyFromProps } from '@vf/shared/src/utils/helpers';
import { Sizes } from '@vf/api-contract/src/ui/optionsSwatch';
import useRootInstance from '../../theme/shared/useRootInstance';
import { ProductVariant } from '@vf/api-contract';

export default defineComponent({
  name: 'OptionsSwatch',
  serverCacheKey: getCacheKeyFromProps,
  props: {
    value: {
      type: String,
      default: '',
    },
    sizes: {
      type: Array as PropType<Sizes>,
      default: () => [],
    },
    itemsCountSmall: {
      type: Number,
      default: null,
    },
    itemsCountMedium: {
      type: Number,
      default: null,
    },
    itemsCountLarge: {
      type: Number,
      default: null,
    },
    roundItems: {
      type: Boolean,
      default: false,
    },
    showMoreText: {
      type: String,
      default: 'show more',
    },
    outOfStockText: {
      type: String,
      default: 'Out of stock',
    },
    availableVariants: {
      type: Array,
      default: () => [],
    },
    role: {
      type: String,
      default: 'size',
    },
    product: {
      type: Object,
      default: () => ({}),
    },
    viewAllOptionsDefault: {
      type: Boolean,
      default: false,
    },
    showAltLabels: {
      type: Boolean,
      default: false,
    },
    showOnlyUnavailableSizes: {
      type: Boolean,
      default: false,
    },
    /** Will show a skeleton loading animation while it's true */
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const viewAllOptions = props.viewAllOptionsDefault;
    const scrollHeight = ref(0);
    const clientHeight = ref(0);
    const optionsSwatch = ref<HTMLDivElement>(null);
    const { root } = useRootInstance();
    const { canBeCrossed } = root.$themeConfig.optionsSwatch;
    const isCoreRedesignEnabled = inject('isCoreRedesignEnabled');
    // To be cleaned up with GLOBAL15-63801
    const isVansPdpRedesignEnabled = inject('isVansPdpRedesignEnabled');

    const isDefinedOptionsOnViewport = computed(() => {
      return (
        props.itemsCountLarge && props.itemsCountMedium && props.itemsCountSmall
      );
    });
    const selectedSizeData = computed(() => {
      return props.sizes.find((size) => {
        return size.value === props.value;
      });
    });

    const getOptionText = (option) => {
      if (!option.available && !option.label) {
        return props.outOfStockText;
      }
      if (props.showAltLabels) {
        return option.altLabel1 || option.label;
      }
      return option.label;
    };
    const checkAvailableOption = (optionValue) => {
      const item = props.product?.variants?.find(
        (variant) =>
          variant.attributes.color == props.product?.color?.value &&
          variant.attributes[props.role] == optionValue
      );
      if (
        !props.availableVariants &&
        !props.product.notifyMe &&
        !item.authorizedDealer.length
      ) {
        return false;
      }
      return props.availableVariants?.find(
        (variant: ProductVariant) =>
          variant.attributes[props.role] == optionValue
      );
    };
    const handleResize = () => {
      root.$nextTick(() => {
        scrollHeight.value = optionsSwatch?.value?.scrollHeight || 0;
        clientHeight.value = optionsSwatch?.value?.clientHeight || 0;
      });
    };

    const isViewAllVisible = computed(() => {
      return scrollHeight.value > clientHeight.value;
    });

    onMounted(() => {
      if (!isDefinedOptionsOnViewport.value) {
        window.addEventListener('resize', handleResize);

        watch(
          () => props.isLoading,
          (isLoading) => {
            if (!isLoading) {
              handleResize();
            }
          }
        );

        const observer = new IntersectionObserver(
          (entries) => {
            if (entries[0].isIntersecting === true) {
              handleResize();
            }
          },
          { threshold: [0] }
        );

        observer.observe(optionsSwatch.value);
      }
    });

    onBeforeUnmount(() => {
      if (!isDefinedOptionsOnViewport.value) {
        window.removeEventListener('resize', handleResize);
      }
    });

    const getOptionDisabledState = (option) => {
      if (props.showOnlyUnavailableSizes) {
        return false;
      }

      return !option.available || !checkAvailableOption(option.value);
    };

    const onMouseOver = (option) => {
      if (props.showOnlyUnavailableSizes) {
        return;
      }
      emit('check-availability', !!checkAvailableOption(option.value));
    };
    const onMouseLeave = () => {
      if (props.showOnlyUnavailableSizes) {
        return;
      }
      emit('check-availability', true);
    };
    return {
      viewAllOptions,
      isDefinedOptionsOnViewport,
      selectedSizeData,
      getOptionText,
      checkAvailableOption,
      handleResize,
      optionsSwatch,
      isViewAllVisible,
      canBeCrossed,
      getOptionDisabledState,
      onMouseOver,
      onMouseLeave,
      isCoreRedesignEnabled,
      isVansPdpRedesignEnabled,
    };
  },
});
