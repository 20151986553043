import { SearchSuggestionsQueryParams } from '@vf/api-contract';
import { Filter } from '../types';

/**
 * Create search query
 * Example: q=search-term
 * @param query
 */
export const prepareSearch = (query: string): string => {
  return `q=${query}`;
};

/**
 * Create sort query
 * Example: sort=whats-new
 * @param sort
 */
export const prepareSort = (sort: string): string => {
  return `sort=${sort}`;
};

/**
 * Create start query - which element to start with
 * Example: start=20
 * @param start
 */
export const prepareStart = (start: number): string => {
  return `start=${start.toString()}`;
};

/**
 * Set min to 5 as Bloomreach does not accept smaller amount
 * @param count
 */
export const clampCount = (count: number): number => {
  return count < 5 ? 5 : count;
};

/**
 * Create count query - how many products should be fetched
 * Example: count=20
 * @param count
 */
export const prepareCount = (count: number): string => {
  return `count=${count.toString()}`;
};

/**
 * Prepare locale query to avoid errors with cached results between locales
 * @param locale
 */
export const prepareLocale = (locale: string): string => {
  return `locale=${locale}`;
};

/**
 * Prepare temporary basket query parameter.
 * Used in Apple Pay PDP to create basket and add PDP product to it for Apple Pay express checkout.
 */
export const prepareTemporaryBasket = (): string => {
  return 'bid=cookie';
};

/**
 * Prepare request type query
 * @param requestType
 */
export const prepareRequestType = (requestType: string): string => {
  return `request_type=${requestType}`;
};

/**
 * Create page meta tags query
 * Example: pageMetaTags=true
 * @param path
 */
export const preparePageMetaTags = (
  path: string,
  fromSearchForm = false
): string => {
  if (fromSearchForm || !path.match(/\/(tag|explore)\/(.*)/)) return null;
  return 'pageMetaTags=true';
};

/**
 * Prepare a query string from a query params object
 * @param queryParamsObject
 */
export const prepareQuery = (
  queryParamsObject: Record<string, unknown>
): string => {
  return Object.entries(queryParamsObject).reduce(
    (acc, [key, value]) =>
      value != null ? `${acc ? acc.concat('&') : acc}${key}=${value}` : acc,
    ''
  );
};

export const prepareCategoryFilters = (
  filters: Filter[],
  slug: string,
  excpt?: boolean
): string => {
  const arrayOfFilters: string[] = [];
  const flatArray = {} as any;

  filters.forEach((element) => {
    if (flatArray[element.code]) {
      flatArray[element.code] += ` OR ${element.value}`;
    } else flatArray[element.code] = element.value;
  });

  for (const property in flatArray) {
    arrayOfFilters.push(
      `${property}=${encodeURIComponent(flatArray[property])}`
    );
  }

  const cgidID = excpt ? '' : `cgid=${slug}`;

  const categoryFilters = arrayOfFilters.length
    ? `filters=${cgidID},${arrayOfFilters.toString()}`
    : `filters=${cgidID}`;

  return categoryFilters;
};

export const prepareFiltersJson = (
  filters: Filter[],
  slug?: string
): string => {
  const flatArray = {} as any;

  filters.forEach((element) => {
    if (flatArray[element.code]) {
      flatArray[element.code] += ` OR ${element.value}`;
    } else flatArray[element.code] = element.value;
  });

  if (slug) flatArray['cgid'] = slug;
  // This builds the URL query and needs to be URL encoded
  // here before we send it to the ExpressJs API server
  return `fqj=${encodeURIComponent(JSON.stringify(flatArray))}`;
};

/**
 * Create search suggestion query
 * Example: _br_uid_2=uid%3D5917073780329%3A_uid%3D9737480431795%3Av%3D11.8%3Ats%3D1459840113832%3Ahc%3D37&q=shoes&url=http://www.vansca.com&ref_url=http://www.vansca.com/home&currency=CAD
 * @param params
 */
export const prepareSearchSuggestions = (
  params: SearchSuggestionsQueryParams
): string => {
  const queryParams = {
    q: encodeURIComponent(params.query),
    _br_uid_2: params.brId,
    url: params.url,
    ref_url: params.refUrl,
  };

  return prepareQuery(queryParams);
};

export const prepareServiceFromCookie = (
  cookie: string | undefined
): string | void => {
  if (!cookie) return;
  const service = cookie.includes('|') ? cookie.split('|')[0] : '';
  return service ? `service=${service}` : undefined;
};

/**
 * Create retail store Id
 * Example: retailStoreId=ABC123
 * @param storeId
 */
export const prepareRetailStoreId = (storeId: string): string => {
  return `retailStoreId=${storeId}`;
};
