import { CMPlaceholder } from '@vf/api-contract';
import {
  getDynamicField,
  getTranslation,
  mapPasswordValidationSteps,
} from './utils';

export const changePasswordForm = (data: CMPlaceholder) => {
  return {
    component: 'account/ChangePasswordForm',
    props: {
      translations: {
        heading: getTranslation(data, 'heading', '$Change Password$'),
        subheading: getTranslation(
          data,
          'subheading',
          '$Password must contain at least 8 characters and include 1 digit and 1 letter and 1 uppercase letter and 1 lowercase letter.$'
        ),
        passwordLabel: getTranslation(data, 'passwordLabel', '$New Password$'),
        oldPasswordLabel: getTranslation(
          data,
          'oldPasswordLabel',
          '$Old Password$'
        ),
        confirmPasswordLabel: getTranslation(
          data,
          'confirmPasswordLabel',
          '$Confirm New Password$'
        ),
        showText: getTranslation(data, 'showText', '$Show$'),
        hideText: getTranslation(data, 'hideText', '$Hide$'),
        validationMessages: getTranslation(data, 'validationMessages', {
          required: '$This field is required$',
          password: '$Please enter a valid password$',
          confirmPassword: '$Please enter the same value again$',
        }),
        passwordHelperText: getTranslation(
          data,
          'passwordHelperText',
          getTranslation(data, 'passwordMustContain', '$Password must contain$')
        ),
        validationSteps: mapPasswordValidationSteps(
          getTranslation(data, 'validationSteps', {
            characters: '$at least 8 characters$',
            uppercaseLetter: '$1 uppercase letter$',
            number: '$1 number$',
            lowercaseLetter: '$1 lowercase letter$',
          })
        ),
        changePasswordButton: getTranslation(
          data,
          'changePasswordButton',
          '$Change Password$'
        ),
        resetPasswordButton: getTranslation(
          data,
          'resetPasswordCTA',
          '$Reset Password$'
        ),
        cancelButton: getTranslation(data, 'cancelButton', '$Cancel$'),
        changePasswordSuccess: getTranslation(
          data,
          'changePasswordSuccess',
          '$YOUR PASSWORD HAS BEEN SUCCESSFULLY UPDATED.$'
        ),
      },
      signInLink: getDynamicField(data, 'signInLink', undefined),
      showCancelButton: getDynamicField(data, 'showCancelButton', false),
      showOldPasswordInput: getDynamicField(data, 'showOldPasswordInput', true),
      componentVariant: getDynamicField(data, 'componentVariant', 'VARIANT_A'),
    },
  };
};
