import { getBasicInfoTranslation } from './basicAccountForm';
import { CMPlaceholder, CmsSite } from '@vf/api-contract';
import { ComposableContext } from '../types';
import {
  extractCommonConfigLink,
  getCreateAccountTranslations,
  getTranslation,
  getDynamicField,
  getValidationConfigValues,
} from './utils';
import { extractInterests } from './utils/extractInterests';

// basicAccountForm component for TNF
export const basicAccountFormBirthdate = (
  data: CMPlaceholder,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  const [registrationForm]: [CMPlaceholder] = extractCommonConfigLink(
    siteConfiguration,
    'registration-form'
  );

  const xplrPassTranslations = getCreateAccountTranslations(
    registrationForm,
    context,
    siteConfiguration,
    cmsBaseUri
  );

  return {
    component: 'account/BasicInformationForm',
    props: {
      componentVariant: 'VARIANT_C',
      interests: extractInterests(siteConfiguration, cmsBaseUri),
      showXplrPass: getDynamicField(data, 'showXplrPass', false),
      validationConfig: getValidationConfigValues(data),
      translations: {
        ...xplrPassTranslations,
        ...getBasicInfoTranslation(data),
        validationMessages: {
          requiredError:
            getTranslation(data, 'validationMessages.requiredError') ??
            '$Required field$',
          nameError:
            getTranslation(data, 'validationMessages.nameError') ??
            '$Please provide valid name format.$',
          emailError:
            getTranslation(data, 'validationMessages.emailError') ??
            '$Please provide valid e-mail address.$',
          birthdayError:
            getTranslation(data, 'validationMessages.birthdayError') ??
            '$Incorrect date format, please use: {{dateFormat}}$',
          minRegisterAgeError:
            getTranslation(data, 'validationMessages.minRegisterAgeError') ??
            '$You must have at least {{minRegisterAge}} years old$',
          phoneError:
            getTranslation(data, 'validationMessages.phoneError') ??
            '$A valid phone number is required$',
          postalCodeError:
            getTranslation(data, 'validationMessages.postalCodeError') ??
            '$Please match the requested format: {{zipCodeLabel}} {{zipCodeFormat}}.$',
        },
      },
    },
  };
};
