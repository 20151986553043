import { scrollTo as scrollToTop } from './scrollTo';

export function scrollToFirstError(): void {
  const ERROR_SELECTORS =
    '.vf-input--invalid, .vf-select--is-invalid, .vf-notification--invalid, .credit-card-form-error';
  let topMargin;

  const errors = (document.querySelectorAll(
    ERROR_SELECTORS
  ) as unknown) as HTMLElement[];
  if (errors.length >= 1) {
    const firstError = errors[0];
    // is in a modal?
    const modal = firstError.closest('.vf-modal__content');
    if (modal) {
      // we are in a modal
      scrollToTop({ target: modal, top: firstError.offsetTop - 30 });
    } else {
      // not in a modal
      const headerElement = document.querySelector(
        '.vf-header__container'
      ) as HTMLElement;
      const checkoutHeaderElement = document.querySelector(
        '.vf-header'
      ) as HTMLElement;
      const offsetHt = headerElement ? headerElement.offsetHeight : 0;
      const checkoutOffsetHt = checkoutHeaderElement
        ? checkoutHeaderElement.offsetHeight
        : 0;
      topMargin = headerElement ? offsetHt : checkoutOffsetHt;
      scrollToTop({ top: errors[0].offsetTop - (topMargin + 30) });
    }
  }
}
