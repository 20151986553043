import { ComponentInstance, ComposablesStorage } from '../types';
import initStorage from '../utils/storage';

import { ref, Ref, computed, ComputedRef } from '@vue/composition-api';
import { Route } from 'vue-router';

import { ROUTES } from '../utils/routes';

type CurrentQueryParams = Record<
  string,
  string | (string | null)[] | null | undefined
>;

type UseRoutingStorage = {
  previousRoute: Ref<Route>;
  currentRoute: Ref<Route>;
};

const SKIP_ROUTES_FROM_HISTORY: string[] = [
  ROUTES.SIGN_IN(),
  ROUTES.SIGN_OUT(),
  ROUTES.ACCOUNT(''),
  ROUTES.RESET_PASSWORD(),
  ROUTES.FORGOT_PASSWORD(),
];

export const useRouting = (instance: ComponentInstance) => {
  const storage: ComposablesStorage<UseRoutingStorage> = initStorage<UseRoutingStorage>(
    instance,
    'useRouting'
  );

  const previousRoute: Ref<Route> =
    storage.get('previousRoute') ?? storage.save('previousRoute', ref(null));

  const currentRoute: Ref<Route> =
    storage.get('currentRoute') ?? storage.save('currentRoute', ref(null));

  const currentQueryParams: ComputedRef<CurrentQueryParams> = computed(
    () => currentRoute.value?.query ?? {}
  );

  const didUrlChange = (): boolean => {
    const previousValue = previousRoute.value.path;
    const currentValue = currentRoute.value.path;

    return previousValue !== currentValue;
  };

  const didQueryParameterChange = (parameterName: string) => {
    const previousValue = previousRoute.value.query[parameterName];
    const currentValue = currentQueryParams.value[parameterName];

    /** Parameter value has changed, for example from undefined to string/array */
    if (previousValue !== currentValue) {
      return true;
    }

    if (previousValue && currentValue) {
      if (Array.isArray(previousValue) && Array.isArray(currentValue)) {
        /** We know that type are the same now, lets check length of value array */
        /** We also must check if any of the array values have changed */

        const sortedPrevious = previousValue.sort();
        const sortedCurrent = currentValue.sort();

        return (
          previousValue.length !== currentValue.length ||
          !sortedCurrent.every((val, i) => {
            return val === sortedPrevious[i];
          })
        );
      } else {
        return previousValue !== currentValue;
      }
    }

    return false;
  };

  const didQueryParametersChange = (parameters: Array<string>): boolean => {
    return parameters.some((param) => didQueryParameterChange(param));
  };

  const didFiltersOrSortingChange = (): boolean => {
    return !didUrlChange() && didQueryParametersChange(['f', 'sort']);
  };

  function setCurrentRoute(route: Route): void {
    currentRoute.value = route;
  }

  function setPreviousRoute(route: Route): void {
    if (
      !SKIP_ROUTES_FROM_HISTORY.some((skippedRoute) =>
        route.path.includes(skippedRoute)
      )
    ) {
      previousRoute.value = route;
    }
  }

  function setQueryFilterParams(queryParams: {
    [key: string]: string | string[];
  }): void {
    /**
     * this helps to avoid empty string in url
     */
    if (queryParams.f === '') {
      queryParams.f = [];
    }
    instance.$root.$router.push({
      query: {
        ...instance.$root.$route.query,
        ...queryParams,
      },
    });
  }

  function getLocaleFromPath(routePath: string) {
    return (instance.$i18n.locales as string[]).find((locale: string) => {
      return routePath.startsWith(`/${locale}`);
    });
  }

  function getPathWithoutLocalization(routePath: string): string {
    const locale = getLocaleFromPath(routePath) ?? '';
    return (routePath || '').replace(`/${locale}`, '/').replace(/^\/\//, '/');
  }

  return {
    previousRoute,
    currentQueryParams,
    didFiltersOrSortingChange,
    didUrlChange,
    didQueryParameterChange,
    didQueryParametersChange,
    setCurrentRoute,
    setPreviousRoute,
    getPathWithoutLocalization,
    getLocaleFromPath,
    setQueryFilterParams,
  };
};

export const useHistory = () => {
  /**
   * modify last browser history entry to avoid '_sr=1' query string
   */
  const updateHistoryWithAkamaiRedirection = () => {
    const currentUrl = window.location.href;
    const isHasAkamaiRedirection = currentUrl.includes('_sr=1');
    if (!isHasAkamaiRedirection) {
      return false;
    }

    let newUrl = `${window.location.origin}${window.location.pathname}`;
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.delete('_sr');
    if (urlParams.toString()) {
      newUrl += `?${urlParams.toString()}`;
    }
    window.history.replaceState(null, '', newUrl);
  };

  return {
    updateHistoryWithAkamaiRedirection,
  };
};
