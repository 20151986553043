var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VfModal',{staticClass:"progressive-profile",style:({
    '--modal-width-lg': _vm.currentStep === 3 ? '40rem' : '25rem',
    '--modal-width-md': _vm.currentStep === 3 ? '40rem' : '25rem',
  }),attrs:{"visible":"","cross":""},on:{"close":_vm.close}},[_c('div',{staticClass:"progressive-profile__header"},[_c('Notifications',{style:({ 'align-self': 'center', 'margin-bottom': 'var(--spacer-xs)' }),attrs:{"in-modal":""}}),_vm._v(" "),_c('VfHeading',{attrs:{"level":4,"title-modifier":"title-4","title":_vm.t('title'),"text-align":{ small: 'center', medium: 'center', large: 'center' }}}),_vm._v(" "),_c('div',{staticClass:"progressive-profile__header__steps-container"},_vm._l((3),function(n){return _c('span',{key:n,staticClass:"progressive-profile__header__steps-container__step",class:[
          n === _vm.currentStep &&
            'progressive-profile__header__steps-container__step-active' ]})}),0)],1),_vm._v(" "),_c('form',{staticClass:"progressive-profile__content",on:{"submit":function($event){$event.preventDefault();return _vm.validateStep.apply(null, arguments)}}},[_c('div',{staticClass:"progressive-profile__content__body"},[(_vm.currentStep === 1)?[_c('VfPhoneInput',{attrs:{"autofocus":"","show-country-selector":"","label":_vm.t('phoneInputLabel'),"mask":"###-###-####","default-country-code":"US","countries":[
            {
              countryCallingCode: '1',
              countryCode: 'US',
              name: 'United States',
            } ],"valid":!_vm.$v.phone.$error,"error-message":!_vm.$v.phone.required ? _vm.t('requiredField') : _vm.t('phoneErrorMessage')},on:{"blur":function($event){return _vm.$v.phone.$touch()}},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}}),_vm._v(" "),_c('VfText',{attrs:{"font-sizes":['sm'],"font-weight":"bold","text-align":{ small: 'center', medium: 'center', large: 'center' }}},[_vm._v("\n          "+_vm._s(_vm.t('phoneDescription'))+"\n        ")]),_vm._v(" "),_c('VfCheckbox',{attrs:{"disabled":_vm.$v.phone.$invalid,"label":_vm.t('smsNotificationConsentLabel'),"error-message":_vm.t('requiredField')},model:{value:(_vm.smsNotificationConsent),callback:function ($$v) {_vm.smsNotificationConsent=$$v},expression:"smsNotificationConsent"}})]:(_vm.currentStep === 2)?[_c('VfInput',{attrs:{"autofocus":"","label":_vm.t('firstNameInputLabel'),"valid":!_vm.$v.firstName.$error,"error-message":!_vm.$v.firstName.required
              ? _vm.t('requiredField')
              : _vm.t('firstNameInputErrorMessage')},on:{"blur":function($event){return _vm.$v.firstName.$touch()}},model:{value:(_vm.firstName),callback:function ($$v) {_vm.firstName=$$v},expression:"firstName"}}),_vm._v(" "),_c('VfInput',{attrs:{"label":_vm.t('lastNameInputLabel'),"valid":!_vm.$v.lastName.$error,"error-message":!_vm.$v.lastName.required
              ? _vm.t('requiredField')
              : _vm.t('lastNameInputErrorMessage')},on:{"blur":function($event){return _vm.$v.lastName.$touch()}},model:{value:(_vm.lastName),callback:function ($$v) {_vm.lastName=$$v},expression:"lastName"}}),_vm._v(" "),_c('VfInput',{attrs:{"label":_vm.t('zipCodeInputLabel'),"valid":!_vm.$v.zipCode.$error,"error-message":!_vm.$v.zipCode.required
              ? _vm.t('requiredField')
              : _vm.t('zipCodeInputErrorMessage')},on:{"blur":function($event){return _vm.$v.zipCode.$touch()}},model:{value:(_vm.zipCode),callback:function ($$v) {_vm.zipCode=$$v},expression:"zipCode"}}),_vm._v(" "),_c('VfInput',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],attrs:{"label":_vm.t('birthDateInputLabel'),"placeholder":"MM/DD/YYYY","inputmode":"numeric","notification":_vm.t('birthDateInputTooltip'),"disabled":!!_vm.basicInformation.birthDate,"valid":!_vm.$v.birthDate.$error,"error-message":_vm.getBirthdateMessage(_vm.$v.birthDate)},on:{"blur":function($event){return _vm.$v.birthDate.$touch()}},model:{value:(_vm.birthDate),callback:function ($$v) {_vm.birthDate=$$v},expression:"birthDate"}})]:(_vm.currentStep === 3)?[_c('VfText',{attrs:{"font-sizes":['sm'],"text-align":{ small: 'center', medium: 'center', large: 'center' }}},[_vm._v("\n          "+_vm._s(_vm.t('interestsTitle'))+"\n        ")]),_vm._v(" "),_c('VfInterestsCollection',{attrs:{"interests":_vm.interests},on:{"interests-change":_vm.handleInterestsChange}}),_vm._v(" "),(_vm.isInterestsError)?_c('div',{staticClass:"progressive-profile__interests-error"},[_vm._v("\n          "+_vm._s(_vm.t('interestsErrorMessage'))+"\n        ")]):_vm._e()]:_vm._e()],2),_vm._v(" "),_c('div',{staticClass:"progressive-profile__content__footer"},[_c('ThemeButton',{attrs:{"type":"submit","color":"primary","size":"full","disabled":_vm.isSaving}},[_vm._v("\n        "+_vm._s(_vm.currentStep === 3 ? _vm.t('submit') : _vm.t('next'))+"\n      ")]),_vm._v(" "),_c('ThemeLink',{staticClass:"progressive-profile__content__footer__skip-button",attrs:{"data-testid":"progressive-profile-skip-button","tag":"button","color":"primary","legacy":false,"disabled":_vm.isSaving},on:{"click":_vm.skipStep}},[_vm._v("\n        "+_vm._s(_vm.t('skipForNow'))+"\n      ")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }