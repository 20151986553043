import { Context } from '@nuxt/types';
import { ComponentInstance } from '@vf/composables/src/types';
import { isClient } from '@vf/shared/src/utils/helpers';

declare global {
  interface Window {
    vfPageCategory: string;
  }
}

export function getVueInstanceFromContext(context: Context): ComponentInstance {
  return ({
    ...context.app,
    $route: context.route,
    $i18n: context.app.i18n,
  } as unknown) as ComponentInstance;
}

export const setPageTypeName = (pageTypeName: string): void => {
  if (typeof window !== 'undefined') {
    window.vfPageCategory = pageTypeName;
  }
};

export const isImageTypeOf = (
  imageSrc: string | { large: string },
  extension: string
): boolean => {
  const fileName = typeof imageSrc === 'object' ? imageSrc.large : imageSrc;
  return !!fileName.match(new RegExp(`\.${extension}$`));
};

export function getDomainName(context: Context): string {
  if (context.$env.DOMAIN_OVERRIDE) {
    return context.$env.DOMAIN_OVERRIDE;
  }

  return context.$env.DOMAIN;
}

export function getRenderer(): string {
  return isClient ? 'CSR' : 'SSR';
}

export function getCoremediaAxiosHeaders(root: ComponentInstance): any {
  const renderer = getRenderer();
  const envProvider =
    root.$env.NODE_ENV === 'development' ? root.$env : process.env;
  if (root.$env.NODE_ENV === 'development' || renderer === 'SSR') {
    return {
      [envProvider[`COREMEDIA_ACCESS_HEADER_SSR`]]:
        envProvider[`COREMEDIA_ACCESS_TOKEN_SSR`],
      ['x-bypass-maintenance']:
        envProvider['HEADER_BYPASS_AKAMAI_MAINTENANCE_PAGE'],
      ['vf-server-trust-id']: envProvider['SECRET_SERVER_CDN_TRUST_HEADER'],
    };
  }

  return {};
}

export function getUniqueStatuses(items: any[]): string[] {
  return [...new Set(items?.map((item) => item.status))];
}

export const mapValueOrFallback = <T>(
  map: T,
  fallbacks: Record<string, unknown>
): T => {
  for (const key of Object.keys(fallbacks)) {
    map[key] ??= fallbacks[key];
  }

  return map;
};

export const getCookieName = (
  name: string,
  vueInstance: ComponentInstance
): string => {
  const apiSiteId = vueInstance.$getEnvValueByCurrentLocale<string>(
    'API_SITE_ID'
  );
  return `vfa_${apiSiteId}_${name}`;
};

export * from './cartProductList';
export * from './promotions';
export * from './payments';
export * from './resolveTwoConditions';
export * from './pdpUrlUtils';
export * from './mapProductDetails';
export * from './dataProtected';
export * from './getFlashesByProductId';
