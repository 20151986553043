var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._l((_vm.navigation),function(l1,l1Key){return _c('VfMobileMenuColumn',{key:l1Key,staticClass:"vf-mobile-mega-menu-column",attrs:{"title":l1.mobileTitle || l1.title},on:{"change-active":function($event){return _vm.handleActiveMegaMenuColumn({ l1: $event })}},scopedSlots:_vm._u([{key:"title",fn:function(ref){
var title = ref.title;
var changeActive = ref.changeActive;
var active = ref.active;
return [_c('ThemeLink',_vm._g({staticClass:"vf-header-mobile-menu__menu-item",attrs:{"data-navigation":l1.dataNavigation,"to":l1.columns && l1.columns.length ? null : l1.link,"tabindex":active ? '-1' : '0'}},{
          click: function () { return l1.columns && l1.columns.length
              ? changeActive(title)
              : _vm.handleMobileNavLinkClick({
                  link: l1.link,
                  l1: l1.name,
                  l2: '',
                  l3: '',
                  isModal: false,
                  isNewTab: false,
                }); },
        }),[_c('VfMenuItem',{staticClass:"vf-mega-menu-column__header",attrs:{"icon":l1.columns && l1.columns.length ? 'chevron_right' : '',"label":l1.mobileTitle || l1.title},scopedSlots:_vm._u([{key:"label",fn:function(){return [(l1.isImage)?_c('VfPicture',{staticClass:"vf-menu-item__label",attrs:{"src":l1.imageData,"width":l1.imageData.width,"height":l1.imageData.height,"lazy":false}}):_c('span',{staticClass:"vf-menu-item__label"},[_vm._v("\n              "+_vm._s(title)+"\n            ")])]},proxy:true}],null,true)})],1)]}}],null,true)},[_vm._v(" "),(l1.columns)?_c('VfMobileMenu',[_c('VfList',[(l1.link && (l1.mobileTitle || l1.title))?_c('VfListItem',[_c('ThemeLink',{class:l1.cssClass,attrs:{"data-dom-location":_vm.AnalyticsDataDomLocation.TopNavigation,"data-navigation":l1.dataNavigation,"to":l1.link,"target":l1.openInNewTab ? '_blank' : '_self'},on:{"click":function($event){return _vm.handleMobileNavLinkClick($event, {
                link: l1.link,
                l1: l1.name,
                l2: '',
                l3: '',
                isModal: l1.openInNewModal,
              })}}},[_c('VfMenuItem',{attrs:{"label":_vm.getShopAllLabel(l1.mobileTitle, l1.title),"icon":""}})],1)],1):_vm._e()],1),_vm._v(" "),_vm._l((l1.columns),function(l2MenuItems,l2Key){return _c('div',{key:l1Key + '_' + l2Key,class:{
          'vf-mega-menu--single-list': !l2MenuItems.children,
        },style:({
          order:
            !_vm.$isServer && _vm.$viewport.size === 'small'
              ? l2MenuItems.orderSmall
              : l2MenuItems.orderMedium,
        })},_vm._l((l2MenuItems.level2MenuItems),function(l2,l2ItemsKey){return _c('div',{key:l1Key + '_' + l2ItemsKey},[(!l2.children)?_c('VfList',[_c('VfListItem',[_c('ThemeLink',{class:l2.cssClass,style:(Object.assign({}, l2.componentStyles)),attrs:{"data-dom-location":_vm.AnalyticsDataDomLocation.TopNavigation,"data-navigation":l2.dataNavigation,"to":l2.link,"target":l2.openInNewTab ? '_blank' : '_self'},on:{"click":function($event){return _vm.handleMobileNavLinkClick($event, {
                    link: l2.link,
                    l1: l1.name,
                    l2: l2.name,
                    l3: '',
                    isModal: l2.openInNewModal,
                  })}}},[_c('VfMenuItem',{attrs:{"label":l2.mobileTitle || l2.title},scopedSlots:_vm._u([{key:"mobile-nav-icon",fn:function(){return [_vm._v("​")]},proxy:true}],null,true)})],1)],1)],1):(l2.children && l2.expandMobile)?_c('VfList',_vm._l((l2.children),function(expandedItem,expandedKey){return _c('VfListItem',{key:l2ItemsKey + '_' + expandedKey},[_c('ThemeLink',{class:expandedItem.cssClass,attrs:{"data-dom-location":_vm.AnalyticsDataDomLocation.TopNavigation,"data-navigation":expandedItem.dataNavigation,"to":expandedItem.link,"target":expandedItem.openInNewTab ? '_blank' : '_self'},on:{"click":function($event){return _vm.handleMobileNavLinkClick($event, {
                    link: expandedItem.link,
                    l1: l1.name,
                    l2: expandedItem.name,
                    l3: '',
                    isModal: expandedItem.openInNewModal,
                  })}}},[_c('VfMenuItem',{attrs:{"label":expandedItem.mobileTitle || expandedItem.title,"icon":""}})],1)],1)}),1):_c('VfMobileMenuColumn',{attrs:{"title":l2.mobileTitle || l2.title},on:{"change-active":function($event){return _vm.handleActiveMegaMenuColumn({
                l1: l1.mobileTitle || l1.title,
                l2: $event,
              })}}},[_c('VfList',_vm._l((l2.children),function(l3,l3Key){return _c('VfListItem',{key:l1Key + '_' + l2ItemsKey + '_' + l3Key},[_c('ThemeLink',{class:l3.cssClass,attrs:{"data-dom-location":_vm.AnalyticsDataDomLocation.TopNavigation,"data-navigation":l3.dataNavigation,"to":l3.link,"target":l3.openInNewTab ? '_blank' : '_self'},on:{"click":function($event){return _vm.handleMobileNavLinkClick($event, {
                      link: l3.link,
                      l1: l1.name,
                      l2: l2.name,
                      l3: l3.name,
                      isModal: l3.openInNewModal,
                    })}}},[_c('VfMenuItem',{attrs:{"label":l3.mobileTitle || l3.title,"label-styles":Object.assign({}, l3.componentStyles)},scopedSlots:_vm._u([{key:"mobile-nav-icon",fn:function(){return [_vm._v("​")]},proxy:true}],null,true)})],1)],1)}),1)],1)],1)}),0)})],2):_vm._e()],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }