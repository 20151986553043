


















import { defineComponent } from '@vue/composition-api';
import { ROUTES, useCart, useI18n } from '@vf/composables';
import useRootInstance from '@/shared/useRootInstance';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';

export default defineComponent({
  name: 'CartIcon',
  setup() {
    const { root } = useRootInstance();
    const { isCoreRedesignEnabled } = useFeatureFlagsStore();

    const theme = root.$themeConfig.staticCart;

    const { totalItems } = useCart(root);
    const { localePath } = useI18n(root);

    return {
      theme,
      totalItems,
      isCoreRedesignEnabled,
      cartPath: localePath(ROUTES.CART()),
    };
  },
});
