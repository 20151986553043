import { Price } from '@vf/api-contract/src/entities/price';
import { Taxonomy } from '@vf/composables/src/types';
import {
  CartCustomerNotificationType,
  FlashErrorType,
  GiftOption,
  OptionItem,
  OrderItemDetailed,
  ProductShippingOption,
  Shipment,
} from '@vf/api-contract';
import { ShippingMethods, SignUpResponse } from '../src/api-types';

export interface ImageAddress {
  url?: string;
}

export interface Image {
  large: ImageAddress;
  medium: ImageAddress;
  small: ImageAddress;
  width: number;
  height: number;
}

export interface ProductImage {
  src: string;
  thumbnail: string;
  alt: string;
}

export interface Article {
  title: string;
  image: Image;
  text: string;
  richText?: string;
  link: string;
  readMoreText: string;
  showReadMore: boolean;
  dataNavigation: string | null;
  subjectTaxonomy?: Taxonomy[];
  publicationDate?: string;
}

export interface ArticleResponse {
  items: Article[];
  filters: string[];
  filter?: string;
  page: number;
  totalPages: number;
}

export interface Rating {
  numReviews: number;
  score: number;
  reviews?: string;
}

export interface Color {
  id?: number;
  label: string;
  value: string;
  src: string;
  slug: string;
  description?: string;
  pageURL?: string;
  available?: boolean;
  badges?: string[];
  presale?: boolean;
}

interface SizeInfo {
  id?: number;
  value: string;
  available: boolean;
  label?: string;
}

type AllowedLabelKeys = `altLabel${string}`;
export type Size = SizeInfo & Record<AllowedLabelKeys, string>;

export interface Length {
  id?: number;
  value: string;
  available: string;
}

export interface Width {
  id?: number;
  value: string;
  available: boolean;
}

export interface Zip {
  id?: number;
  value: string;
  available: boolean;
}

export interface Quantity {
  id: number;
  value: number;
}

export interface ColorSwatch {
  colorHexCode: string;
  name: string;
  value: string;
  defaultColor: boolean;
  pageUrl: string;
  price?: Price;
  labels?: string;
}

export interface ProductValidation {
  missingSize?: boolean;
  missingLength?: boolean;
  missingZip?: boolean;
  missingWidth?: boolean;
}

export interface ProductVariantStock {
  quantity: number;
  inStock: boolean;
}

export interface ProductVariant {
  attributes: ProductVariantsAttribute;
  id: string;
  price: Price;
  stock: ProductVariantStock;
  notifyMe?: boolean;
}

export interface ProductInventoryVariant {
  [k: string]: {
    quantity: number;
    inStock: boolean;
  };
}

export interface StoredProductInventoryVariant {
  productId: string;
  stock: {
    [stockSource: string]: ProductInventoryVariant;
  };
}

export interface ProductVariantsAttribute {
  length?: string;
  color: string;
  size?: string;
  width?: string;
  zip?: string;
}

export interface PageMetaData {
  [k: string]: PageMetaTag;
}

export interface PageMetaTag {
  id: keyof PageMetaData;
  content: string;
  type: string;
}

export interface Product {
  attributes: any;
  available?: string;
  masterId: string;
  colorBundleCode?: string;
  colorDescription?: string;
  colorCode: string;
  displayPriceRange?: boolean;
  id: string;
  itemId?: string;
  link?: string;
  images?: ProductImage[];
  productImageUrl?: string;
  merchantProductId: string;
  name: string;
  description: string;
  rating: Rating;
  slug: string;
  size: Size | null;
  length: Length | null;
  quantity: Quantity;
  color: Color | null;
  colors: Color[];
  sizes: Size[];
  width: Width | null;
  zip: Zip | null;
  widths: Width[];
  zips: Zip[];
  lengths: Length[];
  quantities: Quantity[];
  sku: string;
  variant: ProductVariant;
  variants: ProductVariant[];
  validation: ProductValidation;
  customsExternalurl?: string;
  meta: PageMetaData;
  model?: string;
  season?: string;
  styleCode: string;
  pageUrl: string;
  fullPageUrl: string;
  pdpUrl?: string;
  primaryCategoryName: string;
  primaryCategorySlug: string;
  searchIfUnavailable: boolean;
  sizeChart: string | string[];
  gender: string | string[];
  productTypeDepartment?: string;
  notifyMe: boolean;
  technology?: string[];
  targetPublishDate?: string;
  productDetails?: string[];
  /**
   * Customs attributes
   *
   * standard products (present in PLP and landing on PDP)
   * isCustoms=false
   * customCustomizer=false
   * dummyCustoms=false
   * recipeId=empty
   *
   * dummy customs (present in PLP and landing on PDP)
   * isCustoms=false
   * customCustomizer=false
   * dummyCustoms=true
   * recipeId=some value
   *
   * customs product pre-designed (present in PLP and landing on customizer)
   * isCustoms=true
   * customCustomizer=true
   * dummyCustoms=false
   * recipeId=some value
   *
   * base customs product (the white one present only in the iFrame customizer)
   * isCustoms=true
   * customCustomizer=true
   * recipeId=some value
   */
  recipeId?: string;
  customized?: boolean;
  customsCustomizer?: boolean;
  dummyCustoms?: boolean;
  isCustoms?: boolean;
  customsRecipeID?: string;
  styleColor?: string;
  customModel?: string;
  customsTool?: string;
  customsExternalUrl?: string;
  benefits?: { name: string; alt: string }[];
  crossSellProducts?: ProductCrossSellItem[];
  upsellProducts?: ProductUpsellItem[];
  moreColorsAvailable?: boolean;
  product_slug: string;
  productSlug?: string;
  recipe?: string;
  wearTestDaysAttribute?: unknown;
  sizeContext?: unknown;
  presale?: boolean;
  labels?: string[];
  bundleId?: string;
  customsAvailability?: string;
  productInventoryState?: ProductInventoryState;
  precreatedCustomsCode?: string;
}

export interface ProductUpsellItem {
  id: string;
  sku: string;
  name: string;
  description: string;
  pageUrl: string;
  price: Price;
  stock: {
    inStock: boolean;
    maxQty: number;
    buyable: boolean;
    onlyOnline: boolean;
  };
  styleCode?: string;
  colorCode?: string;
}

export type ProductCrossSellItem = ProductUpsellItem;

export interface CategoryProduct {
  colorBundleCode?: string;
  colorCode: string;
  colorDescription: string;
  id: string;
  pid: string;
  images: unknown[];
  labels: string[];
  merchantProductId: string;
  name: string;
  price: Price;
  primary_category_name: string;
  productType: {
    type: 'product_type';
    variationGroup: boolean;
  };
  rating: {
    score: string;
    numReviews: string;
  };
  slug: string;
  styleCode: string;
  variant: string;
  season: string;
  styleColor: string;
  isCustoms: boolean;
  dummyCustoms: boolean;
  presale: boolean;
  recipeId?: string;
  recipe?: string;
  customsRecipeID?: string;
  bundleId?: string;
  color_swatches?: ColorSwatch[];
  styleId?: string;
  productInventoryState?: ProductInventoryState;
  displayPriceRange?: boolean;
  pageUrl: string;
}

export interface ProductAttribute {
  code: 'color' | 'length' | 'size' | 'width' | 'zip';
  label: string;
  options: Array<ProductAttributeOption>;
}

export interface ProductAttributeOption {
  available: boolean;
  label: string;
  value: string;
}

type ShipNodeType = 'DC' | 'Store';

export type SalesChannel = 'Mainline' | 'Pinnacle';

export interface ProductShippingNode {
  shipNodeId: string;
  shipNodeType: ShipNodeType;
}

export interface CartLineItem {
  id: string;
  itemId: string;
  masterId: string;
  masterId2?: string;
  merchantProductId: string;
  productId: string;
  sku: string;
  slug: string;
  name: string;
  link: string;
  pdpUrl: string;
  pdpUrlRelative?: string;
  qty: number;
  maxQty: number;
  maxAllowedQty: number;
  available: string;
  quantityAvailable: number;
  price: CartLineItemPrice;
  image: string;
  productImageUrl?: string;
  productImageURL: string;
  images: { small: string };
  imageDeclined?: boolean;
  productType: {
    type: string;
    variationGroup: boolean;
  };
  colorBundleCode?: string;
  colorCode: string;
  colorDescription: string;
  variant: string;
  variants: CartLineItemAttribute[];
  model: string;
  season: string;
  styleCode: string;
  productPromotions?: OrderPromotion[];
  notEligibleProductInfoMsg?: string; // message to display for product not eligible for promotion
  customerNotifications?: CartLineItemCustomerNotification[];
  shippingId: string;
  shippingOptions?: ProductShippingOption[];
  shippingNodes?: ProductShippingNode[];
  shippingMethod?: {
    code: string;
    deliveryTime: string;
    label: string;
  };
  isCustoms: boolean;
  recipe?: string;
  recipeId: string;
  customsRecipeID?: string;
  customsCustomizer?: boolean;
  customsExternalUrl?: string;
  dummyCustoms?: boolean;
  precreatedCustomsCode?: string;
  taxTotal: number;
  deliveryTime: string;
  savedForLater?: boolean;
  gift: boolean;
  giftOption?: GiftOption;
  giftBoxPrice?: number;
  isGiftBoxSelected?: boolean;
  isGiftAllowed?: boolean;
  isGiftBoxAvailable?: boolean;
  optionItems: OptionItem[];
  bonus: boolean;
  isRewardProduct: boolean;
  salesChannel?: SalesChannel;
}

export interface CartLineItemOutOfStock {
  id: string;
  name: string;
  productImageURL: string;
  pdpUrl: string;
  variants: CartLineItemAttribute[];
}

export interface NotAddedItem {
  productId: string;
  masterId: string;
  pdpUrl: string;
  productImageURL: string;
  name: string;
  variants: {
    code: string;
    label: string;
    id?: string;
    value?: string;
  }[];
  requestedQty: number;
  availableQty: number;
  maxQty: number;
  reason:
    | 'WILL_EXCEED_MAX_ALLOWED_QTY'
    | 'NOT_ENOUGH_INVENTORY'
    | 'NO_LONGER_AVAILABLE';
}

export interface CartLineItemAttribute {
  code: string;
  id: string;
  label: string;
  value: string;
}

export interface CartLineItemPrice extends Price {
  /** Original cart item price */
  original: number;
  /** Sale product price excluding applied promotions discounts */
  current: number;
  /** current * qty */
  rowTotal: number;
  /** Total item price including product level discounts * qty */
  priceAfterItemDiscount: number;
  /** Total item price including order level discounts * qty */
  priceAfterOrderDiscount: number;
  /** Price with order level discounts */
  proratedPrice: number;
}

export type PaymentInstruments = {
  amount: number;
  client_token: string;
  payment_instrument_id: string;
  c_gcCardNumber?: string;
  c_paymentID?: string;
  c_requestID?: string;
  payment_method_categories: {
    asset_urls: {
      descriptive: string;
      standard: string;
    };
    identifier: string;
    name: string;
  }[];
  payment_method_id: PaymentMethodCode;
  session_id: string;
  attachment?: {
    content_type: string;
    body: string;
  };
};

export interface Cart {
  discounts: {
    autoAppliedPromotions?: AutoAppliedPromotion[];
    couponDiscounts?: CartCouponDiscount[];
    productDiscounts?: CartProductDiscount[];
    shippingDiscounts?: CartShippingDiscount[];
    otherDiscounts?: number;
  };
  currency: string;
  items: CartLineItem[];
  totalItems: number;
  couponItems?: CouponItem[];
  coupons?: CartCoupon;
  id: string;
  shareToken?: string;
  shippingMethods: CartShippingGroup[];
  billingAddress: Address;
  totals: CartTotals;
  orderPromotions?: OrderPromotion[];
  paymentMethod?: {
    code: string;
    additionalData: any;
  };
  payment_instruments: PaymentInstruments[];
  flash: FlashError[];
  bounceBackVouchers?: ApproachingDiscount[]; // TODO remove whn replaced with approachingDiscount
  approachingDiscount?: ApproachingDiscount[];
  customerNotifications?: CartLevelCustomerNotification[];
  products: any[];
  giftedBasketId?: string;
  giftBoxPrice?: number;
  featureFlags?: CartFeatureFlags;
  upsellProducts?: ProductUpsellItem[];
}

export interface CartFeatureFlags {
  applyBestShippingMethod?: boolean;
}

export enum ProductAttributeCodes {
  Color = 'color',
  Length = 'length',
  Size = 'size',
  Width = 'width',
  Zip = 'zip',
  Fit = 'fitType',
}

export enum ProductAttributeValues {
  OneSize = 'OS',
}

export enum ProductInventoryState {
  InStock = 'InStock',
  SoldOut = 'SoldOut',
  OutOfStock = 'OutOfStock',
}

export enum PaymentMethodCode {
  CREDIT_CARD = 'CREDIT_CARD',
  REWARD_CARD = 'REWARD_CARD',
  REWARD_CERTIFICATE = 'REWARD_CERTIFICATE', // 1.0 backward compatibilty
  REWARD_CODE = 'REWARD_CODE',
  PAYPAL_EXPRESS = 'PAYPAL_EXPRESS',
  APPLE_PAY_EXPRESS = 'APPLE_PAY_EXPRESS',
  PAYPAL = 'PAYPAL',
  GIFT_CARD = 'GIFT_CARD',
  ZERO_ORDER = 'ZERO_ORDER',
  KLARNA = 'KLARNA',
  APPLEPAY = 'DW_APPLE_PAY',
  ATHLETES = 'ATHLETES_PAYMENT',
  ATHLETE_CREDIT = 'ATHLETE_CREDIT',
  LOYALTY_POINTS = 'LOYALTY_POINTS',
}

export enum PaymentDetailsCode {
  KLARNA = 'KL',
}

export interface ApproachingDiscount {
  earned: boolean;
  discount?: string;
  distanceFromConditionThreshold?: string;
  conditionThreshold?: string;
  merchandiseTotal?: string;
  currency?: string;
  promotionId?: string;
  calloutMsg?: string;
}

export interface CartCouponDiscount {
  code: string;
  discount: number;
}

export interface AutoAppliedPromotion {
  calloutMsg: string;
  amount: number;
  level: string;
  promotionId: string;
  promotionName: string;
}

export interface CartProductDiscount {
  id: string;
  name: string;
  discount: number;
}

export interface CartShippingDiscount {
  name: string;
  discount: number;
}

export interface FlashError {
  code: FlashErrorType;
  path: string;
  details?: FlashErrorDetails;
  message?: string;
}

export interface FlashErrorDetails {
  primaryCategorySlug?: string;
  basePrice?: number;
  salesPrice?: number;
  requiredQty?: number;
  productId?: string;
  sku?: string;
  color?: string;
  size?: string;
  missingQty?: number;
  availableQty?: number;
  masterProductId?: string;
  productSlug?: string;
  variationGroupId?: string;
  productName?: string;
  productURL?: string;
  productImageURL?: string;
  length?: string;
  maxAllowedQty?: number;
  variationAttributes?: {
    color?: string;
    size?: string;
  };
}

export interface CartLevelCustomerNotification {
  type: CartCustomerNotificationType;
  details: {
    level: string;
    [key: string]: string;
  };
}

export interface CartLineItemCustomerNotification {
  type: string;
  message: string;
  path: string;
  details: {
    productId: string;
    productName: string;
    productImageURL: string;
    color: string;
    size: string;
    previousShippingMethodId: string;
    currentShippingMethodId: string;
    level: string;
    uuid: string;
  };
}

export interface CartShippingGroup {
  shippingId: string;
  code: string;
  label: string;
  cost: number;
  currency: string;
  address: Address;
  shippingPromotions: OrderPromotion[];
  storeId?: string;
  methods: ShippingMethods[];
}

export interface CartShippingMethodPrice {
  amount: number;
  finalAmount?: number;
  currency: string;
}

export interface CouponItem {
  code: string;
  statusCode: string;
  couponItemId: string;
  valid: boolean;
}

export interface OrderPromotion {
  appliedDiscount: {
    amount: number;
    percentage: number;
    type: string;
  };
  couponCode: string;
  calloutMsg: string;
  itemText: string;
  price: number;
  priceAdjustmentId: string;
  promotionId: string;
  campaignId?: string;
}

export interface CartCoupon {
  code: string;
  currency: string;
  id: string;
}

export interface CartTotals {
  total: number;
  itemTotal: number;
  totalDiscount?: number;
  totalWithoutTax: number;
  tax: number;
  shipping: number;
  remainingToPay?: number;
  enforcedShippingFee?: number;
  currency?: string;
}

export interface Address {
  id?: string;
  shippingId?: string;
  addressId?: string;
  approachType?: string;
  firstName: string;
  lastName: string;
  email: string;
  country: string;
  addressLine1: string;
  addressLine2: string;
  postalCode: string;
  city: string;
  province: string;
  phone: string;
  phoneReadonly?: string;
  stateCode?: string;
  subscriptions?: {
    newsletterConsent: boolean;
  };
  storeId?: string;
  altFirstName?: string;
  altLastName?: string;
  altEmail?: string;
  countryCode?: string;
}

export interface AddressSelectionAdditionalOption {
  value: string;
  label: string;
}

/**
 * ECOM15-9020 - to change
 */
export type ApproachType = 'B' | 'S' | 'L';

export interface AddressCustomer {
  id: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  postalCode: string;
  countryCode: string;
  country: string;
  province: string;
  approachType: ApproachType;
  recipientFirstName: string;
  recipientLastName: string;
  recipientContactPhone: string;
  recipientContactEmail: string;
}

// TODO: update when ECOM15-7381 is done
export interface OrderDetails {
  [prop: string]: any;
}

export interface OrderStatuses {
  [key: string]: string;
}

export interface SignUpBody {
  firstName: string;
  lastName: string;
  password: string;
  email: string;
}

export interface TestNewAccount extends SignUpResponse {
  userData: SignUpBody;
}

export interface TestLogIn {
  authorizationCode: string;
  usid: string;
  cookie: string;
  codeVerifier: string;
}

export interface TestLogInWithToken {
  cookie: [];
  consumerId: string;
}

export interface GuestSession {
  cookie: [];
  usid: string;
  consumerId: string;
}

export interface InitGuestSessionHelper {
  cookie: [];
  usid: string;
  consumerId: string;
}

export interface RequestHeader {
  cookie?: string;
  usid?: string;
  locale?: string;
}

export interface IConsumerDetails {
  lastName: string;
  birthDate: string;
  consumerId: string;
  firstName: string;
  email: string;
  gender?: string;
  postalCode: string;
  phone: string;
  source?: {
    store?: string;
    campaignName?: string;
  };
  address?: never[]; // TODO: Check after ECOM15-9020
  employee?: {
    employee?: boolean;
    currentSpend: number;
    annualCap: number;
  };
  athlete?: {
    athlete?: boolean;
    currentSpend: number;
    annualCredit: number;
  };
  prosumer?: {
    prosumer?: boolean;
    currentSpend: number;
    annualCredit: number;
  };
}

export interface Subscription {
  id?: string;
  category: 'subscription' | 'enrollement';
  type: 'Newsletter' | 'Privacy' | 'Loyalty';
  optin: boolean;
  doubleOptin: boolean;
  optIndate?: string;
  optoutDate?: string;
  doubleOptIndate?: string;
  channel?: 'email' | string;
  region: string;
  country: string;
  optinSource: string;
}

export interface Enrollment {
  type: 'Privacy' | 'Loyalty';
  optin: boolean;
  doubleOptin: boolean;
  country: string;
  region: string;
}

export interface ConsumerProfileUserDataEntry {
  key: string;
  value: string;
}

export interface IConsumerProfile {
  consumerDetails: IConsumerDetails;
  subscriptions: Subscription[];
  enrollments?: Enrollment[];
  latestSubscriptionUpdate?: Subscription[];
  preferences?: never[];
  useData?: ConsumerProfileUserDataEntry[];
  isLoyaltyMember?: boolean;
  loyaltyOptInDate?: string;
}

export interface BrandifyStoreInfo {
  fax?: string;
  province: string;
  uid: number;
  pwater?: string;
  slug?: string;
  ac?: string;
  stat?: string;
  psnow?: any;
  caliuo?: any;
  lbts?: any;
  locdesc?: any;
  w?: any;
  t?: any;
  imagepath5?: any;
  address2?: any;
  pbike?: any;
  off: string;
  pskate?: any;
  sandal?: any;
  apparel?: any;
  pro_shop?: any;
  imagepath?: any;
  name: string;
  clientkey: string;
  f?: any;
  iname?: any;
  mte?: any;
  justinhenry?: any;
  _distanceuom: string;
  out?: any;
  retail_outlets?: any;
  otw?: any;
  sci_fi?: any;
  specialhours_locator?: any;
  thu?: any;
  offer?: any;
  tnvn?: any;
  distchan?: any;
  vl?: any;
  cabarbour?: any;
  juliank?: any;
  eu_features?: any;
  city_slug?: any;
  lottie_skate?: any;
  offer3?: any;
  aut?: any;
  city: string;
  icon: string;
  bho?: any;
  phone?: any;
  surf?: any;
  calinord?: any;
  localurl?: any;
  footwear?: any;
  url?: any;
  snowboard_boots?: any;
  sa?: any;
  updated?: any;
  offer2?: any;
  state?: any;
  rank: string;
  postalcode: string;
  imagepath3?: any;
  ca?: any;
  has_delivery?: '1' | string;
  has_curbside_pickup?: null;
  has_in_store_pickup?: null;
  has_product?: boolean;
  sfs_enabled?: string | null;
  sts_enabled?: string | null;
  bopis_enabled?: string | null;
  curbside_enabled?: string | null;
  locname?: any;
  imagepath2?: any;
  address1: string;
  dsply_adr?: any;
  twitter?: any;
  enterprise_store_identifier?: any;
  country: string;
  accnum?: any;
  pro_skate?: any;
  ebts?: any;
  longitude: number;
  su?: any;
  latitude: number;
  email?: any;
  m?: any;
  _distance: string;
  imagepath4?: any;
}

export interface ReturnItem {
  qty: number;
  returnReason: string;
  item: {
    sku: string;
    orderLineKey: string;
  };
  isInvalid?: boolean;
  isSelected?: boolean;
}

export interface ReturnDetailedItem extends OrderItemDetailed {
  orderLineKey: string;
  returnQty: string;
  reasonCode: string;
  returnReason?: string;
  reasonText?: string;
  isInvalid?: boolean;
  isSelected?: boolean;
}

export interface ReturnPayload {
  customerEmail: string;
  items: ReturnItem[];
  isRefundOnGiftCard: boolean;
  giftRecipientEmailID?: string;
}

export interface ReturnObject {
  customerEmail: string;
  items: ReturnDetailedItem[];
  isRefundOnGiftCard: boolean;
}

export interface ReturnPreviewObject {
  returnNumber?: string;
  orderNumber?: string;
  orderNo?: string;
  buyerUserId?: string;
  customerPhoneNo?: string;
  documentType?: string;
  draftOrderFlag?: boolean;
  enterpriseCode?: string;
  entryType?: string;
  orderHeaderKey?: string;
  receivingNode?: string;
  extnIsRefundOnGiftCard: boolean;
  extnReturnLabel?: string;
  extnReturnTrackingNo?: string;
  extnReturnTrackingURL?: string;
  personInfoShipTo?: ReturnPreviewObjectAddress;
  personInfoBillTo?: ReturnPreviewObjectAddress;
  orderLines?: [ReturnPreviewObjectOrderLine];
  overallTotals?: ReturnPreviewObjectOverallTotals;
  headerCharges?: ReturnPreviewObjectHeaderCharges;
  headerTaxes?: ReturnPreviewObjectHeaderTaxes;
  derivedFromOrder?: {
    orderNo: string;
    orderHeaderKey: string;
  };
  returnedItems?: number;
  items?: any;
  status?: string;
}

export interface ReturnPreviewObjectHeaderCharges {
  HeaderCharge?: {
    InvoicedChargeAmount: string;
    ChargeCategory: 'RETURN_FEE' | string;
    Reference: string;
    ChargeName: string;
    IsDiscount: 'Y' | 'N';
    RemainingChargeAmount: string;
    ChargeNameKey: string;
    ChargeAmount: string;
    IsBillable: 'Y' | 'N';
  }[];
}

interface ReturnPreviewObjectHeaderTaxes {
  [key: string]: string;
}

export interface ReturnPreviewObjectAddress {
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  city: string;
  country: string;
  dayPhone: string;
  firstName: string;
  lastName: string;
  mobilePhone: string;
  state: string;
  zipCode: string;
}

export interface ReturnPreviewObjectLineTotals {
  charges: number;
  discount: number;
  extendedPrice: number;
  lineTotal: number;
  lineTotalWithoutTax: number;
  optionPrice: number;
  pricingQty: number;
  shippingBaseCharge: number;
  shippingCharges: number;
  shippingDiscount: number;
  shippingTotal: number;
  tax: number;
  unitPrice: number;
}

export interface ReturnPreviewObjectLineTax {
  chargeCategory: string;
  chargeName: string;
  chargeNameKey: string;
  invoicedTax: number;
  reference_1: number;
  reference_2: number;
  reference_3: number;
  remainingTax: number;
  tax: number;
  taxName: string;
  taxPercentage: number;
}

export interface ReturnPreviewObjectOrderLine {
  derivedFromOrderHeaderKey: string;
  derivedFromOrderLineKey: string;
  orderedQty: number;
  primeLineNo: string;
  returnReason: string;
  shipNode: string;
  item: {
    itemShortDesc: string;
    productClass: string;
    unitOfMeasure: string;
    itemID: string;
  };
  personInfoShipTo: ReturnPreviewObjectAddress;
  derivedFromOrder: {
    orderNo: string;
    orderHeaderKey: string;
  };
  lineOverallTotals: ReturnPreviewObjectLineTotals;
  lineCharges: Record<string, unknown>;
  lineTaxes: [ReturnPreviewObjectLineTax];
  orderLines: [];
  extn: {
    extnDerivedFromReleaseNo: string;
    extnReturnItemNo: string;
  };
  name: string;
}

export interface ReturnPreviewObjectOverallTotals {
  grandCharges: number;
  grandDiscount: number;
  grandShippingBaseCharge: number;
  grandShippingCharges: number;
  grandShippingDiscount: number;
  grandShippingTotal: number;
  grandTax: number;
  grandTotal: number;
  hdrCharges: number;
  hdrDiscount?: number;
  hdrTax: number;
  hdrTotal: number;
  lineSubTotal: number;
}

export interface CreateReturnObject {
  buyerUserId?: string;
  customerEMailID?: string;
  customerFirstName?: string;
  customerLastName?: string;
  customerPhoneNo?: string;
  documentType?: string;
  draftOrderFlag?: boolean;
  enterpriseCode?: string;
  entryType?: string;
  orderNo?: string;
  receivingNode?: string;
  extn?: {
    extnIsRefundOnGiftCard: boolean;
    extnOMSManagedOrder: boolean;
  };
  headerCharges?: ReturnPreviewObjectHeaderCharges;
  orderLines?: [ReturnPreviewObjectOrderLine];
  overallTotals?: ReturnPreviewObjectOverallTotals;
  personInfoBillTo?: ReturnPreviewObjectAddress;
  personInfoShipTo?: ReturnPreviewObjectAddress;
}

export interface CheckoutOrderPaymentMethod {
  code: string;
  label: string;
  additionalData?: {
    amount: number;
    card_type?: string;
    credit_card_expired?: boolean;
    expiration_month?: number;
    expiration_year?: number;
    masked_number?: string;
    number_last_digits?: string;
    payment_method_id?: string;
    payment_instrument_id?: string;
    gcCardNumber?: string;
    loyaltyPoints?: number;
    rewardCategory?: string;
    issueDateTime?: string;
  };
}
export interface CheckoutOrder {
  orderNumber: string;
  discounts?: {
    shippingDiscounts?: CartShippingDiscount[];
    otherDiscounts: number;
  };
  promoSummary: {
    promotionId: string;
    level: 'ORDER' | 'PRODUCT' | 'SHIPPING' | string;
    promotionName: string;
    calloutMessage: string;
    promoDetails: string;
    discount: number;
  }[];
  paymentMethod: CheckoutOrderPaymentMethod[];
  orderPromotions?: OrderPromotion[];
  billingAddress: Partial<Address>;
  totals: CartTotals;
  shipments: Shipment[];
  items: CheckoutOrderLineItem[];
  existingCustomer?: string;
  st: string;
  isFirstOrder?: boolean;
  coom_01: string;
  bounceBackVouchers?: ApproachingDiscount[];

  [key: string]: any;
}

export interface CheckoutOrderLineItem extends CartLineItem {
  shipmentId: string;
  fromStore: string;
  storeId: string;
}

export interface FaqItem {
  question: string;
  answer: string;
}

export interface PickupPerson {
  firstName: string;
  lastName: string;
  email: string;
}

export interface PickupStore {
  id: string;
  name: string;
}
