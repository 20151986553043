






import type { PropType } from 'vue';

export default {
  name: 'VfPopover',
  props: {
    visible: {
      type: Boolean,
    },
    position: {
      type: String as PropType<'top' | 'bottom' | 'left' | 'right'>,
      default: 'bottom',
    },
  },
};
