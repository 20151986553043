//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { computed, defineComponent, inject } from '@vue/composition-api';
import {
  getCacheKeyFromProps,
  createGradient,
} from '@vf/shared/src/utils/helpers';
export default defineComponent({
  name: 'VfFacet',
  serverCacheKey: getCacheKeyFromProps,
  props: {
    type: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    color: {
      type: Array,
      default: () => [],
    },
    count: {
      type: Number,
      default: 0,
    },
    ariaLabel: {
      type: String,
      default: '',
    },
    tiled: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    displayCount: {
      type: Boolean,
      default: true,
    },
    link: {
      type: [String, Object],
      default: '',
    },
    icon: {
      type: String,
      default: 'cross',
    },
    showIcon: {
      type: Boolean,
      default: true,
    },
    rel: {
      type: String,
      default: undefined,
    },
  },
  setup(props) {
    // TODO: Remove in GLOBAL15-62475
    const isFiltersUiUpdateEnabled = inject('isFiltersUiUpdateEnabled');
    // TODO: Cleanup in GLOBAL15-63799
    const isVansPlpRedesignEnabled = inject('isVansPlpRedesignEnabled');
    // TODO: GLOBAL15-61059 remove after redesign core
    const isCoreRedesignEnabled = inject('isCoreRedesignEnabled');
    const background = computed(() => {
      if (!props.color.length) return 'transparent';
      return createGradient(props.color);
    });
    return {
      background,
      isCoreRedesignEnabled,
      isFiltersUiUpdateEnabled,
      isVansPlpRedesignEnabled,
    };
  },
  methods: {
    select() {
      this.$emit('click-select');
    },
    remove() {
      this.$emit('click-remove');
    },
  },
});
