import { AxiosResponse } from 'axios';
import { LocatorRequest, LocatorResponse } from '../../api-types';
import { endpoints } from '../../config';
import { ApiClientConfiguration } from '../../configuration';
import { axios } from '../../helpers';

// TODO: remove the helper when GLOBAL15-51668 epic is done
export const locatorSearch = (configuration: ApiClientConfiguration) => async (
  body: LocatorRequest
): Promise<AxiosResponse<LocatorResponse>> => {
  configuration.logger.debug('Brandify Locator', {
    url: endpoints.get(configuration).brandify.locatorsearch,
  });
  return axios.post(endpoints.get(configuration).brandify.locatorsearch, body);
};
