import { ProductCtaButtonTranslations } from '@vf/api-contract';
import { getTranslation } from './extractProperty';

export const getProductCtaButtonTranslations = (
  data: any
): ProductCtaButtonTranslations => {
  return {
    buttonCTANotify: getTranslation(
      data,
      'buttonCTANotify',
      '$Notify me when available$'
    ),
    buttonCTADiscontinued: getTranslation(
      data,
      'buttonCTADiscontinued',
      '$Out of stock$'
    ),
    buttonCTAActive: getTranslation(data, 'buttonCTAActive', data.title),
    notifyBoxTitle: getTranslation(
      data,
      'notifyBoxTitle',
      '$Notify me when available$'
    ),
    notifyBoxTextBox: getTranslation(
      data,
      'notifyBoxTextBox',
      '$Please enter your email so we can alert you when the {{name}} in color {{color}}, size {{size}} is back in stock. We promise not to spam you. You will only be notified once.$'
    ),
    notificationMessage: getTranslation(
      data,
      'notificationMessage',
      '$You will be notified when this item is available$'
    ),
    notificationErrorMessage: getTranslation(
      data,
      'notificationErrorMessage',
      '$Subscription failed$'
    ),
    notificationAlreadySubscribedMessage: getTranslation(
      data,
      'notificationAlreadySubscribedMessage',
      '$The email provided has already been submitted for this product. A notification will be sent when the item is back in stock$'
    ),
    signInToBuy: getTranslation(data, 'signInToBuy', '$Sign in to buy$'),
    soldOutCta: getTranslation(data, 'soldOutCta', '$SOLD OUT$'),
    sizeLabel: getTranslation(data, 'sizeLabel', '$Size$'),
    notifyEmailTitle: getTranslation(
      data,
      'notifyEmailTitle',
      '$Enter Email Address$'
    ),
    notifyEmailPlaceholder: getTranslation(
      data,
      'notifyEmailPlaceholder',
      '$Enter Email Address$'
    ),
    validationMessages: getTranslation(data, 'validationMessages', {
      requiredError: '$This field is required.$',
      emailError: '$Please enter a valid email address.$',
    }),
    notifyButtonText: getTranslation(data, 'notifyButtonText', '$Notify me$'),
  };
};
