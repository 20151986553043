



































































































































































































import { AnalyticsDataDomLocation } from '@/types';

export default {
  name: 'MobileMenuColumnList',
  props: {
    navigation: {
      type: Array,
      default: () => [],
    },
    shopAll: {
      type: String,
      default: '',
    },
    handleMobileNavLinkClick: {
      type: Function,
      required: true,
    },
    handleActiveMegaMenuColumn: {
      type: Function,
      required: true,
    },
    getShopAllLabel: {
      type: Function,
      required: true,
    },
  },
  setup() {
    return {
      AnalyticsDataDomLocation,
    };
  },
};
