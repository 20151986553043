
import { PropType } from 'vue';
import { defineComponent } from '@vue/composition-api';
// Compatibility delta due to rounding issues
const delta = 2.5;
let debounceId;

interface Data {
  left: number;
  width: number;
  top: number;
  height: number;
  pages: number;
  scrollWidth: number;
  scrollHeight: number;
  hasPrev: boolean;
  hasNext: boolean;
  activePage: number;
  activeItem: number;
  isScrolling?: boolean;
  resizeObserver: null | ResizeObserver;
}

export default defineComponent({
  name: 'UiScroll',
  props: {
    gap: {
      type: String,
      default: '0px',
    },
    /**
     * Move window, indicates the percent of width to travel when nav is triggered.
     */
    displacement: {
      type: Number,
      default: 1,
    },
    /**
     * Frequency of checking the scroll position
     */
    debounce: {
      type: [String, Number],
      default: 200,
    },
    scrollBy: [String, Number],
    loop: Boolean,
    dir: String as PropType<'vertical'>,
    align: { type: String as PropType<'start' | 'center'>, default: 'start' },
    classContainer: String,
  },
  data(): Data {
    return {
      left: 0,
      width: 0,
      top: 0,
      height: 0,
      pages: 0,
      activePage: 0,
      scrollWidth: 0,
      scrollHeight: 0,
      activeItem: 0,
      hasPrev: false,
      hasNext: false,
      isScrolling: false,
      resizeObserver: null,
    };
  },
  computed: {
    container() {
      return this.$refs.container as Element;
    },
    children(): HTMLCollection {
      return this.container.children;
    },
    dirProps() {
      return {
        position: this.dir ? 'top' : 'left',
        endPosition: this.dir ? 'bottom' : 'right',
        scroll: this.dir ? 'scrollTop' : 'scrollLeft',
        scrollSize: this.dir ? 'scrollHeight' : 'scrollWidth',
        containerSize: this.dir ? 'clientHeight' : 'clientWidth',
        size: this.dir ? 'height' : 'width',
      };
    },
  },
  mounted() {
    this.onScrollDebounce(() => this.$emit('init', this));
    this.resizeObserver = new ResizeObserver(this.onResize);
    this.resizeObserver.observe(this.$refs.container);
  },
  beforeDestroy() {
    clearTimeout(debounceId);
    this.resizeObserver.unobserve(this.$refs.container);
  },
  methods: {
    onResize() {
      this.$emit('resize', this.$refs.container.offsetWidth);
    },
    findPrevSlot(x: number): Element | undefined {
      for (let i = 0; i < this.children.length; i++) {
        const rect = this.children[i].getBoundingClientRect();

        if (x <= rect[this.dirProps.position]) {
          return this.children[i];
        }
      }
    },
    findNextSlot(x: number): Element | undefined {
      for (let i = 0; i < this.children.length; i++) {
        const rect = this.children[i].getBoundingClientRect();

        if (x <= rect[this.dirProps.position]) {
          return this.children[i];
        }
      }
    },
    /**
     * Toggle and scroll to the previous set of horizontal content.
     */
    prev(): void {
      if (this.isScrolling) return;
      this.$emit('prev');
      if (this.scrollBy) {
        if (this.activeItem === 0) {
          if (this.loop) {
            this.activeItem = this.children.length - 1;

            if (this.scrollBy === 1 && this.numItemsInView() === 1) {
              const firstChild = this.children[0].cloneNode(true);
              this.container.appendChild(firstChild, null);
              this.scrollToIndex(this.children.length - 1, 'instant');
              this.scrollToIndex(this.children.length - 2, 'smooth', () => {
                this.children[this.children.length - 1].remove();
              });
              return;
            }
          }
        } else {
          const newIndex = this.activeItem - +this.scrollBy;
          this.activeItem = newIndex <= 0 ? 0 : newIndex;
        }
        this.scrollToIndex(this.activeItem);
      } else {
        if (!this[this.dirProps.position]) {
          this.scrollToLeft(this[this.dirProps.scrollSize]);
          return;
        }

        const left = this.container.getBoundingClientRect()[
          this.dirProps.position
        ];
        const x = this[this.dirProps.size] * -this.displacement;

        const el = this.findPrevSlot(x);

        if (el) {
          const width =
            el.getBoundingClientRect()[this.dirProps.position] - left;
          this.scrollToLeft(this.container[this.dirProps.scroll] + width);
          return;
        }

        const width =
          this.container[this.dirProps.containerSize] * this.displacement;
        this.scrollToLeft(this.container[this.dirProps.scroll] - width);
      }
    },
    /**
     * Toggle and scroll to the next set of horizontal content.
     */
    next(): void {
      if (this.isScrolling) return;
      this.$emit('next');

      if (this.scrollBy) {
        if (
          Math.ceil(this[this.dirProps.size] + this[this.dirProps.position]) +
            delta >=
            this[this.dirProps.scrollSize] &&
          this.loop
        ) {
          this.activeItem = 0;
          if (this.scrollBy === 1 && this.numItemsInView() === 1) {
            const lastChild = this.children[this.children.length - 1].cloneNode(
              true
            );
            this.container.insertBefore(lastChild, this.children[0]);
            this.scrollToIndex(0, 'instant');
            this.scrollToIndex(1, 'smooth', () => {
              this.children[0].remove();
              this.container.scrollLeft = 0;
            });
            return;
          }
        } else {
          const newIndex = this.activeItem + +this.scrollBy;
          this.activeItem =
            newIndex > this.children.length - 1
              ? this.children.length - 1
              : newIndex;
        }
        this.scrollToIndex(this.activeItem);
      } else {
        if (!this.hasNext) {
          this.scrollToIndex(0);
          return;
        }

        const left = this.container.getBoundingClientRect()[
          this.dirProps.position
        ];

        const x = this[this.dirProps.size] * this.displacement;
        const el = this.findNextSlot(x);
        if (el) {
          const width =
            el.getBoundingClientRect()[this.dirProps.position] - left;
          if (width > delta) {
            this.scrollToLeft(this.container[this.dirProps.scroll] + width);
            return;
          }
        }

        const width =
          this.container[this.dirProps.containerSize] * this.displacement;
        this.scrollToLeft(this.container[this.dirProps.scroll] + width);
      }
    },
    /**
     * Index of the slots to scroll to.
     * @param {number} i index
     * @param {'smooth' | 'instant' | 'auto} [behavior='smooth']
     * @param {() => void} [callback] callback function to run after scrolling finishes
     */
    scrollToIndex(
      i: number,
      behavior: 'smooth' | 'instant' | 'auto' = 'smooth',
      callback
    ): void {
      if (this.children[i]) {
        const rect = this.children[i].getBoundingClientRect();

        const left =
          rect[this.dirProps.position] -
          this.container.getBoundingClientRect()[this.dirProps.position];
        this.scrollToLeft(
          this.container[this.dirProps.scroll] + left,
          behavior,
          callback
        );
      }
    },
    /**
     * Amount of pixel to scroll to on the left.
     * @param {number} left of the horizontal
     * @param {'smooth' | 'instant' | 'auto} [behavior='smooth']
     * @param {() => void} [callback] callback function to run after scrolling finishes
     */
    scrollToLeft(
      left: number,
      behavior: 'smooth' | 'instant' | 'auto' = 'smooth',
      callback
    ) {
      if (typeof callback == 'function') {
        const onScrollEvent = () => {
          if (this.container[this.dirProps.scroll] === left) {
            this.container.removeEventListener('scroll', onScrollEvent);
            callback();
          }
        };

        this.container.addEventListener('scroll', onScrollEvent);
      }
      this.container.scrollTo({ [this.dirProps.position]: left, behavior });
    },
    paginate(i) {
      this.$emit('paginate');
      if (i === this.pages - 1) {
        // If last page, always scroll to last item
        this.scrollToIndex(this.children.length - 1);
        this.activePage = this.pages - 1;
      } else {
        this.activePage = i;
        this.scrollToLeft(
          i *
            (this.children[0].getBoundingClientRect()[this.dirProps.size] +
              parseInt(this.gap))
        );
      }
    },
    onScroll(): void {
      // Resolves https://github.com/fuxingloh/vue-horizontal/issues/99#issue-862691647
      if (!this.container) return;

      this.$emit('scroll', {
        [this.dirProps.position]: this.container[this.dirProps.scroll],
      });

      this.isScrolling = true;
      clearTimeout(debounceId);
      debounceId = setTimeout(this.onScrollDebounce, +this.debounce);
    },
    onScrollDebounce(cb?: () => void) {
      this.refresh((data) => {
        this.$emit('refresh', data);
        cb?.();
      });
    },
    /**
     * Manually refresh scroll
     * @param {(data: Data) => void} [callback] after refresh, optional
     */
    refresh(callback?: (data) => void): void {
      this.$nextTick(() => {
        const data = this.calculate();
        this.left = data.left;
        this.width = data.width;
        this.scrollWidth = data.scrollWidth;
        this.top = data.top;
        this.height = data.height;
        this.scrollHeight = data.scrollHeight;
        this.hasNext = data.hasNext;
        this.hasPrev = data.hasPrev;
        this.isScrolling = false;
        const gap = parseInt(this.gap);
        const itemSize = this.children[0]
          ? Math.floor(
              this.children[0].getBoundingClientRect()[this.dirProps.size]
            )
          : 0;
        const itemSizeWithGap = itemSize + gap;

        let itemsVisibleInOneView = Math.floor(
          data[this.dirProps.size] / itemSizeWithGap
        );
        // check if without gap is possible to see next item
        if (
          itemsVisibleInOneView * itemSizeWithGap + itemSize <=
          data[this.dirProps.size]
        ) {
          itemsVisibleInOneView++;
        }
        this.pages =
          Math.floor((data[this.dirProps.scrollSize] + gap) / itemSizeWithGap) -
          itemsVisibleInOneView +
          1;
        if (!this.hasPrev) {
          this.activePage = 0;
        } else if (!this.hasNext) {
          this.activePage = this.pages - 1;
        } else {
          const offset =
            this.align === 'center'
              ? Math.floor(data[this.dirProps.size]) / 2
              : 0;
          this.activePage = Math.floor(
            (data[this.dirProps.position] + offset) / itemSizeWithGap
          );
        }
        this.activeItem = this.activePage;

        callback?.(data);
      });
    },
    calculate() {
      const hasNext = (): boolean => {
        return (
          this.container[this.dirProps.scrollSize] >
          this.container[this.dirProps.scroll] +
            this.container[this.dirProps.containerSize] +
            delta
        );
      };

      const hasPrev = (): boolean => {
        if (this.container[this.dirProps.scroll] === 0) return false;

        const containerVWLeft = this.container.getBoundingClientRect()[
          this.dirProps.position
        ];
        const firstChildLeft =
          this.children[0]?.getBoundingClientRect()?.[this.dirProps.position] ??
          0;
        return Math.abs(containerVWLeft - firstChildLeft) >= delta;
      };
      return {
        left: this.container.scrollLeft,
        width: this.container.clientWidth,
        top: this.container.scrollTop,
        height: this.container.clientHeight,
        scrollWidth: this.container.scrollWidth,
        scrollHeight: this.container.scrollHeight,
        activePage: this.activePage,
        activeItem: this.activeItem,
        pages: this.pages,
        hasNext: hasNext(),
        hasPrev: hasPrev(),
      };
    },
    numItemsInView() {
      return (
        this[this.dirProps.size] /
        (this.children[0].getBoundingClientRect()[this.dirProps.size] +
          parseInt(this.gap))
      );
    },
  },
  render(createElement) {
    return createElement('div', { class: 'ui-scroll' }, [
      this.$scopedSlots.prev &&
        this.$scopedSlots.prev({
          active: this.hasPrev || this.loop,
          action: this.prev,
        }),
      createElement(
        'ul',
        {
          ref: 'container',
          class: [
            'ui-scroll-container',
            this.dir && `dir-${this.dir}`,
            `align-${this.align}`,
            this.classContainer,
          ],
          style: { gap: this.gap },
          on: {
            '&scroll': this.onScroll,
          },
        },
        [
          this.$scopedSlots.default &&
            this.$scopedSlots.default({
              active: this.activeItem,
            }),
        ]
      ),
      this.$scopedSlots.next &&
        this.$scopedSlots.next({
          active: this.hasNext || this.loop,
          action: this.next,
        }),
      this.$scopedSlots.pagination &&
        this.$scopedSlots.pagination({
          active: this.activePage,
          total: this.pages,
          action: this.paginate,
        }),
    ]);
  },
});
