import {
  getTranslation,
  extractProperty,
  getComponentConfigByName,
} from './utils/extractProperty';
import {
  CMPlaceholder,
  ProductFindInStoreTranslations,
} from '@vf/api-contract';

interface ProductFindInStoreMapping {
  component: string;
  attrs?: {
    'data-id': string;
  };
  props: {
    translations: ProductFindInStoreTranslations;
    unitOfMeasure: string;
  };
}

export const productFindInStore = (
  data: CMPlaceholder
): ProductFindInStoreMapping => {
  const config = getComponentConfigByName(
    data,
    'pdp-find-in-store-settings',
    {}
  );
  return {
    component: 'pdp/ProductFindInStore',
    props: {
      translations: {
        heading: getTranslation(data, 'heading', '$In store avaliability$'),
        subtitle: getTranslation(
          data,
          'subtitle',
          '$Please enter your “City, State” or “Zip Code” to find stores.$'
        ),
        or: getTranslation(data, 'or', '$or$'),
        of: getTranslation(data, 'of', '$of$'),
        miles: getTranslation(data, 'miles', '$miles$'),
        km: getTranslation(data, 'km', '$km$'),
        locationButton: getTranslation(
          data,
          'locationButton',
          '$Use my location$'
        ),
        findButton: getTranslation(data, 'findButton', '$Find store$'),
        postalCodeLabel: getTranslation(
          data,
          'postalCodeLabel',
          '$City, State or Zip Code$'
        ),
        errorMessage: getTranslation(
          data,
          'errorMessage',
          '$There are no retail stores within your search radius. Please expand your radius or use another zip code.$'
        ),
        requiredError: getTranslation(
          data,
          'requiredError',
          '$This field is required$'
        ),
        direction: getTranslation(data, 'direction', '$Get Directions$'),
        hours: getTranslation(data, 'hours', '$Store hours$'),
        week: getTranslation(data, 'week', '$Monday - Friday$'),
        headerGIAS: getTranslation(data, 'headerGIAS', '$Get it at the store$'),
        descriptionGIAS: getTranslation(
          data,
          'descriptionGIAS',
          '$We try our best to provide the most accurate item availability information, but please remember item quantities are always changing.$'
        ),
        outOfStock: getTranslation(data, 'outOfStock', '$Out of stock$'),
        availableInStore: getTranslation(
          data,
          'availableInStore',
          '$Available in store$'
        ),
        inStock: getTranslation(data, 'inStock', '$In-Stock$'),
        lowInventory: getTranslation(data, 'lowInventory', '$Low inventory$'),
        showOnlyWithStock: getTranslation(
          data,
          'showOnlyWithStock',
          '$Only show stores with availability$'
        ),
        resultsOf: getTranslation(data, 'resultsOf', '$results of$'),
        storeName: getTranslation(data, 'storeName', '$Store name$'),
        size: getTranslation(data, 'size', '$Size$'),
        color: getTranslation(data, 'color', '$Color$'),
        stock: getTranslation(data, 'stock', '$stock$'),
        viewMore: getTranslation(data, 'viewMore', '$View More$'),
        makeYourStore: getTranslation(
          data,
          'makeYourStore',
          '$Make your store$'
        ),
        removeFromYourStore: getTranslation(
          data,
          'removeFromYourStore',
          '$Remove from your store$'
        ),
        yourStore: getTranslation(data, 'yourStore', '$Your Store$'),
        stsAvailableLabel: getTranslation(
          data,
          'stsAvailableLabel',
          '$Ship to store is available$'
        ),
        selectAriaLabel: getTranslation(
          data,
          'selectAriaLabel',
          '$Choose a distance$'
        ),
        monday: getTranslation(data, 'monday', '$Monday$'),
        tuesday: getTranslation(data, 'tuesday', '$Tuesday$'),
        wednesday: getTranslation(data, 'wednesday', '$Wednesday$'),
        thursday: getTranslation(data, 'thursday', '$Thursday$'),
        friday: getTranslation(data, 'friday', '$Friday$'),
        saturday: getTranslation(data, 'saturday', '$Saturday$'),
        sunday: getTranslation(data, 'sunday', '$Sunday$'),
      },
      unitOfMeasure: extractProperty(config, 'unit-of-measure', 'km'),
    },
  };
};
