import {
  CMPlaceholder,
  CmsSite,
  SocialNativeUploadMediaTranslations,
} from '@vf/api-contract';
import { ComposableContext } from '../types';
import {
  extractLocalSettingsExpandedRichText,
  decorateHtmlValue,
  getDynamicField,
  getTranslation,
} from './utils';

export const socialNativeUploadMedia = (
  data: CMPlaceholder,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  const agreementLabel = extractLocalSettingsExpandedRichText(
    data,
    'agreementLabel',
    ''
  );

  const translations: SocialNativeUploadMediaTranslations = {
    // sign in form translations
    title: getTranslation(data, 'title', '$Add your photo$'),
    subCopy:
      extractLocalSettingsExpandedRichText(data, 'requiredFields', undefined)
        ?.html || '$Please fill out the following information.$',
    displayName: getTranslation(data, 'displayName', '$Display Name$'),
    email: getTranslation(data, 'email', '$Email$'),
    agreementLabel: agreementLabel.html
      ? decorateHtmlValue(
          agreementLabel,
          cmsBaseUri,
          context,
          siteConfiguration
        )
      : getDynamicField(data, 'agreementLabel') ||
        '$I agree to the Privacy Policy & Terms and Conditions$',
    emailErrorMessage: getTranslation(
      data,
      'emailErrorMessage',
      '$Please enter a valid email address.$'
    ),
    termsError: getTranslation(
      data,
      'termsError',
      '$You must accept the Privacy Policy & Terms and Conditions.$'
    ),
    requiredErrorMessage: getTranslation(
      data,
      'requiredErrorMessage',
      '$This field is required.$'
    ),
    createUserError: getTranslation(
      data,
      'createUserError',
      '$Social Native create user failed!$'
    ),
    errorImageOutOfSize: getTranslation(
      data,
      'errorImageOutOfSize',
      '$sizes Image exceeded the limits!$'
    ),
    errorImageWrongExtension: getTranslation(
      data,
      'errorImageWrongExtension',
      '$extensions Image not available!$'
    ),
    next: getTranslation(data, 'next', '$Next$'),

    // upload media translations
    uploader: getTranslation(data, 'uploader', '$Drop files here to upload$'),
    uploaderMobileTitle: getTranslation(
      data,
      'uploaderMobileTitle',
      '$Select from Camera Roll$'
    ),
    uploadMediaMobile:
      extractLocalSettingsExpandedRichText(data, 'uploadMediaMobile', undefined)
        ?.html || '$Tap here to select content from your camera roll$',
    submitPhoto: getTranslation(data, 'submitPhoto', '$Submit Photo$'),
    addCaptionTitle: getTranslation(
      data,
      'addCaptionTitle',
      '$Add caption to your photo$'
    ),
    addCaptionCopy: getTranslation(
      data,
      'addCaptionCopy',
      '$Adding the product name, URL or location helps get your content featured!$'
    ),
    addCaption: getTranslation(data, 'addCaption', '$Add caption$'),
    remove: getTranslation(data, 'remove', '$Remove$'),
    submit: getTranslation(data, 'submit', '$Submit$'),
    successMessage:
      extractLocalSettingsExpandedRichText(data, 'successMessage', undefined)
        ?.html ||
      '$Thanks! Your photo has been submitted for approval. Check back soon to see it live!$',
  };
  return {
    component: 'shared/SocialNativeUploadMedia',
    props: {
      apiKey: context.instance.$env.SOCIAL_NATIVE_API_MASTER_KEY,
      contextKey: context.contextKey,
      translations,
    },
  };
};
