import { ComponentInstance } from '../../types';
import { Product } from '@vf/api-client';

export const isValidProductData = (
  instance: ComponentInstance,
  product: Product
): boolean => {
  // this scenario handles when API response is erroed.
  if (Object.keys(product).length === 1) {
    instance.$log.warn(
      `[@useCms/utils/productDataValidator::isValidProductData] Product <${product.id}> was not retrieved from MuleSoft.`,
      { product }
    );
    return false;
  }
  if (!product.variants || !product.variants.length) {
    instance.$log.error(
      `[@useCms/utils/productDataValidator::isValidProductData] Product <${product.id}> has no variants configured`,
      { product }
    );
    return false;
  }
  return true;
};
