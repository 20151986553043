import { CMTeaser } from '@vf/api-contract';
import {
  extractProperty,
  extractCommonConfigLink,
  getTranslation,
  getDynamicField,
  getTitle,
  extractLocalSettingsExpandedLinks,
  generateLinkFromTeaserTargets,
  getCreateAccountTranslations,
  getValidationConfigValues,
} from './utils';
import { MappingMethod } from '../types';

export const checkoutLogin: MappingMethod = (
  data: CMTeaser,
  context,
  siteConfiguration,
  cmsBaseUri
) => {
  const signInData = extractProperty(
    extractCommonConfigLink(siteConfiguration, 'sign-in'),
    '[0]'
  );

  const checkoutCmsLoginModal = extractLocalSettingsExpandedLinks(
    data,
    'loyaltyRegisterLogin'
  )[0];

  const checkoutCmsLoginModalLink = checkoutCmsLoginModal?.id || null;
  const createAccountLink = extractLocalSettingsExpandedLinks(
    data,
    'createAccountLink'
  );
  const createAccountData = extractProperty(createAccountLink, '[0]', {});

  const interestsLink = createAccountData
    ? generateLinkFromTeaserTargets(
        createAccountData.teaserTargets,
        context,
        siteConfiguration
      )
    : '';

  return {
    component: 'checkout/CheckoutLogin',
    props: {
      checkoutCmsLoginModal: checkoutCmsLoginModalLink,
      validations: getValidationConfigValues(createAccountData),
      showPasswordToggler: getDynamicField(data, 'showPasswordToggler', false),
      showCaptcha: getDynamicField(data, 'showCaptcha', false),
      showSmsSubscription: getDynamicField(
        createAccountData,
        'smsSubscriptionEnabled',
        false
      ),
      translations: {
        continueAsAGuest: getTranslation(
          data,
          'continueAsAGuest',
          '$Continue as a guest or$'
        ),
        logIn: getTranslation(data, 'logIn', '$Log in to account$'),
        cartMergedNotification: getTranslation(
          data,
          'cartMergedNotification',
          '$Items have been added from your last visit$'
        ),
      },
      signInModalTranslations: {
        title: getTranslation(signInData, 'title', '$Sign in$'),
        emailLabel: getTranslation(signInData, 'emailLabel', '$Email Address$'),
        emailPlaceholder: getTranslation(signInData, 'placeholder', '$Email$'),
        passwordLabel: getTranslation(
          signInData,
          'passwordLabel',
          '$Password$'
        ),
        validationMessages: {
          email: getTranslation(
            signInData,
            'emailErrorMessage',
            '$Please enter a valid email address.$'
          ),
          required: getTranslation(
            signInData,
            'requiredErrorMessage',
            '$This field is required$'
          ),
        },
        forgotPasswordTitle: getTranslation(
          signInData,
          'forgotPasswordTitle',
          '$Forgot Password$'
        ),
        forgotPasswordButtonText: getTranslation(
          signInData,
          'forgotPasswordButtonText',
          '$Forgot password?$'
        ),
        signInButtonText: getTranslation(
          signInData,
          'signInButtonText',
          '$Sign in$'
        ),
        createAccountButtonLabel: getTranslation(
          signInData,
          'createAccountButtonLabel',
          '$Not a member?$'
        ),
        createAccountButtonText: getTranslation(
          signInData,
          'createAccountButtonText',
          '$Join Now$'
        ),
        bottomHeading: getTranslation(
          signInData,
          'subheading',
          '$Bottom Heading$'
        ),
        facebookAriaLabel: getTranslation(
          signInData,
          'facebookAriaLabel',
          '$Facebook aria label$'
        ),
        resetPasswordButton: getTranslation(
          signInData,
          'resetPasswordButton',
          '$Reset Password$'
        ),
        cancelButton: getTranslation(signInData, 'cancelButton', '$Cancel$'),
        errorReset: getTranslation(
          signInData,
          'errorReset',
          '$Cannot reset the password.$'
        ),
        successReset: getTranslation(
          signInData,
          'successReset',
          '$An email will be sent to you shortly if an account exists for this email address.$'
        ),
        loginError: getTranslation(
          signInData,
          'loginError',
          '$Either the login id or password entered is incorrect. Enter the information again.$'
        ),

        createAccountTranslations: {
          heading: getTranslation(
            createAccountData,
            'greeting',
            getTitle(createAccountData)
          ),
          subheading: getTranslation(
            createAccountData,
            'subtext',
            getTitle(createAccountData)
          ),
          ...getCreateAccountTranslations(
            createAccountData,
            context,
            siteConfiguration,
            cmsBaseUri
          ),
        },
      },
      interestsLink: interestsLink,
      loginRedirectToCart: getDynamicField(
        data,
        'login-redirect-to-cart',
        false
      ),
      showCreateAccountLink: getDynamicField(
        data,
        'showCreateAccountLink',
        false
      ),
    },
  };
};
