import merge from 'lodash/merge';
import { ApiClientConfiguration } from '../configuration';

const defaultEndpoints = {
  authentication: {
    signIn: '/consumers/v1/auth/signin',
    signInGuest: '/consumers/v1/auth/token',
    confirmForgotPassword: '/consumers/v1/confirmforgotpassword',
    signUp: '/consumers/v1/auth/signup',
    signOut: '/consumers/v1/auth/signout',
    refreshToken: '/consumers/v1/auth/token',
    getUserToken: '/consumers/v1/auth/token',
    emailLookup: '/consumers-v3/consumer/lookup',
  },
  customer: {
    getAffiliateCookies: '/consumers-v3/affiliate',
    getBasicInformation: '/consumers-v3/consumer',
    setBasicInformation: '/consumers-v3/consumer',
    forgotPassword: '/consumers-v3/consumer/resetpassword',
    changePassword: '/consumers-v3/consumer/changepassword',
    getOrderHistory: '/consumers-v3/consumer/purchasehistory',
    getReturnHistory: '/consumers-v3/consumer/returnhistory',
    getOrderDetails: '/consumers-v3/consumer/purchase/{orderId}',
    trackOrder: '/consumers-v3/consumer/TrackOrder',
    addAddress: '/consumers-v3/consumer/address',
    deleteAddress: '/consumers-v3/consumer/address/{addressId}',
    updateAddress: '/consumers-v3/consumer/address/{addressId}',
    updateProfile: '/consumers-v3/consumer',
    updateAcceptance: '/consumers-v3/consumer',
    getPaymentInstruments: '/consumers-v3/consumer/payment_instruments',
    addPaymentInstrument: '/consumers-v3/consumer/payment_instruments',
    deletePaymentInstrument:
      '/consumers-v3/consumer/payment_instruments/{instrumentId}',
    setDefaultPaymentInstrument:
      '/consumers-v3/consumer/payment_instruments/{instrumentId}',
    loyaltyToken: '/consumers-v3/consumer/loyalty/token',
    loyaltyWelcomePromoStatus: '/consumers-v3/consumer/loyalty/promo/welcome',
    loyaltyVouchers: '/consumers-v3/consumer/loyalty/vouchers',
    createReturn: '/consumers-v3/consumer/purchase/{orderHeaderKey}/return',
    printReturnLabel: '/consumers-v3/consumer/return/{orderNo}/returnLabel',
    getReturnDetails: '/consumers-v3/consumer/profile/return/{orderHeaderKey}',
    setSubscription: '/consumers-v3/consumer/subscription',
    setSubscriptionGuest: '/consumers-v3/consumer/subscription',
    getSegments: '/consumers-v3/consumer/segment',
    getBuyInStoreLocations: '/consumers-v3/utilities/stores',
    getBuyInStoreEmployees:
      '/consumers-v3/utilities/stores/{storeId}/employees',
  },
  wishList: {
    getFavorites: '/wishlists/v2/favorites',
    addToFavorites: '/wishlists/v2/favorites',
    removeFromFavorites: '/wishlists/v2/favorites/{favoriteId}/items/{itemId}',
    shareFavorites: '/wishlists/v2/favorites/{favoriteId}/share',
    getSharedFavorites: '/wishlists/v2/favorites/{favoriteId}/share',
    getSaveForLater: '/wishlists/v2/saveForLater',
  },
  catalog: {
    getCatalog: '/products/v1/catalog',
  },
  product: {
    getProductDetails: '/products/v1/products/{slug}/details',
    getProductInventory: '/products/v2/products/{productId}/inventory',
    getAvailability: '/products/v1/products/{productId}/availability',
    getProductReviews: '/products/v1/products/{productId}/reviews',
    getProductReviewsConf: '/products/v1/products/reviews/configuration',
    flagReview: '/products/v1/products/{productId}/review/{reviewId}/flag',
    addReviewVote: '/products/v1/products/{productId}/review/{reviewId}/vote',
    notifyMe: '/products/v1/notifications',
    widgetRecommendations: '/products/v1/widgetRecommendations',
  },
  search: {
    getSearchResults: '/products/v1/search/productSearch',
    getSearchSuggestions: '/products/v1/search/autoSuggest',
  },
  cart: {
    getCart: '/cart/v1/cart',
    createCartAddItem: '/cart/v1/cartWithItem',
    addItem: '/cart/v1/item',
    getCartObject: '/cart/v1/cart/{id}',
    swapItem: '/cart/v1/cart/{id}/swapItem',
    deleteItem: '/cart/v1/item',
    setShippingMethod: '/cart/v1/shippingMethod',
    updateItem: '/cart/v1/cart/{id}/item',
    addCoupon: '/cart/v1/coupon',
    deleteCoupon: '/cart/v1/coupon',
    deviceFingerPrint: '/cart/v1/deviceFingerPrint',
    paymentInstruments: '/cart/v1/cart/{id}/paymentInstruments',
    updatePaymentInstrument:
      '/cart/v1/cart/{basketId}/paymentInstruments/{instrumentId}',
    deletePaymentInstruments: '/cart/v1/cart/{id}/paymentInstruments',
    checkGiftCardBalance: '/cart/v1/giftcard/balance',
    share: '/cart/v1/cart/share',
    applyShared: '/cart/v1/cart/applyShared',
    saveForLater: '/cart/v1/cart/{id}/saveForLater',
    updateSaveForLater: '/cart/v1/saveForLater',
    removeSaveForLater:
      '/cart/v1/saveForLater/{saveForLaterId}/{consumerId}/items/{itemId}',
    prepareOrder: '/cart/v1/cart/prepareOrder',
    priceAdjustments: '/cart/v1/cart/{id}/price_adjustments',
    deletePriceAdjustments:
      '/cart/v1/cart/{id}/price_adjustments/{priceAdjustmentId}',
    updatePriceAdjustments:
      '/cart/v1/cart/{id}/price_adjustments/{priceAdjustmentId}',
    getPaymentSession: '/cart/v1/paymentSession',
    getSmartGiftCart: '/cart/v1/cart/smartGiftBasket',
  },
  checkout: {
    getShippingMethods: '/cart/v1/cart/{cartId}/shippingMethod/{shippingId}',
    setShippingAddress: '/cart/v1/cart/{basketId}/shippingAddress',
    setBillingAddress: '/cart/v1/cart/{basketId}/billingAddress',
    setFamilyReward: '/cart/v1/cart/{basketId}/reward',
    getPaymentMethods: '/cart/v1/cart/{basketId}/paymentMethods',
    placeOrder: '/cart/v1/checkout/order',
    patchOrder: '/cart/v2/checkout/order/{orderNumber}',
    addressValidation: '/cart/v1/cart/{cartId}/shippingAddress/validation',
  },
  utilities: {
    countryList: '/consumers/v1/utilities/countryList',
    provinceList: '/consumers/v1/utilities/provinceList/{countryCode}',
    i18n: '/consumers/v1/utilities/i18n/{countryCode}',
  },
  agent: {
    employeeSignIn: '/consumers/v2/consumer/agent/login',
  },
  images: {
    gallery: {
      scene7:
        '{hostname}/is/image/{S7ImageCompany}/{productId}{S7MediaType}?req=set,json,UTF-8&labelkey=label&handler={callbackFnName}',
      customs3d:
        'https://www.vans.com/prod/api/v1/productImages/byRecipeId/{recipeId}?images=%5B%7B%22size%22%3A%22lg-square-white%22%2C%22view%22%3A%22left_square%22%7D%2C%7B%22size%22%3A%22lg-square-white%22%2C%22view%22%3A%22top_square%22%7D%2C%7B%22size%22%3A%22lg-square-white%22%2C%22view%22%3A%22right_square%22%7D%2C%7B%22size%22%3A%22lg-square-white%22%2C%22view%22%3A%22front%22%7D%2C%7B%22size%22%3A%22lg-square-white%22%2C%22view%22%3A%22back%22%7D%2C%7B%22size%22%3A%22sm-square-white%22%2C%22view%22%3A%22left_square%22%7D%2C%7B%22size%22%3A%22sm-square-white%22%2C%22view%22%3A%22top_square%22%7D%2C%7B%22size%22%3A%22sm-square-white%22%2C%22view%22%3A%22right_square%22%7D%2C%7B%22size%22%3A%22sm-square-white%22%2C%22view%22%3A%22front%22%7D%2C%7B%22size%22%3A%22sm-square-white%22%2C%22view%22%3A%22back%22%7D%5D',
    },
  },
  // TODO: remove when GLOBAL15-51668 epic is done
  brandify: {
    locatorsearch: '/fapi/brandify/locatorsearch',
    getlist: '/fapi/brandify/getCountriesList',
  },
  stores: {
    getCities: '/stores/v1/list?object=cities',
    getCountries: '/stores/v1/list?object=countries',
    getStates: '/stores/v1/list?object=states',
    getStores: '/stores/v1/list?object=stores',
    search: '/stores/v1/search',
  },
  contactForm: {
    getCategorySubject:
      '/consumers-v3/contactus/categorySubject?locale={locale}',
    submitContactForm: '/fapi/contactForm',
    submitContactFormSfcc: '/consumers-v3/contactus',
  },
  healthCheck: '/fapi/_hz',
  pdfPrinter: {
    printPackingSlip: '/fapi/pdfPrinter/printPackingSlip',
  },
  featureFlags: {
    allFlagsState: '/fapi/featureFlags/allFlagsState',
  },
  socialNative: {
    getAuth:
      'https://photorankapi-a.akamaihd.net?version=v2.2&auth_token={api_key}',
    getStreamByKey:
      'https://photorankapi-a.akamaihd.net/customers/{customer_id}/streams/search?version=v2.2&auth_token={api_key}&tag_key={tag_key}',
    getStreamByMedia:
      'https://photorankapi-a.akamaihd.net/media/{media_id}/streams?version=v2.2&auth_token={api_key}',
    getMediaByCustomer:
      'https://photorankapi-a.akamaihd.net/customers/{customer_id}/media/recent?version=v2.2&auth_token={api_key}&count={count}&page_number={page_number}',
    getMediaByStream:
      'https://photorankapi-a.akamaihd.net/streams/{stream_id}/media/recent?version=v2.2&auth_token={api_key}&count={count}&page_number={page_number}',
    getMediaByTagKey:
      'https://photorankapi-a.akamaihd.net/streams/media/recent?version=v2.2&auth_token={api_key}&count={count}&tag_key={tag_key}&page_number={page_number}',
    getMediaByCategoryId:
      'https://photorankapi-a.akamaihd.net/categories/media/recent?version=v2.2&tag_key={tag_key}&auth_token={api_key}&count={count}&page_number={page_number}',
    createUser:
      'https://photorankapi-a.akamaihd.net/users?version=v2.2&auth_token={api_key}',
    addMedia:
      'https://photorankapi-a.akamaihd.net/users/{user_id}/media?version=v2.2&auth_token={api_key}',
    analytics: {
      trackWidgetEvent: {
        best: '/widget/{instanceId}/best/{action}.{format}',
        category:
          '/widget/{instanceId}/category/{categoryId}/{action}.{format}',
        stream: '/widget/{instanceId}/stream/{streamId}/{action}.{format}',
      },
      trackMediaEvent: {
        best: '/widget/{instanceId}/best/media/{mediaId}/{action}.{format}',
        category:
          '/widget/{instanceId}/category/{categoryId}/media/{mediaId}/{action}.{format}',
        stream:
          '/widget/{instanceId}/stream/{streamId}/media/{mediaId}/{action}.{format}',
      },
      trackProductShopEvent: {
        best: '/widget/{instanceId}/best/media/{mediaId}/shop.{format}',
        category:
          '/widget/{instanceId}/category/{categoryId}/media/{mediaId}/shop.{format}',
        stream:
          '/widget/{instanceId}/stream/{streamId}/media/{mediaId}/shop.{format}',
      },
      trackCheckoutEvent: '/transaction/{transactionId}/checkout.{format}',
    },
  },
};

const endpoints = {
  get(configuration: ApiClientConfiguration): typeof defaultEndpoints {
    return merge(defaultEndpoints, configuration.endpoints);
  },
};
export default endpoints;
