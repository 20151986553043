import { defineStore } from 'pinia';
import { computed, ref } from '@vue/composition-api';
import { FlashError } from '@vf/api-client/src/types';
import { Cart, CartLineItem, FlashErrorDetails } from '@vf/api-client';
import {
  CartCustomerNotificationType,
  CustomerNotificationType,
  CustomFlashType,
  FlashErrorType,
} from '@vf/api-contract';
import { findFlashIndex } from './helpers/findFlashIndex';

const FlashErrorCodesToDisplay = [
  FlashErrorType.Invalid,
  FlashErrorType.NotAvailable,
  FlashErrorType.InventoryMissing,
  FlashErrorType.ProductItemMaxQuantity,
  FlashErrorType.FullInventoryMissing,
  FlashErrorType.NoLongerAvailable,
  FlashErrorType.ProductStyleMaxQuantity,
  FlashErrorType.ProductLineItemInventoryMissing,
  FlashErrorType.BasketNotFoundException,
  CustomerNotificationType.PickupToSthTransition,
  CustomerNotificationType.PickupToStsTransition,
  CustomerNotificationType.STHToPickupTransition,
  CustomerNotificationType.StsToPickupTransition,
  CustomerNotificationType.StsToSthTransition,
  CartCustomerNotificationType.OrderShippingMethodDowngraded,
  CustomFlashType.ShippingMethodChanged,
];

export const useCartNotificationsStore = defineStore('notifications', () => {
  const cartItemsCache = ref<CartLineItem[]>([]);
  const flashes = ref<FlashError[]>([]);

  const clearFlashes = () => {
    flashes.value = [];
  };

  const clearFlashesWithProductIds = (productIds: string[]) => {
    flashes.value = flashes.value.filter(
      ({ details }) => !productIds.includes(details?.productId)
    );
  };

  const appendFlashes = (items: FlashError[]) => {
    if (!items) return;
    items.forEach((item) => {
      const index = findFlashIndex(flashes.value, item);
      if (index !== -1) return;
      flashes.value.push(item);
    });
  };

  const appendCartNotFoundFlash = (errorDetails) => {
    const additionalInfos = errorDetails
      .flatMap((error) => error.additionalInfo)
      .filter((item) => !!item);
    const items = additionalInfos.map((item) => ({
      code: item.type,
      message: item.message,
    }));
    appendFlashes(items);
  };

  const appendINV408Flash = (errorDetails) => {
    const additionalInfos = errorDetails
      .flatMap((error) => error.additionalInfo)
      .filter((item) => !!item);
    const items = additionalInfos.map((item) => {
      return {
        code: FlashErrorType.ProductStyleMaxQuantity,
        message: item.argument.statusMessage,
        details: item.argument.statusDetails.additionalDetails,
      };
    });
    appendFlashes(items);
  };

  const clearINV408Flashes = () => {
    flashes.value = flashes.value.filter(
      ({ code }) => code !== FlashErrorType.ProductStyleMaxQuantity
    );
  };

  const appendCustomFlash = (code) => {
    appendFlashes([
      {
        code,
        path: 'CartLevel',
      },
    ]);
  };

  const mapCustomerNotificationsToFlashes = (notifications = []) => {
    return notifications.map((notification) => ({
      code: notification.type,
      path: notification.path || 'CartLevel',
      details: notification.details,
    }));
  };

  const appendCartMessages = (data: Cart) => {
    appendFlashes(data.flash);
    appendFlashes(
      mapCustomerNotificationsToFlashes(data.customerNotifications || [])
    );
    appendFlashes(
      mapCustomerNotificationsToFlashes(
        (data.items || []).flatMap(
          ({ customerNotifications }) => customerNotifications || []
        )
      )
    );
  };

  const customerFacingFlashes = computed(() =>
    flashes.value.filter((flash) =>
      FlashErrorCodesToDisplay.includes(flash.code)
    )
  );

  const outOfStockFlashes = computed(() =>
    customerFacingFlashes.value.filter((error) =>
      [
        error.code === FlashErrorType.NotAvailable,
        error.code === FlashErrorType.NoLongerAvailable,
        [
          error.code === FlashErrorType.FullInventoryMissing,
          error.details?.availableQty === 0,
          error.details?.missingQty >= 1,
        ].every(Boolean),
      ].some(Boolean)
    )
  );

  const mapDetailsToProduct = (
    details: FlashErrorDetails,
    product: CartLineItem
  ) => {
    const canMergeProduct =
      product &&
      details &&
      (details.productId || details.sku) === product.productId;
    return {
      ...details,
      ...(canMergeProduct && product),
      name: details.productName,
      pdpUrl: details.productURL,
      qty: details.requiredQty,
      maxAllowedQty: details.availableQty,
      maxQty: details.availableQty,
      price: {
        currency: '',
        original: details.basePrice,
        current: details.salesPrice || details.basePrice,
        priceAfterItemDiscount: details.salesPrice || details.basePrice,
      },
      variants: Object.entries(details.variationAttributes).map((value) => {
        return {
          label: value[0],
          value: value[1],
        };
      }),
    };
  };

  const getCachedProductByPath = (path: string) => {
    let index = -1;
    const matches = path.match(/\[(\d+)\]/);
    if (matches && matches[1]) index = parseInt(matches[1], 10);
    if (index === -1 || index >= cartItemsCache.value.length) return null;
    return cartItemsCache.value[index];
  };

  const outOfStockProducts = computed(() => {
    return outOfStockFlashes.value.map((flash) => {
      const product = getCachedProductByPath(flash.path);
      if (flash.details) return mapDetailsToProduct(flash.details, product);
      if (flash.path) return product;
    });
  });

  const setCartItemsCache = (items: CartLineItem[]) => {
    cartItemsCache.value = items ?? [];
  };

  return {
    customerFacingFlashes,
    outOfStockFlashes,
    outOfStockProducts,
    clearFlashes,
    clearFlashesWithProductIds,
    appendCartMessages,
    appendCartNotFoundFlash,
    appendINV408Flash,
    appendCustomFlash,
    setCartItemsCache,
    clearINV408Flashes,
  };
});
