import {
  extractLocalSettingsExpandedLinks,
  extractProperty,
  getImageObject,
  getDynamicField,
  getTranslation,
  getComponentConfigByName,
} from './utils';
import { CMPlaceholder, CmsSite, SelectorVariant } from '@vf/api-contract';
import { ComposableContext } from '../types';

export const pdpSizeSelector = (
  data: CMPlaceholder,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  const otherConfigs = getComponentConfigByName(data, 'other', {
    'show-divider-line': false,
  });
  return {
    component: 'pdp/ProductSizes',
    props: {
      showNumeration: getDynamicField(data, 'showNumeration', true),
      displayAs: getDynamicField(data, 'display-as', SelectorVariant.Dropdown),
      preselectDefaultSize: getDynamicField(data, 'preselected-size', false),
      hideComponent: getDynamicField(data, 'hide-component', false),
      showSelectedVariationTitle: getDynamicField(
        data,
        'showSelectedVariationTitle',
        false
      ),
      showModelMeasurements: getDynamicField(data, 'showModelPlaque', false),
      sizeChartImage: getSizeChartImage(data, siteConfiguration, cmsBaseUri),
      showDivider: extractProperty(otherConfigs, 'show-divider-line', false),
      translations: {
        sizeChart: getTranslation(data, 'sizeChart', '$Size Chart$'),
        outOfStock: getTranslation(data, 'outOfStock', '$Out of Stock$'),
        chooseSize: getTranslation(data, 'chooseSize', 'Choose size'),
        sizeLabel: getTranslation(data, 'sizeLabel', '$2. Size$'),
        closeLabel: getTranslation(data, 'closeLabel', '$Close$'),
        yourSizeUnavailableCTA: getTranslation(
          data,
          'yourSizeUnavailableCTA',
          '$Your size unavailable?$'
        ),
        sizeButtonText: getTranslation(data, 'sizeButtonText', '$Size Chart$'),
        sizePlaceholder: getTranslation(
          data,
          'sizePlaceholder',
          '$Please choose the size$'
        ),
        soldOutCopy: getTranslation(
          data,
          'soldOutCopy-CHANGR',
          '$Something about this product selling out faster than expected. You might also like {{this recommendation}} to another product listing page.$'
        ),
        soldOutTitle: getTranslation(
          data,
          'soldOutTitle',
          '$THIS ITEM IS SOLD OUT$'
        ),
        validationMessages: {
          unselectedMessage: getTranslation(
            data,
            'validationMessages.unselectedMessage',
            '$Please choose the size.$'
          ),
          fieldIsRequired: getTranslation(
            data,
            'validationMessages.fieldIsRequired',
            '$This field is required.$'
          ),
          invalidMessage: getTranslation(
            data,
            'validationMessages.invalidMessage',
            '$Please enter a valid email address.$'
          ),
        },
        comingSoon: getTranslation(
          data,
          'comingSoon',
          '$Coming soon - {{date}}$'
        ),
        signUpToBeAlerted: getTranslation(
          data,
          'signUpToBeAlerted',
          '$Sign up to be alerted when the {{name}} in {{color}} is available to purchase. We promise not to spam you. You will only be notified once.$'
        ),
        emailAddressTitle: getTranslation(
          data,
          'emailAddressTitle',
          '$Email Address$'
        ),
        enterEmailAddress: getTranslation(
          data,
          'enterEmailAddress',
          '$Enter Email Address$'
        ),
        youAreOnTheList: getTranslation(
          data,
          'youAreOnTheList',
          "$You're on the list! We will notify you when {{name}} in {{color}} is available."
        ),
        weReceivedYourEmail: getTranslation(
          data,
          'weReceivedYourEmail',
          "$We've already received your email address for this product. We'll send you a notification when this item is available."
        ),
        letMeKnow: getTranslation(
          data,
          'letMeKnow',
          '$Let me know when my size is back$'
        ),
        weCanNotifyYou: getTranslation(
          data,
          'weCanNotifyYou',
          '$If we get the {{name}} in {{color}} back in stock we can notify you! Please enter your email.$'
        ),
      },
      recommendationLink: extractProperty(
        extractLocalSettingsExpandedLinks(data, 'recommendationLink')[0],
        'commerceRef.link',
        ''
      ),
      sizeChartLink: getDynamicField(data, 'sizeChartLink', ''),
      showSizeChart: getDynamicField(data, 'showSizeChart', true),
      showSizesExpanded: getDynamicField(data, 'showSizesExpanded', true),
    },
  };
};

const getSizeChartImage = (
  data: CMPlaceholder,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  const sizeChartConfig = extractLocalSettingsExpandedLinks(
    data,
    'sizeChartLink'
  );

  if (sizeChartConfig[0]) {
    return getImageObject(
      sizeChartConfig[0],
      siteConfiguration,
      null,
      cmsBaseUri
    );
  }

  return null;
};
