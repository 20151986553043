import type {
  CyberSourceTokenError,
  CardType,
  TokenizedCreditCard,
} from '@vf/api-contract';
import type { ComponentInstance } from '../types';
import type { VuelidateObject } from '../useValidation';

import { storeToRefs } from 'pinia';
import { ref, Ref, computed } from '@vue/composition-api';
import { apiClientFactory } from '@vf/api-client';
import { useFeatureFlagsStore } from '../store/featureFlags';
import { useCyberSourceStore } from '../store/cyberSourceStore';

import { getCardDataFromJWT } from '@vf/shared/src/utils/helpers/getCardDataFromJWT';
import { useCardStore } from '../store/card';

type CreditCardDetails = {
  cardNumber: string;
  securityCode: string;
  isCCValid: boolean;
  isSecurityCodeValid: boolean;
};

type CyberSourceData = {
  valid: boolean;
  couldBeValid: boolean;
  empty: boolean;
};

type CyberSourceCCData = CyberSourceData & {
  card: {
    name: CardType;
    brandedName: string;
    valid: boolean;
    couldBeValid: boolean;
    cybsCardType: string;
    lengths: number[];
    securityCode: {
      name: string;
      length: number;
    };
  }[];
};

const useCyberSource = (instance: ComponentInstance) => {
  const { getPaymentSession: getPaymentSessionAPI } = apiClientFactory(
    instance
  );
  const cyberSourceStore = useCyberSourceStore(instance);
  const {
    formInstance,
    sessionID,
    captureContext,
    cardErrors,
    possibleCardTypes,
    expirationDate,
    $v,
    isCyberSourceLoaded,
  } = storeToRefs(cyberSourceStore);
  const { setMicroformToken } = cyberSourceStore;
  const cardStore = useCardStore();

  const { isCheckoutRedesignEnabled } = useFeatureFlagsStore();

  const getCyberSourceToken = () => {
    const referrer = instance.$getDomainName();
    return getPaymentSessionAPI({
      referer: !/^https?:\/\//i.test(referrer)
        ? 'https://' + referrer
        : referrer,
    });
  };

  const prepareCSData = (
    callbackFn?: (tokenizedCreditCard: TokenizedCreditCard) => void
  ) => {
    if (isCheckoutRedesignEnabled && !callbackFn) {
      return new Promise((resolve) => {
        try {
          const [mm, yy] = (expirationDate.value ?? '').split('/');
          formInstance.value.createToken(
            {
              expirationMonth: mm,
              expirationYear: `20${yy}`,
            },
            (err?: CyberSourceTokenError, token?: string) => {
              if (err) {
                cardErrors.value = err.details;
                $v.value.cardNumber.$touch();
                $v.value.expirationDate.$touch();
                $v.value.securityCode.$touch();
                resolve(null);
                return;
              }

              setMicroformToken(token);
              const cardData = getCardDataFromJWT(token);
              cardStore.card = {
                ...cardData,
                cardType: cardStore.cardType.toUpperCase() as CardType,
              };
              resolve(token);
            }
          );
        } catch (err) {
          instance.$log.error(
            `[@useCyberSource/index::prepareCSData] unexpected createToken error`,
            err
          );
        }
      });
    }

    // TODO: GLOBAL15-56318 - remove code below in this method
    const options = {
      expirationMonth: document.querySelector<HTMLInputElement>(
        '#expirationMonth'
      ).value,
      expirationYear: document.querySelector<HTMLInputElement>(
        '#expirationYear'
      ).value,
    };

    const saveCCInput = document.querySelector<HTMLInputElement>(
      '[name="save-credit-card"]'
    );

    formInstance.value.createToken(options, function (err, token) {
      if (err) {
        /**
         * Will be changed with place order task
         */
        instance.$log.error(err);
        cardErrors.value = err.details;
        throw err;
      } else {
        callbackFn({
          cardType: cardStore.cardType,
          captureContextKey: captureContext.value,
          microformToken: token,
          saveCC: saveCCInput !== null ? saveCCInput.checked : false,
          sessionID: sessionID.value,
        });
      }
    });
  };

  const initializeCCForm = async (
    creditCardDetails: CreditCardDetails,
    vuelidateObject
  ) => {
    $v.value = vuelidateObject.value;
    // reset card selection; when returning to page
    expirationDate.value = '';
    cardStore.setCardType();
    possibleCardTypes.value = [];

    // Refer to cybersource docs : https://tinyurl.com/r94deefb.
    const number = formInstance.value.createField('number');
    const securityCode = formInstance.value.createField('securityCode');

    number.on('change', (data: CyberSourceCCData) => {
      handleCCNumberChange(data, creditCardDetails);
      handleFieldChange(data, '#number-container');
    });

    securityCode.on('change', (data: CyberSourceData) => {
      creditCardDetails.securityCode = data.empty ? '' : 'securityCode';
      creditCardDetails.isSecurityCodeValid = data.valid;
      handleFieldChange(data, '#securityCode-container');
    });

    number.on('blur', $v.value.cardNumber.$touch);
    number.load('#number-container');
    securityCode.on('blur', $v.value.securityCode.$touch);
    securityCode.load('#securityCode-container');
  };

  const showCreditCardNumberSuccess = ref(false);
  const showSecurityNumberSuccess = ref(false);

  const handleCCNumberChange = (
    data: CyberSourceCCData,
    creditCardDetails: CreditCardDetails
  ) => {
    cardStore.setCardType(data.card?.[0]?.name);
    possibleCardTypes.value = (data.card ?? []).map(
      (card) => card.name
    ) as CardType[];

    creditCardDetails.cardNumber = data.empty ? '' : 'cardNumber';
    creditCardDetails.isCCValid = data.valid;
  };

  const handleFieldChange = (data: CyberSourceData, itemId: string) => {
    const label = document.querySelector(itemId).nextElementSibling;

    const wrapper = document.querySelector(itemId)?.parentElement;
    wrapper?.classList?.[!data.empty ? 'add' : 'remove']?.(
      'vf-flex-microform__input-wrapper--focus'
    );

    if (!data.empty) {
      label.classList.add('credit-card-form__focused-label');
    } else {
      label.classList.remove('credit-card-form__focused-label');
    }

    switch (itemId) {
      case '#number-container':
        if (data.valid)
          cardErrors.value = cardErrors.value.filter(
            (element) => element.location !== 'number'
          );
        showCreditCardNumberSuccess.value = data.valid;
        break;
      case '#securityCode-container':
        if (data.valid)
          cardErrors.value = cardErrors.value.filter(
            (element) => element.location !== 'securityCode'
          );
        showSecurityNumberSuccess.value = data.valid;
        break;
    }
  };

  const setIsCyberSourceLoaded = (value: boolean) => {
    isCyberSourceLoaded.value = value;
  };

  const validateCyberSourceForm = (v: Ref<VuelidateObject>): boolean => {
    v.value && v.value.$touch();

    return !(
      !v.value ||
      v.value.$invalid ||
      v.value.cardNumber?.$invalid ||
      v.value.securityCode?.$invalid ||
      v.value.month?.$invalid ||
      v.value.year?.$invalid
    );
  };

  const isCcIconBlurred = (icon): boolean =>
    !!possibleCardTypes.value.length &&
    !possibleCardTypes.value.includes(icon.id);

  return {
    getCyberSourceToken,
    formInstance,
    sessionID,
    captureContext,
    expirationDate,
    cardErrors,
    prepareCSData,
    initializeCCForm,
    showCreditCardNumberSuccess: computed(
      () => showCreditCardNumberSuccess.value
    ),
    showSecurityNumberSuccess: computed(() => showSecurityNumberSuccess.value),

    handleCCNumberChange,
    handleFieldChange,

    isCyberSourceLoaded,
    setIsCyberSourceLoaded,
    possibleCardTypes,
    validateCyberSourceForm,
    isCcIconBlurred,
  };
};

export default useCyberSource;
