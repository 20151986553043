import { CMPlaceholder, CmsSite } from '@vf/api-contract';
import { decorateHtmlValue, extractProperty, getTranslation } from './utils';
import { ComposableContext } from '../types';

export const contactForm = (
  data: CMPlaceholder,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  const getTextOnlySubjects = () => {
    const localSettingsExpanded = extractProperty(
      data,
      'localSettingsExpanded',
      []
    );

    if (!Array.isArray(localSettingsExpanded)) {
      return {};
    }
    return localSettingsExpanded.reduce((obj, setting) => {
      return {
        ...obj,
        [setting.path.replace('dynamicFields.', '')]: decorateHtmlValue(
          extractProperty(setting, 'richTexts[0]', {}),
          cmsBaseUri,
          context,
          siteConfiguration
        ),
      };
    }, {});
  };
  const textOnlySubjects = getTextOnlySubjects();

  return {
    component: 'forms/ContactForm',
    props: {
      categories: getTranslation(data, 'categories', {
        order: '$Order Inquiry$',
        product: '$Product Information$',
        brand: '$Our brand$',
      }),
      subjects: getTranslation(data, 'subjects', {
        order: [
          '$General Status$',
          '$Lost package$',
          '$Cancel Order$',
          '$Return$',
        ],
        product: [
          '$Availability$',
          '$Custom Shoes$',
          '$General Product Question$',
        ],
        brand: [
          '$Employment$',
          '$General Question$',
          '$Gift Card$',
          '$International Shipping$',
          '$Password Reset$',
          '$Retail Comments$',
          '$Site Issues$',
          '$Sticker Request$',
        ],
      }),
      translations: {
        nameLabel: getTranslation(
          data,
          'nameLabel',
          '$First Name and Last Name$'
        ),
        subjectLabel: getTranslation(data, 'subjectLabel', '$Subject$'),
        categoryLabel: getTranslation(data, 'categoryLabel', '$Category$'),
        emailLabel: getTranslation(data, 'emailLabel', '$Email Address$'),
        phoneLabel: getTranslation(
          data,
          'phoneLabel',
          '$Phone (555-555-5555)$'
        ),
        phoneMask: getTranslation(data, 'phoneMask', '$###-###-####$'),
        orderNumberLabel: getTranslation(
          data,
          'orderNumberLabel',
          '$Order Number$'
        ),
        messageLabel: getTranslation(data, 'messageLabel', '$Message$'),
        submitButton: getTranslation(data, 'submitButton', '$Submit$'),
        continueShoppingButton: getTranslation(
          data,
          'continueShoppingButton',
          '$Continue Shopping$'
        ),
        messageSent: getTranslation(
          data,
          'messageSent',
          '$Your message has been sent$'
        ),
        validationMessages: getTranslation(data, 'validationMessages', {
          required: '$This field is required.$',
          email: '$Please enter a valid email address.$',
          phone:
            '$Please match the requested format: Enter phone number with no spaces or special characters.$',
        }),
      },
      textOnlySubjects,
    },
  };
};
