import { getTranslation } from './utils';
import { CMPlaceholder } from '@vf/api-contract';

export const plpFiltersDisplayToggle = (data: CMPlaceholder) => {
  return {
    component: 'plp/CategoryFiltersDisplayToggle',
    props: {
      translations: {
        showFilters: getTranslation(data, 'showFilters', '$Show Filters$'),
        hideFilters: getTranslation(data, 'hideFilters', '$Hide Filters$'),
      },
    },
  };
};
