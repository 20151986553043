import { getTranslation } from './utils/extractProperty';
import { CMPlaceholder, CmsSite } from '@vf/api-contract';
import {
  extractProperty,
  extractLocalSettingsExpandedLinks,
  generateLinkFromTeaserTargets,
  generateLinkFromTarget,
} from './utils';
import { ComposableContext } from '../types';
import { ROUTES } from '@vf/composables';

export const maResetPassword = (
  data: CMPlaceholder,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  const signInLinkData = extractProperty(
    extractLocalSettingsExpandedLinks(data, 'signInLink'),
    '[0]'
  );
  return {
    component: 'account/ResetPasswordForm',
    props: {
      translations: {
        heading: getTranslation(data, 'heading', 'Change Password'),
        subheading: getTranslation(
          data,
          'subheading',
          'Password must contain at least 8 characters and include 1 digit and 1 letter and 1 uppercase letter and 1 lowercase letter.'
        ),
        passwordLabel: getTranslation(data, 'passwordLabel', '$New Password$'),
        oldPasswordLabel: getTranslation(
          data,
          'oldPasswordLabel',
          '$Old Password$'
        ),
        confirmPasswordLabel: getTranslation(
          data,
          'confirmPasswordLabel',
          '$Confirm New Password$'
        ),
        validationMessages: getTranslation(data, 'validationMessages', {
          required: '$This field is required.$',
          password: '$Please enter a valid password.$',
          confirmPassword: '$Please enter the same value again.$',
        }),
        changePasswordButton: getTranslation(
          data,
          'changePasswordButton',
          '$Change Password$'
        ),
        cancelButton: getTranslation(data, 'cancelButton', '$Cancel$'),
        changePasswordSuccess: getTranslation(
          data,
          'changePasswordSuccess',
          '$YOUR PASSWORD HAS BEEN SUCCESSFULLY UPDATED.$'
        ),
      },
      signInLink:
        generateLinkFromTeaserTargets(
          signInLinkData?.teaserTargets,
          context,
          siteConfiguration,
          signInLinkData?.urlParams,
          cmsBaseUri
        ) || ROUTES.SIGN_IN(),
      successLink:
        generateLinkFromTarget(
          extractLocalSettingsExpandedLinks(data, 'successLink')[0],
          context,
          siteConfiguration,
          cmsBaseUri
        ) || ROUTES.PROFILE(),
    },
  };
};
