import { FocusableInputElement } from '../types';
const focusableElements =
  'a[href], button:not([disabled]), button:not([hidden]), [tabindex]:not([tabindex="-1"]), input:not([disabled]), select, textarea';

const isHidden = (selector: HTMLElement): boolean =>
  selector.offsetParent === null;

export const getFocusableChildren = (
  el: HTMLElement
): FocusableInputElement[] => {
  return Array.from(
    el.querySelectorAll<FocusableInputElement>(focusableElements)
  ).filter((selector: HTMLElement): boolean => !isHidden(selector));
};

export const setFocusFirstElement = (
  focusableChildrenElements: FocusableInputElement[],
  arg: string
): void => {
  // autofocus element has priority
  const autofocusElement = focusableChildrenElements.find((item) =>
    item.hasAttribute('autofocus')
  );
  if (arg == 'removeFirstElementFocus') return;
  if (autofocusElement) autofocusElement.focus();
  else if (arg == 'firstFocusOnCloseButton')
    focusableChildrenElements[0].focus();
  // [0] is the "x" close button, [1] is the modal content div
  else if (focusableChildrenElements[1]) focusableChildrenElements[1].focus();
  else focusableChildrenElements[0].focus();
};

export const isFocusable = (
  e: Event,
  focusableChildrenElements: FocusableInputElement[]
): boolean =>
  Array.from(focusableChildrenElements).some(
    (el: HTMLElement) => el === e.target
  );

export const moveFocus = (
  e: KeyboardEvent,
  focusableChildrenElements: FocusableInputElement[],
  arg: string
): void => {
  if (!focusableChildrenElements?.length || e.key !== 'Tab') return;

  if (!isFocusable(e, focusableChildrenElements)) {
    arg == 'removeFirstElementFocus' ? null : e.preventDefault();

    focusableChildrenElements[0].focus();
  }

  if (focusableChildrenElements.length === 1) {
    e.preventDefault();
    return;
  }

  const lastElementIndex = focusableChildrenElements.length - 1;
  const isLastElement =
    e.target === focusableChildrenElements[lastElementIndex];
  const isFirstElement = e.target === focusableChildrenElements[0];
  const isGoingForward = e.shiftKey === false;

  if (isGoingForward && isLastElement) {
    e.preventDefault();
    focusableChildrenElements[0].focus();
  } else if (!isGoingForward && isFirstElement) {
    e.preventDefault();
    focusableChildrenElements[lastElementIndex].focus();
  }
};
