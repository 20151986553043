import 'setimmediate';
import {
  sanitizeCmsPageContentResponse,
  sanitizeCmsEspotsResponse,
  sanitizeCmsVisualFiltersResponse,
  sanitizeCmsFlexibleContentByPathResponse,
} from './dataTransformators';
import { computed, ref, Ref } from '@vue/composition-api';
import { ComponentInstance, ComposablesStorage } from '../types';
import initStorage from '../utils/storage';
import {
  CmsBreadcrumb,
  CmsEspot,
  CmsHeaderConfig,
  CmsSanitizationRequirements,
  CmsSharedState,
  ComposableContext,
  PageTypeName,
  PreviewContentType,
  PreviewType,
  UseCmsStorage,
} from './types';
import * as dataFetcher from './dataFetcher';
import { Context, ModalSettings, PageType } from '@vf/api-contract';
import * as lifecycleMethods from './lifecycleMethods';
import {
  ROUTES,
  useI18n,
  useCategory,
  useProduct,
  useProductInventory,
  useSearch,
  useRequestTracker,
  useRouting,
  useUrl,
  useInitSetup,
} from '../index';
import {
  getBreadcrumbs,
  getEspots,
  getVisualFilter,
  getHeaderConfig,
  parseRemoteCsvFile,
  isValidProductData,
  getFlexibleContent,
} from './utils';
import { extractCommonConfigLink, extractProperty } from './mappings/utils';
import { createMetaTagObject } from '../utils/metaTags';
import { PageMetaData } from '@vf/api-client/src/types';
import { useCmsRefStore } from '@vf/composables/src/store/cmsRef';
import { useHeaderAndFooterStore } from '@vf/composables/src/store/headerAndFooter';
import { getPageCanonicalUrl } from './utils/getPageCanonicalUrl';
import { getStructuredData } from './utils/getStructuredData';
import { Product } from '@vf/api-client';
import { isClient, setWindowLocationHref } from '@vf/shared/src/utils/helpers';

const ContentToPageTypeMap = {
  CMExternalChannel: 'category',
  CMExternalProduct: 'product',
};

const useCms = (instance: ComponentInstance, contextKey?: string) => {
  const { trackRequest, clearRequest } = useRequestTracker(instance);
  const { localePath } = useI18n(instance);
  const { initSetup, verifySetup, apiClientRef } = useInitSetup(instance);
  const cmsRefStore = useCmsRefStore(instance.$pinia);
  const headerAndFooterStore = useHeaderAndFooterStore(instance.$pinia);

  const storage: ComposablesStorage<UseCmsStorage> = initStorage<UseCmsStorage>(
    instance,
    `useCms-${contextKey}`
  );

  const context: ComposableContext = { instance, contextKey };

  const pageLoadPercentage: Ref<number> =
    storage.get('pageLoadPercentage') ??
    storage.save('pageLoadPercentage', ref(0));

  const pageLoadingPath: Ref<string> =
    storage.get('pageLoadingPath') ?? storage.save('pageLoadingPath', ref(''));

  const setPageLoadPercentage = (percentage: number) => {
    pageLoadPercentage.value = percentage;

    if (percentage === 100) {
      setTimeout(() => {
        pageLoadPercentage.value = 0;
        pageLoadingPath.value = '';
      }, 500);
    }
  };

  const { meta, getProductDetails, product } = useProduct(
    context.instance,
    context.contextKey
  );
  const { getProductInventory, inventoryLoading } = useProductInventory(
    context.instance,
    context.contextKey
  );

  const retrieveProductData = (id: string): Promise<Product> => {
    return new Promise((resolve) => {
      const { query } = instance.context.route;
      if (product.value && product.value.id === id) {
        let isSameProduct = true;
        if (
          query.color &&
          !product.value.colors?.find((color) => color.value === query.color)
        ) {
          // query color selected not present in current product try to call again but using query color this time
          isSameProduct = false;
        }

        if (isSameProduct) return resolve(product.value);
      }

      return resolve(
        getProductDetails(id, {
          isBackgroundRequest: false,
          loadImages: true,
          saveVariation: false,
          configuredColor: query.color?.toString(),
          configuredVariant: query.variant as string,
          configuredSize: query.size as string,
        })
      );
    });
  };

  cmsRefStore.$patch({
    pageUrl: instance.$getPageUrl(),
  });

  /**
   * Register language switched listener
   * It is needed to call proper requests after user clicks NEXT and BACK in the browser
   * */
  context.instance.$i18n.onLanguageSwitched = async (
    oldLocale: string,
    newLocale: string
  ) => {
    /**
     * Fetch the languageSelectorSites common config which is set up per site
     * See https://digital.vfc.com/wiki/pages/viewpage.action?pageId=105198348#Country/LanguageSelector-CommonConfigSettingsFile
     */
    const languageSelectorSites = extractProperty(
      extractCommonConfigLink(
        cmsRefStore.cmsSiteConfiguration,
        'language-selector-sites'
      ),
      '[0].settings.sites',
      []
    );
    /**
     * Find a site with the chosen locale
     */
    const getCorrectLocale = (loc) => loc.locale === newLocale;
    /**
     * If site with chosen locale exists, redirect user to the site link
     */
    const parentLocale = languageSelectorSites.find((site) => {
      return site?.locales
        ? site.locales.find(getCorrectLocale)
        : site.locale === newLocale;
    });
    const externalSite = parentLocale?.locales
      ? parentLocale?.locales.find(getCorrectLocale)
      : parentLocale;

    /**
     * Check if its an external site
     * Otherwise redirect user to domain in site configuration.
     */

    if (externalSite?.link?.startsWith('http')) {
      setWindowLocationHref(externalSite.link);
      return;
    }

    cmsRefStore.$patch({
      site: instance.$getEnvValueByCurrentLocale<string>('CMS_SITE'),
    });
    /**
     * Check if there is a site for the chosen locale hosted
     * in Coremedia CMS (/site API)
     */
    const selectedSite = await dataFetcher.config.getSiteConfiguration(
      cmsRefStore.site,
      apiClientRef.value
    );
    /**
     * If selected locale is found in CMS (/site API),
     * redirect user to new homepage
     */
    const domains = (selectedSite.domain || '').split('|');
    /** Redirect to homepage */
    domains.includes(context.instance.$getDomainName())
      ? setWindowLocationHref(localePath('/'))
      : setWindowLocationHref(`///${domains[0]}`);
    /**
     * If selected locale for a given brand is found in CMS but
     * this locale belongs to a different domain that what the user is on now
     * (for e.g. vans.es -> vans.it)
     * redirect user to the new domain instead of hot-switching
     */
  };

  const changeLocale = (locale: string) => {
    // regex looking for case insensitive locale format of xx-xx (ex: en-us)
    if (!/^[a-z]{2,}(?:-[A-Z][a-z]*)*-([A-Z]{2})$/gim.test(locale)) {
      return;
    }
    /** Set locale to new one in app memory */
    context.instance.$i18n.setLocale(locale);
  };

  const resetCurrentFetchResource = () => {
    headerAndFooterStore.$patch({ headerAndFooterData: null });
    if (cmsRefStore.currentFetch) {
      cmsRefStore.$patch({
        currentFetch: {
          resourceId: undefined,
          resourceType: undefined,
        },
      });
    }
  };

  const isHeaderConfigChanged = (
    oldVal: CmsHeaderConfig,
    newVal: CmsHeaderConfig
  ): boolean => {
    if (oldVal && newVal) {
      const isPropertyChanged = (property: keyof CmsHeaderConfig) =>
        newVal[property] != null && oldVal[property] !== newVal[property];
      return ['backgroundColor', 'isAnimated', 'isFadeEnabled'].some(
        isPropertyChanged
      );
    }
    return !!newVal;
  };

  const fetchPage = async (
    path: string,
    qs: Record<string, string>,
    getHeaderAndFooterData,
    sanitizeHeaderAndFooterData,
    { isBackgroundRequest, disableTracking } = {
      isBackgroundRequest: false,
      disableTracking: false,
    }
  ) => {
    if (pageLoadPercentage.value && path === pageLoadingPath.value) return;
    pageLoadingPath.value = path;
    const { parseUrl } = useUrl(instance, contextKey);
    const { getPathWithoutLocalization } = useRouting(instance);
    const localizedPath = getPathWithoutLocalization(path);
    const { resourceType, resourceId, hidePageContent } = parseUrl(path, qs);
    const oldHeaderConfig = {
      ...headerAndFooterStore.headerConfig,
    };

    setPageLoadPercentage(20);

    instance.$log.debug('FETCH CMS PAGE', {
      path: localizedPath,
      id: resourceId,
      type: resourceType,
    });

    const fetchAfterRedirect =
      cmsRefStore.currentFetch &&
      cmsRefStore.currentFetch.resourceId &&
      cmsRefStore.currentFetch.resourceType &&
      cmsRefStore.currentFetch.resourceId === resourceId &&
      cmsRefStore.currentFetch.resourceType === resourceType;

    if (verifySetup()) {
      if (fetchAfterRedirect) {
        cmsRefStore.$patch({
          stateRedirectUrlExecuted: false,
          canonicalRedirectUrlExecuted: false,
        });
        setPageLoadPercentage(100);
        return;
      }
      let tag: symbol | null = null;
      if (!disableTracking) {
        tag = trackRequest('useCms-fetchPage', isBackgroundRequest).tag;
      }

      const [pageData, headerAndFooterData] = await Promise.all([
        getPageContentData(
          resourceType,
          resourceId,
          hidePageContent,
          localizedPath,
          qs
        ),
        getHeaderAndFooterData(),
      ]);

      let sanitizedHeaderAndFooterData = headerAndFooterData;

      // sanitize header and footer data if config changed
      if (isHeaderConfigChanged(oldHeaderConfig, pageData.headerConfig)) {
        sanitizedHeaderAndFooterData = sanitizeHeaderAndFooterData(
          headerAndFooterStore.headerAndFooterData
        );
      }

      setPageLoadPercentage(90);
      updatePageContentRefs(
        { ...pageData, breadcrumbs: undefined },
        sanitizedHeaderAndFooterData
      );
      if (tag) {
        clearRequest(tag, isBackgroundRequest);
      }

      if (!disableTracking) {
        cmsRefStore.$patch({
          currentFetch: { path, resourceId, resourceType },
        });
      }

      setPageLoadPercentage(100);
    } else {
      setPageLoadPercentage(100);
      cmsRefStore.$patch({
        errors: [
          ...cmsRefStore.errors,
          'CMS has not been properly configured. Check console for more details.',
        ],
      });
    }
  };

  const fetchPreview = async (
    type: PreviewType,
    id: string,
    contentType: PreviewContentType,
    commerceId: string,
    getHeaderAndFooterData,
    sanitizeHeaderAndFooterData,
    { isBackgroundRequest } = { isBackgroundRequest: false }
  ) => {
    if (verifySetup()) {
      const { tag } = trackRequest('useCms-fetchPreview', isBackgroundRequest);
      const [previewData] = await Promise.all([
        dataFetcher.preview.getPreviewData(
          type,
          id,
          contentType,
          commerceId,
          cmsRefStore.cmsSiteConfiguration.id,
          apiClientRef.value
        ),
        getHeaderAndFooterData(),
      ]);

      if (!previewData.content) {
        instance.$log.warn(
          `[@useCms::fetchPreview] Page with no content accessed at <${instance.$root.$route.path}>`
        );
        return;
      }

      const requirements: CmsSanitizationRequirements = [];

      const contentSanitized = sanitizeCmsPageContentResponse(
        previewData.content,
        cmsRefStore.cmsSiteConfiguration,
        context,
        cmsRefStore.baseMediaUri,
        requirements,
        PageTypeName.OTHER // Todo: Implement PageTypeName for Preview
      );

      const pageType = ContentToPageTypeMap[contentType];

      const {
        stateRedirectUrlExecuted,
      } = await lifecycleMethods.afterCmsResponseSanitization(
        pageType,
        context,
        commerceId,
        previewData.content,
        requirements,
        undefined,
        pageType === 'product'
          ? await retrieveProductData(commerceId)
          : undefined
      );

      if (stateRedirectUrlExecuted) {
        cmsRefStore.$patch({
          stateRedirectUrlExecuted: true,
        });
        return;
      }
      const isHeaderVisible =
        previewData.content?.headerVisibility !== 'hidden';
      const isFooterVisible =
        previewData.content?.footerVisibility !== 'hidden';
      const headerConfig = getHeaderConfig(previewData.content);
      const sanitizedHeaderAndFooterData = sanitizeHeaderAndFooterData(
        headerAndFooterStore.headerAndFooterData
      );

      updatePageContentRefs(
        {
          content: contentSanitized.data,
          errors: previewData.errors.concat(contentSanitized.errors),
          requirements,
          headerVisible: isHeaderVisible,
          footerVisible: isFooterVisible,
          pageTypeName: PageTypeName.OTHER, // Todo: Implement PageTypeName for Preview
          pageLayoutName: '',
          pageProps: {},
          headerConfig,
          breadcrumbs: previewData.breadcrumbs || [],
        },
        sanitizedHeaderAndFooterData
      );
      clearRequest(tag, isBackgroundRequest);
    }
  };

  const fetchModalContents = async (
    type: ModalSettings['type'],
    id: string,
    path: string
  ): Promise<void> => {
    if (!verifySetup()) {
      setPageLoadPercentage(100);
      throw new Error('Site setup is broken!');
    }
    try {
      if (type === 'page') {
        const { getPathWithoutLocalization } = useRouting(instance);

        const getPageTypeAndId = (pagePath: string): [string, string] => {
          const defaultData: [string, string] = [type, id];

          if (!pagePath) {
            return defaultData;
          }

          if (path.includes('/category/')) {
            return ['category', path.split('/').pop()];
          }
          if (path.includes('/product/')) {
            return ['product', path.split('/').pop()];
          }

          return defaultData;
        };

        cmsRefStore.$patch({
          modalData: null,
          modalDataErrors: [],
        });

        const localizedPath = getPathWithoutLocalization(path);

        const [pageType, pageId] = getPageTypeAndId(path);

        // Workaround until GLOBAL15-37717 is done
        const pageBreadcrumb = cmsRefStore.breadcrumbs;

        const pageData = await getPageContentData(
          pageType as PageType,
          pageId,
          false,
          localizedPath
        );

        cmsRefStore.$patch({
          modalData: pageData.content,
          breadcrumbs: pageBreadcrumb,
          pageTypeName: pageData.pageTypeName,
          modalDataErrors: pageData.errors,
        });
      }

      if (type === 'fragment') {
        /** Search for modal data in Site configuration response */
        const modalCmsData = (
          cmsRefStore.cmsSiteConfiguration.commonConfigExpanded || []
        ).find((setting) => setting.path === id);

        /** There is no Modal Data on CMS side! Show an error in modal contents */
        if (!modalCmsData || !modalCmsData.links.length) {
          cmsRefStore.$patch({
            modalDataErrors: [
              `Modal data has not been found in site configuration, site ID: ${cmsRefStore.cmsSiteConfiguration.id}, modal ID: ${id}`,
            ],
          });
        } else {
          /** Fetch fragment data from CMS headless server */
          const fragmentSanitized = await getFragmentAndSanitize(
            modalCmsData.links[0].id,
            PageTypeName.MODAL
          );

          /** Assign reactive variables values */
          cmsRefStore.$patch({
            modalData: fragmentSanitized.data as any,
            modalDataErrors: fragmentSanitized.errors,
            requirements: [],
          });
        }
      }

      if (type === 'lazyFragment') {
        /** Fetch fragment data from CMS headless server */
        const modalData = await dataFetcher.page.getModalContents(
          id,
          apiClientRef.value
        );

        const sanitizeAndPatchModal = () => {
          const requirements: CmsSanitizationRequirements = [];

          /** Sanitize modal window response contents */
          const fragmentSanitized = sanitizeCmsPageContentResponse(
            modalData.content,
            cmsRefStore.cmsSiteConfiguration,
            context,
            cmsRefStore.baseUri,
            requirements,
            PageTypeName.MODAL
          );

          /** Assign reactive variables values */
          cmsRefStore.$patch({
            modalData: fragmentSanitized.data as any,
            modalDataErrors: fragmentSanitized.errors,
            requirements: requirements,
          });
        };

        const patchModalErrors = () => {
          cmsRefStore.$patch({
            modalDataErrors: [
              `Modal fragment has not been found, site ID: ${cmsRefStore.cmsSiteConfiguration.id}, modal ID: ${id}`,
            ],
          });
        };

        modalData.content ? sanitizeAndPatchModal() : patchModalErrors();
      }
    } finally {
      setPageLoadPercentage(100);
    }
  };

  const getFragmentAndSanitize = async (
    id: string,
    pageTypeName: PageTypeName
  ) => {
    /** Fetch fragment data from CMS headless server */
    const contentData = await dataFetcher.page.getModalContents(
      id,
      apiClientRef.value
    );

    /** Sanitize modal window response contents */
    return sanitizeCmsPageContentResponse(
      contentData.content,
      cmsRefStore.cmsSiteConfiguration,
      context,
      cmsRefStore.baseUri,
      [],
      pageTypeName
    );
  };

  const getFlexContentByPath = (type: PageType) =>
    type === 'homepage' &&
    instance.$root.$themeConfig.cmsPage.flexContentEnabled
      ? dataFetcher.page.getFlexibleContentByPath(
          cmsRefStore.cmsSiteConfiguration.id,
          apiClientRef.value
        )
      : undefined;

  // Private methods
  const getPageContentData = async (
    type: PageType,
    id: string,
    hidePageContent: boolean,
    path: string,
    qs?: { [key: string]: string }
  ) => {
    const defaultPageContent = {
      content: {
        children: [
          {
            component: 'common/RichText',
          },
        ],
      },
      breadcrumbs: [],
      pageTypeName: null,
      pageLayoutName: null,
      pageProps: {},
      meta: {},
      errors: [],
      requirements: [],
      headerVisible: true,
      footerVisible: true,
    };

    if (hidePageContent) {
      return defaultPageContent;
    }

    cmsRefStore.$patch({
      isNotFound: false,
      stateRedirectUrlExecuted: false,
    });

    inventoryLoading.value = true;

    const [
      productDataResult,
      pageContentResult,
      flexibleContentByPathResult,
    ] = await Promise.allSettled([
      type === 'product'
        ? await (retrieveProductData(id) as any).catch(() => {
            return { id: id };
          })
        : undefined,
      dataFetcher.page.fetchPageContent(
        type,
        context,
        cmsRefStore.cmsSiteConfiguration,
        apiClientRef.value,
        id,
        path
      ),
      getFlexContentByPath(type),
    ]);

    if (
      type === 'product' &&
      isClient &&
      productDataResult.status === 'fulfilled' &&
      isValidProductData(context.instance, productDataResult.value)
    ) {
      getProductInventory(id);
    }
    setPageLoadPercentage(60);

    if (path === ROUTES.NOT_FOUND()) {
      cmsRefStore.$patch({ isNotFound: true });
    }

    if (
      path !== ROUTES.NOT_FOUND() &&
      (pageContentResult.status === 'rejected' ||
        !pageContentResult.value.content)
    ) {
      cmsRefStore.$patch({ isNotFound: true });
      if (path !== ROUTES.NOT_FOUND()) {
        return getPageContentData(null, null, false, ROUTES.NOT_FOUND(), qs);
      }
    }

    const pageContent = (pageContentResult as PromiseFulfilledResult<any>)
      .value;

    let flexibleContent = [];
    if (flexibleContentByPathResult) {
      flexibleContent = getFlexibleContent(
        (flexibleContentByPathResult as PromiseFulfilledResult<any>).value
      );
    }

    const breadcrumbs = getBreadcrumbs(
      pageContent.content,
      type,
      instance,
      cmsRefStore
    );
    cmsRefStore.$patch({ breadcrumbs });

    const espots = getEspots(pageContent.content);
    const visualFilter = getVisualFilter(pageContent.content);
    const requirements: CmsSanitizationRequirements = [];

    // content sanitizer
    const contentSanitized = sanitizeCmsPageContentResponse(
      pageContent.content,
      cmsRefStore.cmsSiteConfiguration,
      context,
      cmsRefStore.baseMediaUri,
      requirements,
      pageContent.pageTypeName
    );

    const espotsSanitized = sanitizeCmsEspotsResponse(
      espots,
      cmsRefStore.cmsSiteConfiguration,
      context,
      cmsRefStore.baseMediaUri,
      requirements,
      pageContent.pageTypeName
    );

    const visualFilterSanitized = sanitizeCmsVisualFiltersResponse(
      visualFilter,
      cmsRefStore.cmsSiteConfiguration,
      context,
      cmsRefStore.baseMediaUri,
      requirements,
      pageContent.pageTypeName
    );

    const flexibleContentSanitized = sanitizeCmsFlexibleContentByPathResponse(
      flexibleContent,
      context,
      cmsRefStore.cmsSiteConfiguration,
      cmsRefStore.baseMediaUri
    );

    cmsRefStore.$patch({
      flexibleContentByPathResult:
        flexibleContentSanitized.data.flexContentByPathResult,
    });

    const {
      notFound,
      stateRedirectUrlExecuted,
      canonicalRedirectUrlExecuted,
      canonicalUrl,
    } = await lifecycleMethods.afterCmsResponseSanitization(
      type,
      context,
      id,
      pageContent.content,
      requirements,
      qs,
      productDataResult.status === 'fulfilled' ? productDataResult.value : null
    );

    setPageLoadPercentage(80);

    if (notFound) {
      cmsRefStore.$patch({ isNotFound: true });
      return getPageContentData(null, null, false, ROUTES.NOT_FOUND(), qs);
    }

    cmsRefStore.$patch({
      stateRedirectUrlExecuted: stateRedirectUrlExecuted,
      canonicalRedirectUrlExecuted: canonicalRedirectUrlExecuted,
    });

    if (stateRedirectUrlExecuted) {
      return defaultPageContent;
    }

    const isHeaderVisible = pageContent.content?.headerVisibility !== 'hidden';
    const isFooterVisible = pageContent.content?.footerVisibility !== 'hidden';
    const headerConfig = getHeaderConfig(pageContent.content);

    if (contextKey === Context.PageContent) {
      headerAndFooterStore.$patch({ headerConfig });
    }

    return {
      requirements,
      breadcrumbs,
      espots: espotsSanitized.data,
      visualFilter: visualFilterSanitized.data,
      content: contentSanitized.data,
      pageTypeName: pageContent.pageTypeName,
      pageLayoutName: pageContent.pageLayoutName,
      pageProps: pageContent.pageProps,
      errors: pageContent.errors
        .concat(contentSanitized.errors)
        .concat(espotsSanitized.errors)
        .concat(visualFilterSanitized.errors),
      meta: getPageMeta(cmsRefStore, type, pageContent, canonicalUrl),
      headerVisible: isHeaderVisible,
      footerVisible: isFooterVisible,
      headerConfig,
      flexibleContentByPathResult:
        flexibleContentSanitized.data.flexContentByPathResult,
    };
  };

  const getPageMeta = (
    cmsRefValue,
    type,
    pageContent,
    commercePageCanonicalUrl
  ) => {
    let pageMeta = {
      ...getPageContentMetas(pageContent, cmsRefValue),
      ...(commercePageCanonicalUrl && {
        canonicalUrl: getPageCanonicalUrl(commercePageCanonicalUrl, instance),
      }),
    };
    if (!pageMeta.htmlTitle) {
      pageMeta.htmlTitle = pageContent?.content?.commerceRef?.title;
    }
    switch (type) {
      case 'customizer': {
        pageMeta = {
          ...pageMeta,
          canonicalUrl: getPageCanonicalUrl(instance.$route.path, instance),
        };
        break;
      }
      case 'category': {
        const { meta, category, categoryId } = useCategory(
          context.instance,
          context.contextKey
        );
        const htmlTitle =
          getCommerceTitle(meta.value) || category.value || pageMeta.htmlTitle;
        pageMeta = {
          ...pageMeta,
          htmlTitle,
          commerceTags: meta.value,
          twitterCards: {
            ...pageMeta?.twitterCards,
            ...(meta.value['og:description']?.content
              ? { tcDescription: meta.value['og:description']?.content }
              : null),
          },
          analyticsTags: {
            ...pageMeta?.analyticsTags,
            categoryId: categoryId.value,
          },
        };
        break;
      }
      case 'product': {
        const htmlTitle =
          getCommerceTitle(meta.value) ||
          product.value?.name ||
          pageMeta.htmlTitle;
        const htmlDescription = product.value?.description?.replace(
          /(<([^>]+)>)/gi, // remove html markup from the product description
          ''
        );

        let productSpecificTags = [
          createMetaTagObject('og:productId', product.value?.id, true),
        ];
        pageMeta = {
          ...pageMeta,
          analyticsTags: {
            ...pageMeta?.analyticsTags,
            productId: product.value?.id,
          },
        };
        if (product.value?.variant) {
          const { price, stock } = product.value.variant;
          const inStockLabel = stock?.inStock ? 'in stock' : 'out of stock';
          productSpecificTags = [
            ...productSpecificTags,
            createMetaTagObject(
              'og:price:amount',
              price?.current?.toString(),
              true
            ),
            createMetaTagObject('og:price:currency', price?.currency, true),
            createMetaTagObject('og:availability', inStockLabel, true),
          ];
          const { width, height } =
            cmsRefValue?.cmsSiteConfiguration?.commonConfig
              ?.ogProductImageSizes || {};

          const imageUrl = instance.$root.$mediaUrlGenerator({
            productName: product.value?.name,
            pid: product.value?.id,
            colorCode: colorCode.value,
            colorName: product.value?.color?.label,
            shotType: instance.$env.IMAGES_SHOTTYPE,
            preset: width && height ? `wid=${width}&hei=${height}&qlt=95` : '',
          }) as string;
          productSpecificTags = [
            ...productSpecificTags,
            createMetaTagObject('image', imageUrl),
            createMetaTagObject('og:image', imageUrl, true),
            createMetaTagObject('og:image:width', width, true),
            createMetaTagObject('og:image:height', height, true),
          ];
        }

        pageMeta = {
          ...pageMeta,
          commerceTags: {
            ...Object.fromEntries(
              productSpecificTags.map((tag) => [tag.id, tag])
            ),
            ...meta.value,
          },
          htmlTitle,
          htmlDescription,
          openGraph: {
            ogUrl: product.value?.fullPageUrl,
            ogType: pageContent?.content?.commerceRef?.type || 'product',
            ogLocale: cmsRefValue?.cmsSiteConfiguration?.locale,
          },
          twitterCards: {
            ...pageMeta?.twitterCards,
            ...(meta.value['og:description']?.content
              ? { tcDescription: meta.value['og:description']?.content }
              : null),
          },
          analyticsTags: {
            ...pageMeta?.analyticsTags,
            productId: product.value?.id,
          },
        };
        break;
      }
      case 'tag': {
        const { queryString, meta } = useSearch(context.instance);
        const searchQuery = queryString.value;
        const htmlTitle =
          getCommerceTitle(meta.value) ||
          `${searchQuery} | ${instance.$env.WEBSITE_NAME}`;
        pageMeta = {
          ...pageMeta,
          htmlTitle,
          htmlDescription: meta.value?.description,
          commerceTags: meta.value,
          twitterCards: {
            ...pageMeta?.twitterCards,
            ...(meta.value['og:description']?.content
              ? { tcDescription: meta.value['og:description']?.content }
              : null),
          },
          openGraph: {
            ogDescription: meta.value['og:description']?.content,
            ogTitle: meta.value['og:title']?.content,
          },
          analyticsTags: {
            ...pageMeta?.analyticsTags,
            searchQuery,
          },
        };
        break;
      }
      case 'search': {
        const { queryString } = useSearch(context.instance);
        const query = instance.$root.$route.query.q || queryString.value;
        const searchQuery = Array.isArray(query) ? query[0] ?? '' : query;
        const title = pageMeta.htmlTitle;
        const htmlTitle = computed(() => {
          return instance.$root.$themeConfig.cmsPage
            .showQueryStringInSearchPageMetaTitle
            ? `${title} ${queryString.value}`
            : title;
        });

        pageMeta = {
          ...pageMeta,
          htmlTitle,
          analyticsTags: {
            ...pageMeta?.analyticsTags,
            searchQuery,
          },
        };
        break;
      }
    }
    return pageMeta;
  };

  const colorCode = computed(() =>
    instance.$root.$themeConfig.openGraph?.isColorNeededForOgImage ?? false
      ? (instance.$root.$route.query?.color as string) ??
        product.value?.color?.value
      : ''
  );

  const getPageContentMetas = (
    pageContent: any,
    cmsRefValue: CmsSharedState
  ) => {
    const pageContentMetas = {
      ...pageContent.meta,
      ...getStructuredData(
        pageContent,
        instance.$config.SEO_JSONLD_ORGANIZATION
      ),
    };

    if (!pageContentMetas) return {};

    if (pageContentMetas.canonicalUrl === localePath('/tag')) {
      pageContentMetas.canonicalUrl = cmsRefValue.pageUrl;
    }

    return pageContentMetas;
  };

  const getCommerceTitle = (meta: PageMetaData): string => {
    return meta?.title?.content;
  };

  const clearModalContent = () => {
    cmsRefStore.$patch({
      modalData: null,
      modalDataErrors: [],
    });
  };
  const updatePageContentRefs = (
    pageData: {
      content: any;
      breadcrumbs?: CmsBreadcrumb[];
      espots?: CmsEspot[];
      visualFilter?: any;
      errors: string[];
      pageTypeName: PageTypeName;
      pageLayoutName: string;
      pageProps: { [key: string]: string };
      requirements: CmsSanitizationRequirements;
      meta?: { [key: string]: any };
      headerVisible?: boolean;
      footerVisible?: boolean;
      headerConfig?: CmsHeaderConfig;
      flexibleContentByPathResult?: any;
    },
    headerAndFooterContent: { content: any; errors: string[] }
  ) => {
    cmsRefStore.$patch({
      errors: [...pageData.errors, ...headerAndFooterContent.errors],
      page: pageData.content,
      pageTypeName: pageData.pageTypeName,
      pageLayoutName: pageData.pageLayoutName,
      pageProps: pageData.pageProps,
      requirements: pageData.requirements,
      espots: pageData.espots,
      visualFilter: pageData.visualFilter,
      pageUrl: instance.$getPageUrl(),
      flexibleContentByPathResult: pageData.flexibleContentByPathResult,
    });
    cmsRefStore.$patch((state) => {
      state.meta = pageData.meta ?? {};
    });
    if (pageData.breadcrumbs) {
      cmsRefStore.$patch({
        breadcrumbs: pageData.breadcrumbs,
      });
    }

    headerAndFooterStore.$patch({
      header:
        headerAndFooterContent.content.header || headerAndFooterStore.header,
      footer:
        headerAndFooterContent.content.footer || headerAndFooterStore.footer,
      headerVisible: pageData.headerVisible,
      footerVisible: pageData.footerVisible,
      headerConfig: pageData.headerConfig,
    });
  };

  const afterSSR = async (ctx: ComposableContext, pageType: string) => {
    return lifecycleMethods.afterSSR(ctx, pageType);
  };

  const getRobotsResource = async () => {
    if (!apiClientRef.value) {
      const msg = 'CMS Api Client not initialized!';
      console.error(msg);
      return [];
    }
    return dataFetcher.config.getFragmentFolderData(
      instance,
      apiClientRef.value,
      'Settings/Domains',
      cmsRefStore.cmsSiteConfiguration.domain,
      'robots'
    );
  };

  const setFacetConfiguration = (config) => {
    cmsRefStore.$patch((state) => {
      state.facetConfiguration = config;
    });
  };

  const getArrayFromCsv = (filePath) => {
    return parseRemoteCsvFile(instance, filePath);
  };

  return {
    setup: initSetup,
    verifySetup,
    resetCurrentFetchResource,
    fetchPage,
    fetchPreview,
    fetchModalContents,
    clearModalContent,
    changeLocale,
    apiClientRef,
    getArrayFromCsv,
    errors: computed(() => cmsRefStore.errors),
    page: computed(() => cmsRefStore.page),
    seo: computed(() => cmsRefStore.seo),
    sizeCharts: computed(() => cmsRefStore.sizeCharts),
    header: computed(() => headerAndFooterStore.header),
    espots: computed(() => cmsRefStore.espots || []),
    visualFilter: computed(() => cmsRefStore.visualFilter),
    footer: computed(() => headerAndFooterStore.footer),
    pageLayoutName: computed(() => cmsRefStore.pageLayoutName),
    pageProps: computed(() => cmsRefStore.pageProps),
    baseMediaUri: computed(() => cmsRefStore.baseMediaUri),
    baseUri: computed(() => cmsRefStore.baseUri),
    currentFetch: computed(() => cmsRefStore.currentFetch),
    modalData: computed(() => cmsRefStore.modalData),
    modalDataErrors: computed(() => cmsRefStore.modalDataErrors),
    meta: computed(() => cmsRefStore.meta),
    pageUrl: computed(() => cmsRefStore.pageUrl),
    isNotFound: computed(() => cmsRefStore.isNotFound),
    flexibleContentByPathResult: computed(
      () => cmsRefStore.flexibleContentByPathResult
    ),
    stateRedirectUrlExecuted: computed(
      () => cmsRefStore.stateRedirectUrlExecuted
    ),
    canonicalRedirectUrlExecuted: computed(
      () => cmsRefStore.canonicalRedirectUrlExecuted
    ),
    headerVisible: computed(() => headerAndFooterStore.headerVisible),
    footerVisible: computed(() => headerAndFooterStore.footerVisible),
    headerConfig: computed(() => headerAndFooterStore.headerConfig),
    site: computed(() => cmsRefStore.site),
    afterSSR,
    getFragmentAndSanitize,
    getRobotsResource,
    getPageContentData,
    setFacetConfiguration,
    facetConfiguration: computed(() => cmsRefStore.facetConfiguration),
    employeeSignInPage: computed(() => cmsRefStore.employeeSignInPage),
    pageLoadPercentage: computed(() => pageLoadPercentage.value),
    getPageMeta,
    resetPage: () =>
      cmsRefStore.$patch({ page: { ...cmsRefStore.page, children: [] } }),
  };
};

export default useCms;
