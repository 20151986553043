import { computed, readonly, Ref, ref } from '@vue/composition-api';
import {
  apiClientFactory,
  Cart,
  CartLineItem,
  CartLineItemOutOfStock,
  Product,
} from '@vf/api-client';
import { FlashErrorType, SaveForLaterItemRequest } from '@vf/api-contract';

import { ComponentInstance, ComposablesStorage } from '../types';
import {
  BgRequest,
  BgRequestWithTempBasket,
  TempBasket,
  UpdateItemObject,
  UseCartStorage,
} from './types';
import initStorage from '../utils/storage';
import ls from '../utils/localStorage';
import { useCartStore } from '../store/cartStore';
import { usePromotionMessageStore } from '../store/promotionMessageStore';
import useUrl from '../useUrl';
import { useRequestTracker } from '../useRequestTracker';
import { errorMessages } from '../utils/errorMessages';
import getCartImage from '../utils/dataSources/handlers/getCartImage';
import {
  prepareLocale,
  prepareQuery,
  prepareTemporaryBasket,
} from '../utils/query';
import { useI18n } from '../useI18n';
import { useCustoms } from '../useCustoms';
import { usePickup } from './composables/usePickup';

import useSignInToStore, { EmployeeDetails } from '../useSignInToStore';
import useCheckout from '../useCheckout';
import { ROUTES } from '../utils/routes';
import { getProductListWithDeletedItems } from '../utils/getProductListWithDeletedItems';
import { mapUpdateItemRequestObject } from '../utils/mapUpdateItemRequestObject';
import { ApplePayContext } from '../useApplePay/types';
import { isBasketNotFoundError } from './helpers';
import {
  getItemGiftBoxPrice,
  getProductImage,
} from '@vf/shared/src/utils/helpers';
import { getRecipeId, isCustomsProduct } from '../useCustoms/utils';
import { useSharedCart } from './composables/useSharedCart';
import { useMiniCart } from './composables/useMiniCart';
import { usePromotions } from './composables/usePromotions';
import { useShipping } from './composables/useShipping';
import { useFlashErrors } from './composables/useFlashErrors';
import { useRecentlyAddedProduct } from './composables/useRecentlyAddedProduct';
import { useCustomerNotifications } from './composables/useCustomerNotifications';
import { usePDPDetails } from './composables/usePDPDetails';
import { useQuickShop } from './composables/useQuickShop';
import { useGuestCart } from './composables/useGuestCart';
import { usePayPal } from './composables/usePayPal';
import { useGiftCards } from './composables/useGiftCards';
import { usePrice } from './composables/usePrice';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';

export type AddToCartPayloadItem = {
  cartId: string;
  productId: string;
  qty: number;
  sku: string;
  maxQty: number;
  recipeId: string;
  saveForLater?: SaveForLaterItemRequest;
  storeAssociateData?: EmployeeDetails;
  storeId?: string;
};

export type CustomsAddToCartPayloadItem = Omit<
  AddToCartPayloadItem,
  'recipeId'
> & {
  isCustoms: true;
  recipe: string;
  precreatedCustomsCode: string;
  model?: string;
  imageView?: string;
  imageSize?: string;
  size?: string;
};

export type ErrorDetailObject = {
  message: string;
  type: string;
  persistent: boolean;
  errorMessageId: string;
  additionalInfo: any;
};

type CartInitializationObject = {
  storeId: number | string;
  employeeId: number | string;
  login: string;
};

type InventorySupplyCheck = { inventorySupplyCheck: boolean };
type BgRequestWithInventorySupplyCheck = BgRequest & InventorySupplyCheck;
type CartParams = BgRequest & TempBasket & InventorySupplyCheck;

const LS_CART_ID_KEY = 'cartId';

export const cartObject = {
  products: [],
  items: [],
  currency: '',
  totalItems: 0,
  discounts: {
    otherDiscounts: 0,
  },
  id: '',
  shippingMethods: [],
  billingAddress: {
    addressId: '',
    firstName: '',
    lastName: '',
    email: '',
    country: '',
    addressLine1: '',
    addressLine2: '',
    postalCode: '',
    city: '',
    province: '',
    phone: '',
  },
  totals: {
    itemTotal: 0,
    shipping: 0,
    tax: 0,
    totalWithoutTax: 0,
    total: 0,
    remainingToPay: 0,
  },
  orderPromotions: [],
  payment_instruments: [],
  flash: [],
  bounceBackVouchers: [],
  customerNotifications: [],
  featureFlags: {
    applyBestShippingMethod: false,
  },
};

const convertToArray = (objectOrArray) => {
  return [].concat(objectOrArray);
};

const setINV408Flash = (errorDetails) => {
  const inv408Errors = errorDetails.filter(
    ({ errorMessageId }) => errorMessageId === 'INV408'
  );
  useCartStore().appendINV408Flash(inv408Errors);
};

export const useScopedCart = (contextKey?: string) => {
  const customerBasketsQuotaExceededExceptionHandler = (
    data: any
  ): string | null => {
    let cartId = null;
    if (data?.errorDetails?.length) {
      for (let i = 0; data.errorDetails.length > i; i++) {
        if (data.errorDetails[i].additionalInfo) {
          for (let j = 0; data.errorDetails[i].additionalInfo.length > j; j++) {
            cartId = data.errorDetails[i].additionalInfo[j].argument?.basketIds;
            break;
          }
        }
      }
    }
    return cartId;
  };

  let inited,
    instance = {
      $composables: {},
    } as ComponentInstance,
    buildItemPDPDetails,
    appliedGiftCards,
    appliedRewards,
    autoAppliedPromotions,
    payFullWithGiftCard,
    productsExcludedFromAthletes,
    clearGuestCart,
    saveGuestCart,
    isCartMergeNotificationVisible,
    initPayPalSession,
    updatePayPalSession,
    overridePrice,
    resetPrice,
    updatePrice,
    isPriceAdjusted,
    checkGiftCardBalance,
    setQuickshopSizeChart,
    quickshopSizeChart,
    itemsToShip,
    itemsForPickup,
    hasPickupItems,
    isPickupItem,
    isShipmentPickup,
    updateCartForBopis,
    setShippingMethod,
    getShippingMethods,
    defaultShippingAddress,
    shippingGroups,
    getShippingGroupFormattedPrice,
    hasFlashErrorsToDisplay,
    getProductRelatedFlashMessages,
    updateCartFlashesFromError,
    outOfStockFlashErrors,
    updateCartFlashes,
    recentlyAddedProduct,
    setRecentlyAddedProduct,
    setMiniCart,
    setHideMiniCart,
    isMinicartHidden,
    isMinicartOpen,
    clearCartLevelCustomerNotifications,
    cartLevelCustomerNotificationActions,
    updateCustomerNotifications,
    clearCartLineItemCustomerNotifications,
    cartLineItemCustomerNotifications,
    trackRequest,
    clearRequest,
    displayErrorMessages,
    getErrorDetails,
    getINV408ErrorMessageById,
    getRelativeUrl,
    getCustomsCaid,
    setImagesDeclined,
    localeCode,
    localePath,
    addItemAPI,
    createCartAddItemAPI,
    deleteItemAPI,
    getCartAPI,
    getCartObjectAPI,
    getCustomsSkuAPI,
    getSmartGiftCartAPI,
    initCartAPI,
    prepareOrderAPI,
    swapItemAPI,
    updateItemAPI,
    setFamilyRewardAPI,
    getShareLink,
    showExpiredSharedCartError,
    applyShared,
    notAddedItems,
    clearNotAddedItems;

  const deletedFromStandardCartProductsList = ref([]);

  const storage: ComposablesStorage<UseCartStorage> = initStorage<UseCartStorage>(
    instance,
    ['useCart', contextKey].join('-')
  );

  const isProceedBillingButtonDisabled: Ref<boolean> = storage.getOrSave(
    'isProceedBillingButtonDisabled',
    ref(false)
  );

  const isCartMerged: Ref<boolean> = storage.getOrSave(
    'isCartMerged',
    ref(false)
  );

  const cartRef: Ref<Cart> = storage.getOrSave(
    'cartRef',
    ref({ ...cartObject })
  );

  const previousCartState: Ref<string> = storage.getOrSave(
    'previousCartState',
    ref('')
  );

  const outOfStockList: Ref<CartLineItemOutOfStock[]> = storage.getOrSave(
    'outOfStockList',
    ref([])
  );
  /**
   * LocalStorage do we need brand?
   */
  const cartId: Ref<string> = storage.getOrSave(
    'cartId',
    ref(ls.getItem(LS_CART_ID_KEY) || '')
  );

  const isAddItemRequestPending: Ref<boolean> = storage.getOrSave(
    'isAddItemRequestPending',
    ref(false)
  );

  const isCartLoading: Ref<boolean> = storage.getOrSave(
    'isCartLoading',
    ref(false)
  );

  const cartErrorDetails: Ref<ErrorDetailObject[]> = storage.getOrSave(
    'cartErrorDetails',
    ref(null)
  );

  const missingPhoneErrorDetail: Ref<ErrorDetailObject> = storage.getOrSave(
    'missingPhoneErrorDetail',
    ref(null)
  );

  const previousPageRoutePath: Ref<string> = storage.getOrSave(
    'previousPageRoutePath',
    ref('')
  );

  const isPageHasBeenChanged: Ref<boolean> = storage.getOrSave(
    'isPageHasBeenChanged',
    ref(false)
  );

  const cartItems = computed<CartLineItem[]>(() => {
    return (cartRef.value.items || []).map((product) => ({
      ...product,
      pdpUrlRelative: getRelativeUrl(product.pdpUrl),
      productImageURL: getProductImage(
        product,
        product.imageDeclined ? instance.$mediaUrlFallback() : null
      ),
    }));
  });

  const setCartId = (
    id: string,
    { suppressLocalStorageOverride } = { suppressLocalStorageOverride: false }
  ): void => {
    cartId.value = id;
    if (!suppressLocalStorageOverride) {
      ls.setItem('cartId', id);
    }
  };

  const isPatchCallRequired = (currentRoutePath) => {
    const pathsForPatchCall = [
      ROUTES.CART(),
      ROUTES.CHECKOUT_SHIPPING(),
      ROUTES.CHECKOUT_PAYMENT(),
    ];
    return pathsForPatchCall.some((path: string) =>
      currentRoutePath.includes(path)
    );
  };

  const initCart = async (data: CartInitializationObject) => {
    try {
      const response = await initCartAPI(data);
      if (response.data?.id) {
        setCartId(response.data.id);
      }
    } catch (e) {
      console.error(`Failed to init cart`);
    }
  };

  const loadCart = async ({
    isBackgroundRequest,
    isTemporary,
    inventorySupplyCheck,
  }: CartParams) => {
    const { tag } = trackRequest('useCart-loadCart', isBackgroundRequest);

    if (
      !cartId.value ||
      // When allowing shared cookies, we must always get the cart id from the server. This is because the user could have changed their id by authenticating, or any other action on the other page we are sharing cookies with
      (instance.$config.ALLOW_SHARED_COOKIES &&
        !instance.$cookies.get(useSignInToStore(instance).employeeCookieName))
    ) {
      await createNewCart({ isBackgroundRequest, isTemporary });
    }
    if (cartId.value) {
      await getCartObject(cartId.value, {
        isBackgroundRequest,
        inventorySupplyCheck,
      });
    }
    clearRequest(tag, isBackgroundRequest);
  };

  /**
   * Only for the purpose of keeping the cart alive on BE.
   * We skip assigning the cart to the ref here to avoid the need to fetch line items images over and over again.
   */
  const keepCartAlive = async () => {
    if (!cartId.value) return refreshCart();
    cartRef.value.flash = [];
    const query: string[] = [prepareLocale(localeCode())];
    if (isPatchCallRequired(window.location.pathname)) {
      query.push('isPatchRequired=true');
    }
    try {
      const response = await getCartObjectAPI(cartId.value, query.join('&'));
      await updateCart(response.data);
      cartRef.value.items = await setImagesDeclined(cartItems.value);
    } catch (error) {
      if (isBasketNotFoundError(error.response?.data)) {
        useCartStore().appendCartNotFoundFlash(
          error.response.data.errorDetails
        );
        refreshCart();
      }
    }
  };

  const createNewCart = async (
    { isBackgroundRequest, isTemporary }: BgRequestWithTempBasket = {
      isBackgroundRequest: false,
    }
  ) => {
    const { setAVSSkip } = useCheckout(instance);
    const { tag } = trackRequest('useCart-getCart', isBackgroundRequest);
    let cart;
    try {
      const query: string[] = [prepareLocale(localeCode())];
      if (isTemporary) {
        query.push(prepareTemporaryBasket());
      }
      const response = await getCartAPI(query.join('&'));
      if ([200, 201].includes(response.status)) {
        cart = response.data.id;
      }
      setAVSSkip(false);
    } catch (e) {
      const cartIdError:
        | string
        | null = customerBasketsQuotaExceededExceptionHandler(e.response?.data);
      if (
        (e.response?.status === 500 || e.response?.status === 400) &&
        cartIdError
      ) {
        cart = cartIdError;
      } else {
        displayErrorMessages(e);
      }
    }

    if (cart) {
      setCartId(cart, { suppressLocalStorageOverride: isTemporary });
    }
    clearRequest(tag, isBackgroundRequest);
    return cart || null;
  };

  const getCartObject = async (
    id: string,
    {
      isBackgroundRequest,
      inventorySupplyCheck,
      forceCartUpdate,
    }: BgRequestWithInventorySupplyCheck | any
  ) => {
    const { tag } = trackRequest('useCart-getCartObject', isBackgroundRequest);
    if (id === null || id === '' || typeof id === 'undefined') {
      id = ls.getItem(LS_CART_ID_KEY);
    }

    try {
      isCartLoading.value = true;
      const queryParamsObject = {
        locale: localeCode(),
        inventorySupplyCheck,
        isPatchRequired: isPatchCallRequired(window.location.pathname)
          ? true
          : undefined,
      };
      const response = await getCartObjectAPI(
        id,
        prepareQuery(queryParamsObject)
      );
      await updateCart(response.data, forceCartUpdate);
    } catch (e) {
      console.error(`Failed to fetch cart with ID: ${id}.`, e);
      resetCart();
    } finally {
      isCartLoading.value = false;
    }
    clearRequest(tag, isBackgroundRequest);
  };

  const getProductId = (product) => {
    let productId = '';
    if (product.saveForLater) {
      productId = product.productId;
    }

    if (product.upsell) {
      // product from upsell component on cart page
      productId = product.id;
    } else if (isCustomsProduct(product)) {
      productId = product.sku || product.productId || product.id;
    } else {
      // regular product
      productId = product.sku || product.productId;
    }
    return productId;
  };

  const mapProductForAddToCart = async (
    product
  ): Promise<AddToCartPayloadItem> => {
    const { employeeConnected, employeeDetails } = useSignInToStore(instance);
    let storeAssociateData: EmployeeDetails;
    let recipeId: string;
    let saveForLater: SaveForLaterItemRequest;
    // product from save for later
    if (product.saveForLater) {
      saveForLater = product.saveForLater;
    }
    if (isCustomsProduct(product)) {
      recipeId = product.customsRecipeID || product.recipe || product.recipeId;
    }

    if (employeeConnected.value) {
      storeAssociateData = { ...employeeDetails.value };
    }

    const getPropObject = (target, fieldName) => {
      if (target[fieldName] !== undefined)
        return { [fieldName]: target[fieldName] };
      return undefined;
    };

    return {
      cartId: cartId.value,
      productId: getProductId(product),
      qty: product.qty ?? parseInt(product.quantity?.value ?? product.quantity),
      sku: product.sku,
      maxQty:
        parseInt(product.variant?.stock?.quantity ?? product.maxQty) || 100,
      ...(await buildItemPDPDetails(product as Product, recipeId)),
      ...getPropObject({ recipeId }, 'recipeId'),
      ...getPropObject(product, 'precreatedCustomsCode'),
      ...getPropObject({ saveForLater }, 'saveForLater'),
      storeAssociateData,
      ...getPropObject(product, 'storeId'),
    };
  };

  const validateSizeIsSelected = (product): void => {
    if (product.size) return;
    // used for size select validation on PDP and QuickShop
    product.validation = {
      ...product.validation,
      missingSize: true,
    };
  };

  const createCartAddItem = async (
    payload: any,
    {
      isTemporary = false,
      queryParamsObject = {},
    }: TempBasket & { queryParamsObject?: Record<string, any> } = {}
  ) => {
    try {
      let hasQueryParams = !!Object.keys(queryParamsObject).length;
      const queryParams = new URLSearchParams(queryParamsObject);
      if (isTemporary) {
        queryParams.set('bid', 'cookie');
        hasQueryParams = true;
      }

      return hasQueryParams
        ? await createCartAddItemAPI(payload, {
            query: queryParams.toString(),
          })
        : await createCartAddItemAPI(payload);
    } catch (e) {
      const cartIdError:
        | string
        | null = customerBasketsQuotaExceededExceptionHandler(e.response?.data);
      if (
        (e.response.status === 500 || e.response.status === 400) &&
        cartIdError
      ) {
        return cartIdError;
      } else {
        displayErrorMessages(e);
      }
    }
  };

  const generateCustomSku = async (
    product: any,
    recipeId: string,
    locale: string
  ) => {
    if (!product.size) return;
    // Extracted method from addItem method.
    const sku = await getCustomsSkuAPI({
      caid: instance.$customsCAID || instance.$env[getCustomsCaid()],
      size: product.size.value || product.size,
      baseUrl: instance.$env.CUSTOMS_SKU,
      locale,
      recipeId,
    });
    // Product SKU assignment rewritten in more clear way.
    const productSku = sku.data?.sku || product.sku;
    product.sku = productSku;
    product.productId = productSku;
  };

  const generateCustomsForProducts = async (products) => {
    const locale = instance.$i18n.locale;

    for (const product of products) {
      validateSizeIsSelected(product);

      if (isCustomsProduct(product)) {
        const recipeId =
          product.recipe || product.recipeId || product.customsRecipeID;
        await generateCustomSku(product, recipeId, locale);
      }
    }
  };

  const addItem = async (
    payload: any,
    {
      isTemporary = false,
      queryParamsObject = {},
    }: TempBasket & { queryParamsObject?: Record<string, any> } = {}
  ): Promise<boolean> => {
    useCartStore().clearINV408Flashes();
    isAddItemRequestPending.value = true;

    try {
      const products = convertToArray(payload);

      try {
        await generateCustomsForProducts(products);
      } catch (e) {
        if (
          Array.isArray(e.response?.data?.errorDetails) &&
          e.response?.data?.errorDetails?.length > 0
        ) {
          throw e;
        }

        const { getStaticTranslation } = useI18n(instance);

        throw {
          response: {
            ...(e.response || {}),
            data: {
              ...(e.response?.data || {}),
              errorDetails: [
                {
                  errorId: instance.$env.CUSTOMS_ERROR_CODE_PDP,
                  message: getStaticTranslation('muleSoftErrors')[
                    instance.$env.CUSTOMS_ERROR_CODE_PDP
                  ],
                },
              ],
            },
          },
        };
      }

      const productsToAdd = await Promise.all(
        products.map(mapProductForAddToCart)
      );

      const hasQueryParams = !!Object.keys(queryParamsObject).length;
      const queryParams = new URLSearchParams(queryParamsObject);

      let response = null;
      if (!cartId.value) {
        response = await createCartAddItem(productsToAdd, {
          isTemporary,
          queryParamsObject,
        });

        // Basket is created, item is added
        if (response.data?.id) {
          setCartId(response.data.id, {
            suppressLocalStorageOverride: isTemporary,
          });
        }

        // Basket already exists, item is not added
        if (typeof response === 'string') {
          setCartId(response, {
            suppressLocalStorageOverride: isTemporary,
          });
          productsToAdd.forEach((p) => {
            p.cartId = response;
          });
          response = hasQueryParams
            ? await addItemAPI(productsToAdd, {
                query: queryParams.toString(),
              })
            : await addItemAPI(productsToAdd);
        }
      } else {
        try {
          response = hasQueryParams
            ? await addItemAPI(productsToAdd, {
                query: queryParams.toString(),
              })
            : await addItemAPI(productsToAdd);
        } catch (err) {
          if (isBasketNotFoundError(err.response?.data)) {
            // previously saved cart ID is not valid any more, the cart has expired
            // we need to create a new one and add the item
            resetCart();
            response = await createCartAddItem(productsToAdd, {
              isTemporary: false,
              queryParamsObject,
            });
          } else {
            throw err;
          }
        }
      }

      if (response.status === 200) {
        // TODO: need response to include model, image, and link
        await updateCartItems(response.data);

        // set page change to update flash messages when customer adds product beyond cart page
        setPageChange();

        // get the last added product
        const lastAddedProduct = productsToAdd[productsToAdd.length - 1];

        const recentlyAdded = (response.data?.items || []).find(
          (p) => p.productId === lastAddedProduct?.productId
        );

        setRecentlyAddedProduct(recentlyAdded);

        return true;
      }

      return false;
    } catch (e) {
      const errorsDetails = getErrorDetails(
        e.response?.data.errorDetails,
        payload.productId
      );

      // necessary to display INV408 flash messages on the pdp page
      displayErrorMessages(e);
      instance.$log.error(
        '[@useCart/index::addItem] Could not add item to the cart',
        { error: e }
      );

      // necessary to display INV408 flash messages on the cart page
      setINV408Flash(errorsDetails);

      return false;
    } finally {
      isAddItemRequestPending.value = false;
    }
  };

  const updateCartItems = async (data: Cart, forceCartUpdate = false) => {
    const {
      isBopisEnabled,
      isCheckoutRedesignEnabled,
    } = useFeatureFlagsStore();

    useCartStore().setCartItemsCache(cartRef.value.items);
    /** Reset notAddedItems ref from Shared Cart API when items subtotal changed */
    // TODO: GLOBAL15-38619 - extract useSharedCart sub-composable and improve cart mocks
    if (cartRef.value.totals?.itemTotal !== data.totals?.itemTotal) {
      clearNotAddedItems();
    }

    const newCartState = JSON.stringify(data);
    if (previousCartState.value === newCartState && !forceCartUpdate) {
      return;
    }

    previousCartState.value = newCartState;

    if (!data?.items) {
      cartRef.value = data;
      useCartStore().setCart(data);
      isProceedBillingButtonDisabled.value = true;
      updateCartFlashes(
        data.flash,
        isCheckoutRedesignEnabled && forceCartUpdate
      );
      return;
    }

    if (!data.flash) {
      data.flash = [];
    }

    /**
     * Overwrite customer notifications only if some new appears,
     * otherwise leave the old ones to avoid blinking of the notifications (they will be cleared on route change)
     * in the case if there is no new and old notifications create empty array
     */
    if (isCheckoutRedesignEnabled) {
      data.customerNotifications = data.customerNotifications || [];
    } else {
      // TODO: remove in GLOBAL15-56318
      data.customerNotifications =
        data.customerNotifications ||
        cartRef.value?.customerNotifications ||
        [];
    }

    let notifications = [];

    for (const product of data.items) {
      const isCustoms = isCustomsProduct(product);
      const recipeId = getRecipeId(product);

      product.image =
        product.productImageURL ||
        (await getCartImage(instance, {
          image_uri: instance.$mediaUrlGenerator({
            pid: product.masterId,
            productName: product.name,
          }) as string,
          fallbackImage: instance.$mediaUrlFallback(),
          productId: product.masterId,
          recipeId,
          isCustoms,
        }));
      product.productImageURL = product.image;

      if (!isBopisEnabled && product.shippingOptions) {
        product.shippingOptions = [];
      }

      if (product.customerNotifications) {
        notifications = [...notifications, ...product.customerNotifications];
      }
    }

    cartRef.value = data;
    cartRef.value.items = await setImagesDeclined(cartItems.value);

    useCartStore().setCart(data);
    usePromotionMessageStore().setApproachingDiscounts(data);

    updateCartFlashes(data.flash, isCheckoutRedesignEnabled && forceCartUpdate);
    cartLevelCustomerNotificationActions(notifications);

    isProceedBillingButtonDisabled.value = false;
  };

  const deleteItem = async (productId: string) => {
    const query: string[] = [prepareLocale(localeCode())];
    try {
      const response = await deleteItemAPI(
        {
          cartId: cartId.value,
          itemId: productId,
        },
        query.join('&')
      );
      if (response.status === 200) {
        updateCartItems(response.data);
      }
      return response;
    } catch (err) {
      if (isBasketNotFoundError(err.response?.data)) {
        resetCart();
      } else {
        displayErrorMessages(err);
      }
    }
  };

  const swapItem = async (oldProduct: CartLineItem, newProduct: Product) => {
    if (!cartId.value) {
      await loadCart({
        isBackgroundRequest: false,
        isTemporary: false,
        inventorySupplyCheck: true,
      });
    }

    try {
      const details = await buildItemPDPDetails(newProduct);
      const response = await swapItemAPI({
        cartId: cartId.value,
        oldItem: {
          itemId: oldProduct.id,
        },
        newItem: {
          productId: newProduct.sku,
          qty: newProduct.quantity,
          maxQty: newProduct.variant.stock.quantity,
          ...details,
        },
      });
      if (response.status === 200) {
        updateCartItems(response.data);
        return true;
      }
    } catch (e) {
      displayErrorMessages(e);
      return false;
    }
  };

  const resetCart = (
    { suppressLocalStorageOverride } = {
      suppressLocalStorageOverride: false,
    }
  ) => {
    const { setAVSSkip } = useCheckout(instance);
    cartRef.value = cartObject;
    cartId.value = '';
    previousCartState.value = '';
    setAVSSkip(false);
    if (!suppressLocalStorageOverride) {
      ls.removeItem(LS_CART_ID_KEY);
    }
  };

  const refreshCart = async () => {
    resetCart();
    await loadCart({
      isBackgroundRequest: false,
      isTemporary: false,
      inventorySupplyCheck: false,
    });
  };

  const updateItem = async (
    updateItemObject: UpdateItemObject,
    showErrorInNotification = true,
    query = null
  ) => {
    // Custom product modify maxQty to allow qty increase;
    // TODO: should be moved to SFCC
    if (updateItemObject.recipeId) {
      updateItemObject.maxQty = 100;
    }

    useCartStore().clearINV408Flashes();

    try {
      const response = await updateItemAPI(
        {
          ...mapUpdateItemRequestObject(updateItemObject, cartId.value),
          items: cartRef.value.items
            .filter((item) => item.productId !== updateItemObject.productId)
            .map((item) => {
              const shippingMethodForItem = cartRef.value.shippingMethods.find(
                (method) => method.shippingId === item.shippingId
              );
              return mapUpdateItemRequestObject(
                {
                  ...item,
                  ...(shippingMethodForItem.storeId !== undefined && {
                    storeId: shippingMethodForItem.storeId,
                  }),
                },
                cartId.value
              );
            }),
        },
        query
      );
      if (response.status === 200) {
        await updateCartItems(response.data);
      }
    } catch (err) {
      const errorsDetails = getErrorDetails(
        err.response.data.errorDetails,
        updateItemObject.productId
      );
      if (!showErrorInNotification) {
        cartErrorDetails.value = errorsDetails;
      } else {
        displayErrorMessages(err);
      }

      setINV408Flash(errorsDetails);

      return false;
    }
    return true;
  };

  // TODO: GLOBAL15-38458
  const getCartErrorDetail = computed(() => {
    if (!cartErrorDetails.value?.length) return;
    const errorDetail = cartErrorDetails.value[0];
    let messageFromCms: string = '';
    if (errorDetail.errorMessageId === 'INV408') {
      messageFromCms = getINV408ErrorMessageById(errorDetail, messageFromCms);
    }
    errorDetail.message = messageFromCms;
    return errorDetail;
  });

  const setPageChange = () => {
    const instanceRoutePath = instance.$root?.$route?.path;
    if (!previousPageRoutePath.value) {
      previousPageRoutePath.value = instanceRoutePath ? instanceRoutePath : '';
    }
    if (previousPageRoutePath.value !== instanceRoutePath) {
      isPageHasBeenChanged.value = true;
      previousPageRoutePath.value = instanceRoutePath;
    }
  };

  const updateCart = async (data, forceCartUpdate = false) => {
    setPageChange();
    // set cartRef.value with modded cart properties
    await updateCartItems(data, forceCartUpdate);
  };

  const isProductQuantityWasReduced = (product: CartLineItem) => {
    const productRelatedFlashMessage = cartRef.value.flash.find((flash) => {
      return flash.details
        ? flash.details.productId === product.productId
        : false;
    });
    if (!productRelatedFlashMessage) {
      return false;
    }
    return productRelatedFlashMessage.code === FlashErrorType.InventoryMissing;
  };

  const prepareOrder = async ({ refetchCart } = { refetchCart: true }) => {
    const { tag } = trackRequest('useCart-prepareOrder');
    try {
      const response = await prepareOrderAPI({
        cartId: cartId.value,
      });
      await updateCart(response.data);
      return response;
    } catch (e) {
      e.response?.data?.errorDetails?.forEach((error) => {
        if (error.errorId === 'INV700') {
          instance.$root.$router.push(localePath(ROUTES.CART()));
        }
      });

      displayErrorMessages(e);
    } finally {
      clearRequest(tag);
      refetchCart &&
        (await getCartObject(cartId.value, {
          isBackgroundRequest: false,
          inventorySupplyCheck: true,
          forceCartUpdate: true,
        }));
    }
  };

  const standardCartProducts = computed(() =>
    cartItems.value
      .filter((p) => !p.recipeId && !(p as any).savedForLater)
      .map((product) => ({
        ...product,
        totalGiftBoxPrice: getItemGiftBoxPrice(product),
      }))
  );
  const customCartProducts = computed(() =>
    cartItems.value.filter((p) => p.recipeId && !(p as any).savedForLater)
  );

  // TODO: GLOBAL15-38458
  const checkAndRemoveOutOfStockItems = (isPageChanged) => {
    if (!outOfStockFlashErrors.value || isPageChanged) return;
    outOfStockFlashErrors.value.forEach((flashError) => {
      const isInList = outOfStockList.value.find(
        (p) => p.id === flashError.details.productId
      );
      if (!isInList) {
        const cartLineItemOutOfStock: CartLineItemOutOfStock = {
          id: flashError.details.productId,
          name: flashError.details.productName,
          productImageURL: flashError.details.productImageURL,
          pdpUrl: flashError.details.productSlug
            ? `${window.location.origin}/${localeCode()}${
                flashError.details.productSlug
              }`
            : '',
          variants: [
            {
              id: flashError.details.color,
              code: 'color',
              label: 'Color',
              value: flashError.details.color,
            },
            {
              id: flashError.details.size,
              code: 'size',
              label: 'Size',
              value: flashError.details.size,
            },
          ],
        };
        if (flashError.details.length)
          cartLineItemOutOfStock.variants.push({
            id: flashError.details.color,
            code: 'length',
            label: 'Length',
            value: flashError.details.length,
          });
        outOfStockList.value.push(cartLineItemOutOfStock);
      }
    });
  };

  const standardCartProductsWithDeletedItems = computed(() => {
    return getProductListWithDeletedItems(
      standardCartProducts.value,
      deletedFromStandardCartProductsList.value
    );
  });

  const updateStandardCartProductsList = ({ index, product }) => {
    deletedFromStandardCartProductsList.value.push({
      index,
      product: { ...product, isDeleted: true },
    });
  };

  const isSomeItemCustom = computed(() => {
    return cartItems.value.some((product) => product.isCustoms);
  });

  const hasItems = computed(() => cartItems.value.length > 0);

  const hasShippingItems = computed(() => {
    return itemsToShip.value ? itemsToShip.value.length > 0 : false;
  });

  const getSmartGiftCart = async (giftedCartId: string): Promise<void> => {
    try {
      if (!cartId.value)
        await createNewCart({ isBackgroundRequest: false, isTemporary: false });
      const getSmartGiftCartResponse = await getSmartGiftCartAPI({
        smartGiftBasketId: giftedCartId,
        cartId: cartId.value,
      });
      if ([200, 201].includes(getSmartGiftCartResponse.status)) {
        setCartId(getSmartGiftCartResponse.data.id);
        await loadCart({
          isBackgroundRequest: false,
          isTemporary: false,
          inventorySupplyCheck: true,
        });
      }
    } catch (e) {
      displayErrorMessages(e);
    }
  };

  const setFamilyReward = async (selectedReward: number) => {
    if (cartId.value) {
      try {
        const response = await setFamilyRewardAPI(cartId.value, selectedReward);
        updateCartItems(response.data, true);
      } catch (e) {
        displayErrorMessages(e);
      }
    }
  };

  const isGoToBillingButtonDisabled = computed(() => {
    const { isPoAddress } = useCheckout(instance);
    return (
      isPoAddress.value ||
      isProceedBillingButtonDisabled.value ||
      !hasItems.value
    );
  });

  const setMissingPhoneErrorDetail = (value: ErrorDetailObject) => {
    missingPhoneErrorDetail.value = value;
  };

  const totalItems = computed(() => cartRef.value.totalItems || 0);
  const totalCart = computed(() => cartRef.value.totals.total);
  const subTotal = computed(() => cartRef.value.totals.itemTotal || 0);
  const cartShippingMethods = computed(() => cartRef.value.shippingMethods);
  const billingAddress = computed(() => cartRef.value.billingAddress);

  const giftedBasketId = computed(() => cartRef.value?.giftedBasketId || null);

  return (_instance: ComponentInstance) => {
    instance = _instance;

    if (!inited) {
      inited = true;
      ({ overridePrice, resetPrice, updatePrice, isPriceAdjusted } = usePrice(
        instance,
        { updateCartItems },
        contextKey
      ));
      ({ checkGiftCardBalance } = useGiftCards(instance));
      ({ initPayPalSession, updatePayPalSession } = usePayPal(instance, {
        cartId,
      }));
      ({
        saveGuestCart,
        isCartMergeNotificationVisible,
        clearGuestCart,
      } = useGuestCart({
        cartItems,
        totalItems,
      }));
      ({ setQuickshopSizeChart, quickshopSizeChart } = useQuickShop(
        instance,
        contextKey
      ));
      ({
        appliedGiftCards,
        appliedRewards,
        autoAppliedPromotions,
        payFullWithGiftCard,
        productsExcludedFromAthletes,
      } = usePromotions({ cartRef, cartItems }));
      ({
        itemsToShip,
        itemsForPickup,
        hasPickupItems,
        isPickupItem,
        isShipmentPickup,
        updateCartForBopis,
      } = usePickup(instance, {
        cartId,
        cartItems,
        updateCart,
        refreshCart,
        isPatchCallRequired,
      }));
      ({
        setShippingMethod,
        getShippingMethods,
        defaultShippingAddress,
        shippingGroups,
        getShippingGroupFormattedPrice,
      } = useShipping(
        instance,
        {
          cartRef,
          isProceedBillingButtonDisabled,
          cartId,
          loadCart,
          isShipmentPickup,
          updateCartItems,
        },
        contextKey
      ));
      ({
        hasFlashErrorsToDisplay,
        getProductRelatedFlashMessages,
        updateCartFlashesFromError,
        outOfStockFlashErrors,
        updateCartFlashes,
      } = useFlashErrors(
        instance,
        {
          cartRef,
          isPageHasBeenChanged,
        },
        contextKey
      ));
      ({
        // TODO: GLOBAL15-56318 Remove minicart subcomposable references
        setMiniCart,
        setHideMiniCart,
        isMinicartHidden,
        isMinicartOpen,
      } = useMiniCart(instance, contextKey));
      ({
        clearCartLevelCustomerNotifications,
        cartLevelCustomerNotificationActions,
        updateCustomerNotifications,
        clearCartLineItemCustomerNotifications,
        cartLineItemCustomerNotifications,
      } = useCustomerNotifications(
        instance,
        isPageHasBeenChanged,
        cartRef,
        contextKey
      ));
      ({
        recentlyAddedProduct,
        setRecentlyAddedProduct,
      } = useRecentlyAddedProduct(instance, contextKey));
      ({ trackRequest, clearRequest } = useRequestTracker(instance));
      ({
        displayErrorMessages,
        getErrorDetails,
        getINV408ErrorMessageById,
      } = errorMessages(instance));
      ({
        getShareLink,
        showExpiredSharedCartError,
        applyShared,
        notAddedItems,
        clearNotAddedItems,
      } = useSharedCart(
        instance,
        {
          createNewCart,
          resetCart,
          updateCartItems,
          cartItems,
        },
        contextKey
      ));
      ({ buildItemPDPDetails } = usePDPDetails(instance));
      ({ getRelativeUrl } = useUrl(instance));
      ({ getCustomsCaid, setImagesDeclined } = useCustoms(instance));
      ({ localeCode, localePath } = useI18n(instance));
      ({
        addItem: addItemAPI,
        createCartAddItem: createCartAddItemAPI,
        deleteItem: deleteItemAPI,
        getCart: getCartAPI,
        getCartObject: getCartObjectAPI,
        getCustomsSku: getCustomsSkuAPI,
        getSmartGiftCart: getSmartGiftCartAPI,
        initCart: initCartAPI,
        prepareOrder: prepareOrderAPI,
        swapItem: swapItemAPI,
        updateItem: updateItemAPI,
        setFamilyReward: setFamilyRewardAPI,
      } = apiClientFactory(instance));
    }

    return {
      cart: cartRef,
      cartItems,
      initCart,
      loadCart,
      keepCartAlive,
      createNewCart,
      addItem,
      deleteItem,
      swapItem,
      quickshopSizeChart,
      setQuickshopSizeChart,
      totalItems,
      totalCart,
      subTotal,
      cartId: computed(() => cartId.value),
      setCartId,
      setShippingMethod,
      getShippingMethods,
      cartShippingMethods,
      billingAddress,
      updateItem,
      isMinicartOpen,
      setMiniCart,
      recentlyAddedProduct,
      setHideMiniCart,
      isMinicartHidden,
      isProceedBillingButtonDisabled,
      defaultShippingAddress,
      shippingGroups,
      updateCart,
      saveGuestCart,
      isCartMergeNotificationVisible,
      clearGuestCart,
      isCartMerged,
      initPayPalSession,
      updatePayPalSession,
      appliedGiftCards,
      appliedRewards,
      autoAppliedPromotions,
      checkGiftCardBalance,
      resetCart,
      refreshCart,
      payFullWithGiftCard,
      hasPickupItems,
      hasShippingItems,
      itemsForPickup,
      itemsToShip,
      isPickupItem,
      getProductId,
      getShareLink,
      applyShared,
      notAddedItems: readonly(notAddedItems),
      isAddItemRequestPending,
      cartErrorDetails,
      missingPhoneErrorDetail,
      setMissingPhoneErrorDetail,
      getCartErrorDetail,
      prepareOrder,
      standardCartProducts,
      customCartProducts,
      isCartLoading,
      standardCartProductsWithDeletedItems,
      updateStandardCartProductsList,
      deletedFromStandardCartProductsList,
      getShippingGroupFormattedPrice,
      overridePrice,
      resetPrice,
      updatePrice,
      isPriceAdjusted,
      outOfStockList,
      checkAndRemoveOutOfStockItems,
      outOfStockFlashErrors,
      getProductRelatedFlashMessages,
      updateCartFlashes,
      updateCartFlashesFromError,
      hasFlashErrorsToDisplay,
      getSmartGiftCart,
      isSomeItemCustom,
      updateCartForBopis,
      giftedBasketId,
      isProductQuantityWasReduced,
      isGoToBillingButtonDisabled,
      hasItems,
      previousPageRoutePath,
      cartLineItemCustomerNotifications,
      updateCustomerNotifications,
      cartLevelCustomerNotificationActions,
      clearCartLevelCustomerNotifications,
      clearCartLineItemCustomerNotifications,
      showExpiredSharedCartError,
      buildItemPDPDetails,
      productsExcludedFromAthletes,
      updateCartItems,
      setFamilyReward,
    };
  };
};

export const useCart = useScopedCart();
export const useApplePayPdpCart = useScopedCart(ApplePayContext.PDP);
