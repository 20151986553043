import { Ref } from '@nuxtjs/composition-api';
import { CartLineItem, Product } from '@vf/api-client';
import { CMDataSource, PdpGalleryImage } from '../utils/dataSources/types';
import { ProductVariant } from '@vf/api-client/src/types';

export type ProductConfiguration = {
  size?: string;
  color?: string;
  length?: string;
  quantity?: number;
  zip?: string;
  width?: string;
};

export type UseProductStorage = {
  loading: Ref<boolean>;
  pdpImages: Ref<PdpGalleryImage[]>;
  product: Ref<Product>;
  oldProduct: Ref<CartLineItem>;
  pdpGalleryDataSources: Ref<CMDataSource[]>;
  findInStoreQuickShopVisible: Ref<boolean>;
  contextProducts: Ref<ProductContext>;
  isQuickShopOpen: Ref<boolean>;
  quickShopOpenProductId: Ref<string>;
  isUnavailableSizeHovered: Ref<boolean>;
  isNotifyMeFormVisible: Ref<boolean>;
  priceData: Ref<PriceData>;
  attributesData: Ref<AttributesData>;
  variantsData: Ref<VariantsData>;
};

export type AttributesData = {
  attributeCodes: string[];
  attributeGroupingPrices: null | string;
  attributeOptionsAttName: AttributeOptionsAttName;
  isAttributePriceVariationClicked: boolean;
  isFutureProduct: boolean;
  isInStock: boolean;
  isSoldOut: boolean;
  isOutOfStock: boolean;
  showSizeSelector: boolean;
};

export type AttributeOptionsAttName = {
  [attributeName: string]: string;
};

export type VariantsData = {
  areAllVariantsInStock: boolean;
  areAllVariantsOutOfStock: boolean;
  remappedVariantsByPriceAndAttributes: any;
  sortedVariantsByCurrentPrice: ProductVariant[];
  sortedVariantsByOriginalPrice: ProductVariant[];
};

export type PriceRangeData = {
  lowest: number;
  isLowestPriceDiscounted: boolean;
  highest: number;
  isHighestPriceDiscounted: boolean;
  currency: string;
};

export type PriceData = {
  selectedVariant: {
    currentPrice: number;
    originalPrice: number;
  };
  specialPrice: number;
  currency: string;
  hasSpecialPrice: boolean;
  showPriceRange: boolean;
  priceRange: PriceRangeData;
};

export type ProductContext = {
  [slug: string]: unknown;
};

export type GetProductDetailsOptions = {
  isBackgroundRequest?: boolean;
  loadImages?: boolean;
  saveVariation?: boolean;
  saveOnlyColor?: boolean;
  configuredColor?: string;
  configuredVariant?: string;
  configuredSize?: string;
  showLoadingState?: boolean;
  inventoryLoading?: Ref<boolean>;
};

export const COMING_SOON_BADGE = 'coming soon';
