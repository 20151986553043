import {
  CMChannel,
  CMPlaceholder,
  CmsSite,
  ProfileSmsSubscriptionTranslations,
} from '@vf/api-contract';
import {
  extractLocalSettingsExpandedLinks,
  extractRichText,
  generateLinkFromTarget,
  getTranslation,
  getValidationConfigValues,
} from './utils';
import { ComposableContext } from '../types';

export const smsSubscriptionProfile = (
  data: CMPlaceholder,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  const [enrollPage]: [CMChannel] = extractLocalSettingsExpandedLinks(
    data,
    'enrollPage'
  );

  const translations: ProfileSmsSubscriptionTranslations = {
    title: getTranslation(data, 'title', '$SMS Preferences$'),
    subtitle: getTranslation(data, 'subtitle', {
      subscribed: '$You are receiving notifications at$',
    }),
    unsubscribed: extractRichText(
      data,
      'unsubscribed',
      '$Add your mobile number to receive notifications via text from The North Face.$',
      cmsBaseUri,
      context,
      siteConfiguration
    ),
    checkboxLabel: extractRichText(
      data,
      'checkboxLabel',
      '$Click here to receive texts about your orders and offerings from The North Face.$',
      cmsBaseUri,
      context,
      siteConfiguration
    ),
    termsAndConditions: {
      ...getTranslation(data, 'termsAndConditions', {
        title: '$Terms & Conditions$',
        ctaLabel: '$I Accept$',
      }),
      body: extractRichText(
        data,
        'tcRichText',
        '$I expressly consent to receive recurring text messages from The NorthFace.$',
        cmsBaseUri,
        context,
        siteConfiguration
      ),
    },
    confirmationTooltip: getTranslation(
      data,
      'confirmationTooltip',
      '$Please reply "Yes" to 84243 to complete SMS Registration.$'
    ),
    notifications: getTranslation(data, 'notifications', {
      unsubscribe: '$You have unsubscribed from SMS notifications.$',
    }),
    phoneLabel: getTranslation(data, 'phoneLabel', '$Phone Number$'),
    validationMessages: {
      phoneError: getTranslation(
        data,
        'validationMessages.phoneError',
        '$A valid phone number is required$'
      ),
    },
    saveButtonText: getTranslation(
      data,
      'saveButtonText',
      '$Save SMS Preferences$'
    ),
    required: getTranslation(data, 'required', '$Required$'),
    subscribeCTA: getTranslation(data, 'subscribeCTA', '$Subscribe$'),
    unsubscribeCTA: getTranslation(data, 'unsubscribeCTA', '$Unsubscribe$'),
  };

  return {
    component: 'account/ProfileSmsSubscription',
    props: {
      translations,
      validationConfig: getValidationConfigValues(data),
      enrollPage: generateLinkFromTarget(
        enrollPage,
        context,
        siteConfiguration,
        cmsBaseUri
      ),
    },
  };
};
