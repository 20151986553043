import { useI18n } from '../../useI18n';
import { useRouting } from '../../useRouting';
import { ComponentInstance } from '../../types';
import { removeDomain } from '@vf/shared/src/utils/helpers';

const isExternalUrl = (url = ''): boolean => {
  return url.startsWith('http://') || url.startsWith('https://');
};

export const formatUrlName = (name?: string): string =>
  encodeURIComponent(
    (name || 'notitle').trim().toLowerCase().replace(/\s+/g, '-')
  );

export const normalizeUrl = (
  instance: ComponentInstance,
  url: string,
  qs?: Record<string, string>
): string => {
  if (!url) {
    return null;
  }

  const { localePath } = useI18n(instance);
  const { getPathWithoutLocalization } = useRouting(instance);
  const path = isExternalUrl(url) ? removeDomain(url) : url;
  const processedUrl = getPathWithoutLocalization(path.toLowerCase());

  let qsStr = Object.keys(qs || {})
    .filter((key) => !!qs[key] && !path.includes(`${key}=`))
    .map((key) => `${key}=${encodeURIComponent(qs[key])}`)
    .join('&');

  if (qsStr) {
    qsStr = processedUrl.includes('?') ? `&${qsStr}` : `?${qsStr}`;
  }
  if (instance.$config.queryParametersCaseSensitive) {
    return localePath(
      `/${processedUrl.toLowerCase()}${qsStr || ''}`
        .replace(/\/{2,}/g, '/')
        .replace(/recipe/i, 'recipe')
    );
  } else {
    return localePath(
      `/${processedUrl}${qsStr || ''}`.replace(/\/{2,}/g, '/')
    ).toLowerCase();
  }
};
