import type { CardType, TokenizedCreditCard } from '@vf/api-contract';
import type { ComponentInstance } from '../types';

import { computed, ref } from '@vue/composition-api';
import { CheckoutContext } from '@vf/api-contract';
import { load as loadScript } from '@vf/shared/src/utils/helpers/load-script';
import { usePaymentStore } from '../store/payment';
import { useCardStore } from '../store/card';
import { useOrderStore } from '../store/order';
import { useRequestTracker } from '../useRequestTracker';
import { useOrders } from '../useCheckout/composables/useOrders';
import { useSuccessfulOrder } from '../useSuccessfulOrder';
import { useCheckoutStore } from '../store/checkoutStore';
import useNotification from '../useNotification';
import useValidators from '../useValidators';

const deviceFingerprintUrl = 'https://live.adyen.com/hpp/js/df.js?v=20171130';

interface CreditCardFieldStatus {
  dirty: boolean;
  empty: boolean;
  valid: boolean;
}

export const useAdyenPaymentProvider = (instance: ComponentInstance) => {
  const paymentStore = usePaymentStore(instance);
  const cardStore = useCardStore();
  const orderStore = useOrderStore();
  const checkoutStore = useCheckoutStore();

  const { trackRequest, clearRequest } = useRequestTracker(instance);
  const { patchOrder } = useOrders(instance);
  const { addNotification } = useNotification(instance);

  const creditCardInputStatus = ref<CreditCardFieldStatus>({
    dirty: false,
    empty: true,
    valid: false,
  });
  const expirationDateInputStatus = ref<CreditCardFieldStatus>({
    dirty: false,
    empty: true,
    valid: false,
  });
  const securityCodeInputStatus = ref<CreditCardFieldStatus>({
    dirty: false,
    empty: true,
    valid: false,
  });

  const getValidationText = (scope: string) =>
    instance.$t(`creditCardMicroform.validation_messages.${scope}`) as string;

  const { validate, validationFields } = useValidators(
    () => ({
      // these are just needed to comply with `useValidators`'s contract, empty values are intentional and never used
      cardNumber: '',
      securityCode: '',
      expirationDate: '',
    }),
    ref({
      cardNumber: [
        () => {
          if (creditCardInputStatus.value.empty)
            return getValidationText('required');
        },
        () => {
          if (
            creditCardInputStatus.value.dirty &&
            !creditCardInputStatus.value.valid
          )
            return getValidationText('cardNumber');
        },
      ],
      expirationDate: [
        () => {
          if (expirationDateInputStatus.value.empty)
            return getValidationText('required');
        },
        () => {
          if (
            expirationDateInputStatus.value.dirty &&
            !expirationDateInputStatus.value.valid
          )
            return getValidationText('dateFormat');
        },
      ],
      securityCode: [
        () => {
          if (securityCodeInputStatus.value.empty)
            return getValidationText('required');
        },
        () => {
          if (
            securityCodeInputStatus.value.dirty &&
            !securityCodeInputStatus.value.valid
          )
            return getValidationText('securityCodeFormat');
        },
      ],
    })
  );

  const processDeviceFingerprint = (elementId: string) => {
    const fingerprintInputId = 'device-fingerprint';

    // create hidden input to fill it with device fingerprint token
    const hiddenInput = document.createElement('input');
    hiddenInput.setAttribute('id', fingerprintInputId);
    hiddenInput.setAttribute('type', 'hidden');
    hiddenInput.setAttribute('name', fingerprintInputId);

    document.querySelector(elementId)?.appendChild(hiddenInput);

    window.dfSet?.(fingerprintInputId); // set finger print id on hidden input
    paymentStore.deviceFingerprint = hiddenInput.value;
  };

  function getAdditionalData() {
    return {
      deviceFingerprint: paymentStore.deviceFingerprint,
      paymentData: JSON.stringify(paymentStore.data),
    };
  }

  async function getCardData(): Promise<TokenizedCreditCard> {
    return {
      cardType: cardStore.card.cardType,
      paymentData: JSON.stringify(paymentStore.data),
    };
  }

  const mapPaymentTypeVariant = (
    variants: string[]
  ): 'DEBIT' | 'PREPAID' | 'CREDIT' => {
    const [variant = ''] = variants || [];
    if (variant.toLowerCase().includes('debit')) return 'DEBIT';
    if (variant.toLowerCase().includes('prepaid')) return 'PREPAID';
    return 'CREDIT';
  };

  async function render() {
    const {
      meta: {
        type,
        brands,
        hasHolderName,
        holderNameRequired,
        minimumExpiryDate,
      },
    } = paymentStore.session;
    // TODO correct id https://digital.vfc.com/jira/browse/GLOBAL15-88940
    const elementId = '#credit-card-microform';

    processDeviceFingerprint(elementId);

    paymentStore.service
      .create('securedfields', {
        type,
        brands,
        hasHolderName,
        holderNameRequired,
        minimumExpiryDate,
        onChange(state) {
          const {
            valid: {
              encryptedCardNumber: validCardNumber,
              encryptedExpiryMonth: validExpiryMonth,
              encryptedExpiryYear: validExpiryYear,
              encryptedSecurityCode: validSecurityCode,
            },
            errors: {
              encryptedCardNumber: invalidCardNumber,
              encryptedExpiryDate: invalidExpiryDate,
              encryptedSecurityCode: invalidSecurityCode,
            },
          } = state;

          creditCardInputStatus.value = {
            dirty: invalidCardNumber && !invalidCardNumber.isValid,
            empty: !(validCardNumber || invalidCardNumber),
            valid: validCardNumber,
          };

          expirationDateInputStatus.value = {
            dirty: invalidExpiryDate && !invalidExpiryDate.isValid,
            empty: !(invalidExpiryDate || validExpiryMonth || validExpiryYear),
            valid: validExpiryMonth && validExpiryYear,
          };

          securityCodeInputStatus.value = {
            dirty: invalidSecurityCode && !invalidSecurityCode.isValid,
            empty: !(validSecurityCode || invalidSecurityCode),
            valid: validSecurityCode,
          };
        },
        onFocus({ fieldType, focus }) {
          // for some reason onChange event is triggered after onFocus, hence we neet this setTimeout hack to ensure that we validate updated fields.
          setTimeout(() => {
            // toggle input wrapper class to adhere to actual styling
            const wrapper = document.querySelector(`[data-cse=${fieldType}]`)
              ?.parentElement;
            wrapper?.classList?.[focus ? 'add' : 'remove']?.(
              'vf-flex-microform__input-wrapper--focus'
            );
            if (focus) return;
            // validates & marks field as dirty when focus leaves field
            switch (fieldType) {
              case 'encryptedCardNumber':
                creditCardInputStatus.value.dirty = true;
                validate('cardNumber');
                break;
              case 'encryptedExpiryDate':
                expirationDateInputStatus.value.dirty = true;
                validate('expirationDate');
                break;
              case 'encryptedSecurityCode':
                securityCodeInputStatus.value.dirty = true;
                validate('securityCode');
                break;
            }
          }, 75);
        },
        onValid(state) {
          paymentStore.data = state.data;
          cardStore.card = {
            maskedNumber: cardStore.cardNumber,
            cardType: cardStore.cardType.toUpperCase() as CardType,
          };
        },
        onFieldValid({ fieldType, endDigits }) {
          if (fieldType === 'encryptedCardNumber')
            cardStore.cardNumber = `${endDigits}`;
        },
        onBrand({ brand }) {
          cardStore.cardType = brand;
        },
        onBinValue({ binValue }) {
          orderStore.bin = { ...orderStore.bin, binValue };
        },
        onBinLookup({ paymentMethodVariants }) {
          orderStore.bin = {
            ...orderStore.bin,
            type: mapPaymentTypeVariant(paymentMethodVariants),
          };
        },
      })
      .mount(elementId);
  }

  async function load(useDeviceFingerprint = true) {
    const { tag } = trackRequest('CreditCardForm-loadAdyen');
    try {
      if (useDeviceFingerprint) {
        await loadScript(deviceFingerprintUrl);
      }
      await loadScript(instance.$config.ADYEN_URLS.jsBaseUrl, null, null, {
        integrity:
          'sha384-O8p0CLZyOw1jkmYN7ZwJxWzd+sDYRFGpLEffqc+dKye24gFImbU72did4PC7ysTY',
        crossorigin: 'anonymous',
      });
    } catch (error) {
      instance.$log.error('Adyen script loading failed', error);
    } finally {
      clearRequest(tag);
    }
  }

  async function initService() {
    if (paymentStore.service) return;

    const {
      meta: { environment, clientKey },
      locale,
    } = paymentStore.session;

    if (!clientKey) throw new Error('Adyen client key is missing');

    const paymentMethodsConfiguration = {
      card: {
        styles: {
          base: {},
          error: {},
          placeholder: {
            color: 'transparent',
            caretColor: 'black',
          },
        },
      },
    };

    paymentStore.service = await window.AdyenCheckout({
      locale,
      environment,
      clientKey,
      paymentMethodsConfiguration,
      async onAdditionalDetails(state) {
        orderStore.challenge3ds = undefined;

        checkoutStore.order = await patchOrder(state).catch((error) => {
          instance.$log.error(
            '[@usePaymentProvider/adyen] Error while patching order',
            { error }
          );
        });

        if (checkoutStore.order?.orderNumber) {
          return useSuccessfulOrder(instance);
        }

        checkoutStore.setCurrentStep(CheckoutContext.Payment);
        addNotification({
          message: '',
          type: 'danger',
        });
      },
    });
  }

  return {
    form: {
      validate,
      validationFields,
    },
    getAdditionalData,
    getCardData,
    initService,
    isExpiryExternal: true,
    load,
    preparePaymentData: async () => {
      creditCardInputStatus.value.dirty = true;
      expirationDateInputStatus.value.dirty = true;
      securityCodeInputStatus.value.dirty = true;
      return validate();
    },
    render,
    showCreditCardNumberSuccess: computed(
      () => creditCardInputStatus.value.valid
    ),
    showExpirationDateSuccess: computed(
      () => expirationDateInputStatus.value.valid
    ),
    showSecurityNumberSuccess: computed(
      () => securityCodeInputStatus.value.valid
    ),
    unload: async () => void 0,
    unmount: () =>
      paymentStore.service?.components.forEach((component) =>
        component.unmount()
      ),
    reset: () => void 0,
  };
};
