import { ComponentInstance, Filter } from '../types';

/**
 * encodeURIComponent method does the work as expected,
 * but then vue router is trying to encode it by itself
 * and it results with cutting off string after = sign in filters,
 * for example: 3.5 Boys = 5.0 Women filter after vue router decoding equals:
 * 3.5 Boys =
 * So we're replacing = sign with something else
 */
const equalSignMapping = {
  source: '=',
  replacement: '__',
};

const FILTER_SEPARATOR = '&|';

export const sortFilters = (filters: Filter[]) => {
  const sortFn = (a, b) =>
    a.code.localeCompare(b.code) || a.value.localeCompare(b.value);

  return [...filters].sort(sortFn);
};

export const decodeUrl = (filtersData?: string | string[]): Filter[] => {
  if (filtersData) {
    if (Array.isArray(filtersData)) {
      return filtersData.reduce((mappedParams, el) => {
        const mappedParam = mapFilterSearchParamIntoFilterObject(el);
        if (mappedParam) mappedParams.push(mappedParam);
        return mappedParams;
      }, []);
    } else {
      const mappedParam = mapFilterSearchParamIntoFilterObject(filtersData);
      return mappedParam ? [mappedParam] : [];
    }
  }
  return [];
};

export const encodeFiltersQueryParams = (filters: Filter[]): any => {
  return {
    f: filters.reduce((encodedValue, filter, index) => {
      if (index) {
        encodedValue += FILTER_SEPARATOR;
      }
      encodedValue += `${filter.code},${encodeUrlValue(filter.value)}`;

      return encodedValue;
    }, ''),
  };
};

const mapFilterSearchParamIntoFilterObject = (urlFilter: string): Filter => {
  const [code, value] = urlFilter.split(',');
  if (!value) return;
  return {
    code,
    value: decodeUrlValue(value),
  };
};

const encodeUrlValue = (value: string) => {
  return encodeURIComponent(
    value.replace(equalSignMapping.source, equalSignMapping.replacement)
  );
};

const decodeUrlValue = (value: string) => {
  return decodeURIComponent(
    value.replace(equalSignMapping.replacement, equalSignMapping.source)
  );
};

export const decodeFilterParam = (instance) => {
  const f = instance.$root.$route.query?.f;
  let result;
  if (f) {
    if (Array.isArray(f)) {
      result = f;
    } else if (f.includes(FILTER_SEPARATOR)) {
      result = f.split(FILTER_SEPARATOR);
    } else {
      result = [f];
    }
  }

  return decodeUrl(result || f);
};

export const getFilterQueryLink = (
  instance: ComponentInstance,
  selectedFilters: Filter[],
  { code, value }: Filter
): { [key: string]: string | string[] | { q: string | string[] } } => {
  const currentFilters = decodeFilterParam(instance);
  const isSelected = selectedFilters.some(
    (it) => it.code === code && it.value === value
  );
  const skipSelected = (it: Filter) =>
    !(it.code === code && it.value === value);
  const filters = isSelected
    ? currentFilters.filter(skipSelected)
    : [...currentFilters, { code, value }];
  // Filters should be sorted alphabetically by facet and filter for SEO optimization
  const sortedFilters = sortFilters(filters);

  return {
    query: {
      q: instance.$root.$route.query.q,
      ...encodeFiltersQueryParams(sortedFilters),
    },
  };
};
