import { defineStore } from 'pinia';
import { ref } from '@vue/composition-api';
import { ApproachingDiscount, Cart, CartLineItem } from '@vf/api-client';

enum ApproachingPromotion {
  BounceBackVoucher = 'bounceBackVouchers',
  GiftWithPurchase = 'GWP',
}

const getGwpPromotionFromCartItems = (items: CartLineItem[]) => {
  return items.reduce((promotions, item) => {
    const gwpPromo = item.productPromotions?.find(({ promotionId }) =>
      promotionId.includes(ApproachingPromotion.GiftWithPurchase)
    );
    if (gwpPromo)
      promotions.push({
        earned: true,
        promotionId: gwpPromo.promotionId,
        calloutMsg: gwpPromo.calloutMsg || item.name,
      });

    return promotions;
  }, []);
};

export const usePromotionMessageStore = defineStore('promotionMessage', () => {
  const approachingDiscounts = ref<ApproachingDiscount[]>([]);

  return {
    approachingDiscounts,
    setApproachingDiscounts: (cartData: Cart) => {
      const {
        items,
        approachingDiscount = [],
        bounceBackVouchers = [],
      } = cartData;

      approachingDiscounts.value = [];

      approachingDiscount.forEach((approachingObject) => {
        approachingDiscounts.value.push({
          ...approachingObject,
        });
      });

      getGwpPromotionFromCartItems(items).forEach((gwpPromo) => {
        approachingDiscounts.value.push(gwpPromo);
      });

      bounceBackVouchers.forEach(() => {
        approachingDiscounts.value.push({
          promotionId: ApproachingPromotion.BounceBackVoucher,
          ...bounceBackVouchers[0],
        });
      });
    },
  };
});
