import type {
  Cart,
  CartLineItem,
  CartShippingGroup,
  CartTotals,
  PaymentInstruments,
  PickupPerson,
  PickupStore,
} from '@vf/api-client';
import { PaymentMethodCode } from '@vf/api-client';
import type { PaymentInstrument, ShippingMethod } from '@vf/api-contract';
import type { Address } from '@vf/api-client/src/types';
import type { ShippingMethods } from '@vf/api-client/src/api-types';
import type { Ref } from '@vue/composition-api';
import { computed, ref } from '@vue/composition-api';

import { defineStore } from 'pinia';

import uniqBy from '../utils/uniqBy';
import {
  isPickup,
  isPickupOrSts,
  isPOBoxAddress,
  isSTS,
} from '@vf/shared/src/utils/helpers';
import { useDiscountStore } from './discountStore';
import { useCartNotificationsStore } from './cartNotificationsStore';
import { isCustomsProduct } from '@vf/composables/src/useCustoms/utils';

const getCartShipping = (data: Cart) => {
  return data.shippingMethods?.find(({ code }) => {
    return !isPickupOrSts(code);
  });
};

const getCartPickup = (data) => {
  return data.shippingMethods?.find(({ code }) => {
    return isPickupOrSts(code);
  });
};

export enum PdpShippingMethod {
  ShipToHome = 'sth',
  Pickup = 'pickup',
}

export const useCartStore = defineStore('cart', () => {
  const notificationsStore = useCartNotificationsStore();
  const cartId = ref<string>(null);
  const cartItems = ref<CartLineItem[]>([]);

  const cartShipments = ref<CartShippingGroup[]>([]);
  const cartPickups = ref<CartShippingGroup[]>([]);
  const shippingAddress = ref<Address>(null);
  const shippingItems = ref<CartLineItem[]>([]);
  const billingAddress = ref<Address>(null);

  const pickupItems = ref<CartLineItem[]>([]);
  const stsItems = ref<CartLineItem[]>([]);
  const pickupPerson = ref<PickupPerson>(null);
  const pickupAddress = ref<Address>(null);
  const selectedPickupStore = ref<PickupStore>(null);
  const isBopisSupported = ref<boolean>(true);
  const hasPoAddress = ref(false);

  const shippingOptions = ref<ShippingMethod[]>([]);
  const pickupOptions = ref<ShippingMethod[]>([]);

  const pdpShippingMethod = ref<PdpShippingMethod>(
    PdpShippingMethod.ShipToHome
  );

  const availableCreditCards = ref<PaymentInstrument[]>([]);
  const appliedGiftCards = ref<PaymentInstruments[]>([]);
  const appliedRewardCards = ref<PaymentInstruments[]>([]);
  const appliedRewardCodes = ref<PaymentInstruments[]>([]);
  const appliedPointRewards = ref<PaymentInstruments[]>([]);
  const athleteDiscounts = ref<PaymentInstruments[]>([]);

  const totals = ref<CartTotals>({} as CartTotals);
  const totalItems = ref(0);
  const orderTotal = ref<number | string>(null);
  const currency = ref<string>(null);

  const loading = ref<boolean>(false);

  const resetState = (...refs: Ref[]) => {
    refs.forEach((ref) =>
      Array.isArray(ref.value) ? (ref.value = []) : (ref.value = null)
    );
  };

  const setShippingAndDelivery = (data: Cart) => {
    const shipping = getCartShipping(data);
    if (shipping) {
      shippingAddress.value = shipping.address;
    } else {
      resetState(shippingAddress);
    }

    const pickup = getCartPickup(data); //both STS and BOPIS always have same data
    if (pickup) {
      const { firstName, lastName, email, ...address } = pickup.address;
      pickupAddress.value = {
        ...address,
        shippingId: pickup.shippingId,
      };
      pickupPerson.value = { firstName, lastName, email };
      selectedPickupStore.value = {
        id: pickup.storeId,
        name: pickup.storeName,
      };
    } else {
      resetState(pickupAddress, pickupPerson, selectedPickupStore);
    }

    const shippingMethods = data.shippingMethods ?? [];

    const groupedShipments = shippingMethods.reduce(
      (acc, curr) => {
        const key = isPickupOrSts(curr.code) ? 'pickup' : 'shipping';
        (acc[key] || []).push(curr);
        return acc;
      },
      {
        pickup: [] as CartShippingGroup[],
        shipping: [] as CartShippingGroup[],
      }
    );

    cartShipments.value = groupedShipments.shipping.map((method) => {
      const oldShippingGroup = findShippingGroup(method.shippingId);

      return {
        ...method,
        methods: oldShippingGroup?.methods || [],
      };
    });

    const hasDefaultShipment = cartShipments.value.find(({ shippingId }) =>
      shippingId?.startsWith('me')
    );
    if (hasDefaultShipment) {
      hasPoAddress.value = isPOBoxAddress(
        shippingAddress.value.addressLine1,
        shippingAddress.value.addressLine2
      );
    }

    cartPickups.value = groupedShipments.pickup;
  };

  const fullShippingAddress = computed(() => {
    return (
      shippingAddress.value || {
        ...pickupAddress.value,
        ...pickupPerson.value,
      }
    );
  });

  const fieldsToSkipShippingAddressCheck = [
    'id',
    'addressId',
    'addressLine2',
    'addressLine3',
    'stateCode',
    'subscriptions',
  ];
  const isShippingAddressIncomplete = (shippingAddress?: Address) => {
    const address = shippingAddress || fullShippingAddress.value;
    return Object.keys(address).some(
      (field) =>
        !fieldsToSkipShippingAddressCheck.includes(field) && !address[field]
    );
  };

  const setCartItems = (data: Cart) => {
    cartItems.value = data.items ?? [];

    let items;
    if (cartItems.value.every((item) => item.shippingOptions?.length)) {
      // bopis available, it's okay to rely on item.shippingOptions
      items = cartItems.value.reduce(
        (acc, item) => {
          let selectedShippingMethod = item.shippingOptions.find(
            (option) => option.selected
          )?.shippingMethod;
          if (!selectedShippingMethod) return acc;
          selectedShippingMethod = {
            ...selectedShippingMethod,
            ...(item.deliveryTime && { deliveryTime: item.deliveryTime }),
          }; //updating shipping option with EDD deliveryTime, cause apparently it's put in the different place in the API
          if (isPickup(selectedShippingMethod.code)) {
            acc.pickupOptions.push(selectedShippingMethod);
            acc.pickupItems.push(item);
          } else if (isSTS(selectedShippingMethod.code)) {
            acc.pickupOptions.push(selectedShippingMethod);
            acc.stsItems.push(item);
          } else {
            acc.shippingOptions.push(selectedShippingMethod);
            acc.shippingItems.push(item);
          }
          return acc;
        },
        {
          shippingItems: [],
          pickupItems: [],
          stsItems: [],
          shippingOptions: [],
          pickupOptions: [],
        }
      );
    } else {
      isBopisSupported.value = false;
      items = {
        shippingItems: [...cartItems.value],
        pickupItems: [],
        stsItems: [],
        shippingOptions: [],
        pickupOptions: [],
      };
      cartShipments.value.forEach((shipment) => {
        const method = shipment.methods.find(
          (method) => method.code === shipment.code
        ) || {
          code: shipment.code,
          deliveryTime: '', //fallback before shipping methods are available
        };
        items.shippingOptions.push(method as ShippingMethod);
      });
    }

    pickupItems.value = items.pickupItems;
    stsItems.value = items.stsItems;
    pickupOptions.value = uniqBy(items.pickupOptions, 'code');
    shippingItems.value = items.shippingItems;
    shippingOptions.value = uniqBy(items.shippingOptions, 'code');
  };

  const findShippingGroup = (shipmentId) => {
    return cartShipments.value.find(
      (shipment) => shipmentId === shipment.shippingId
    );
  };

  const inStoreItems = computed(() => [
    ...pickupItems.value,
    ...stsItems.value,
  ]);

  const isMixedShippingAndPickup = computed(() => {
    return shippingItems.value.length && inStoreItems.value.length;
  });

  const isZeroOrder = computed(
    () =>
      totals.value.remainingToPay === 0 || // standard case
      (totals.value.remainingToPay === undefined && totals.value.total === 0) // -100% promo code / autopromotion case
  );

  const hasCustomItems = computed(
    () => !!cartItems.value.filter((item) => isCustomsProduct(item)).length
  );

  const getAppliedInstruments = (
    data: Cart,
    instrumentType: PaymentMethodCode
  ) => {
    return (data.payment_instruments || []).filter(
      (item) => item.payment_method_id === instrumentType
    );
  };

  const setCart = (data: Cart) => {
    cartId.value = data.id;
    totals.value = data.totals || ({} as CartTotals);
    totalItems.value = data.totalItems;
    orderTotal.value = data.totals?.remainingToPay ?? data.totals?.total;
    currency.value = data.currency;

    // TODO: GLOBAL15-65058 create a separate store for applied payment instruments
    appliedGiftCards.value = getAppliedInstruments(
      data,
      PaymentMethodCode.GIFT_CARD
    );
    appliedRewardCards.value = getAppliedInstruments(
      data,
      PaymentMethodCode.REWARD_CARD
    );
    appliedRewardCodes.value = getAppliedInstruments(
      data,
      PaymentMethodCode.REWARD_CODE
    );
    appliedPointRewards.value = getAppliedInstruments(
      data,
      PaymentMethodCode.LOYALTY_POINTS
    );
    athleteDiscounts.value = getAppliedInstruments(
      data,
      PaymentMethodCode.ATHLETES
    );

    setCartItems(data);
    setShippingAndDelivery(data);
    billingAddress.value = data.billingAddress;

    useDiscountStore().setDiscounts(data);
    notificationsStore.appendCartMessages(data);
  };

  return {
    cartId,
    totals,
    totalItems,
    currency,
    cartItems,

    cartShipments,
    cartPickups,
    shippingItems,
    shippingOptions,
    shippingAddress,
    fullShippingAddress,
    isShippingAddressIncomplete,
    pickupItems,
    stsItems,
    inStoreItems,
    pickupOptions,
    pickupAddress,
    pickupPerson,
    hasPoAddress,
    isBopisSupported,
    selectedPickupStore,
    loading,
    isMixedShippingAndPickup,
    billingAddress,

    availableCreditCards,
    appliedGiftCards,
    appliedRewardCards,
    appliedRewardCodes,
    appliedPointRewards,
    athleteDiscounts,
    isZeroOrder,
    hasCustomItems,

    pdpShippingMethod,

    setCart,

    resetCartStore: () => setCart({} as Cart),

    setShippingGroupMethods: (
      shipmentId: string,
      shippingMethods: ShippingMethods[]
    ) => {
      const result = findShippingGroup(shipmentId);

      if (result) {
        result.methods = shippingMethods;
      }
    },

    setCartLoading: (value: boolean) => (loading.value = value),

    customerFacingFlashes: computed(
      () => notificationsStore.customerFacingFlashes
    ),
    outOfStockFlashes: computed(() => notificationsStore.outOfStockFlashes),
    outOfStockProducts: computed(() => notificationsStore.outOfStockProducts),
    clearFlashes: notificationsStore.clearFlashes,
    clearFlashesWithProductIds: notificationsStore.clearFlashesWithProductIds,
    appendCartNotFoundFlash: notificationsStore.appendCartNotFoundFlash,
    appendINV408Flash: notificationsStore.appendINV408Flash,
    clearINV408Flashes: notificationsStore.clearINV408Flashes,
    setCartItemsCache: notificationsStore.setCartItemsCache,
  };
});
